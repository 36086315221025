import { AppEpic } from '../../utils/reduxUtils'
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators'
import { concat, EMPTY, of } from 'rxjs'
import { setDeviceTest } from './actions'
import { combineEpics } from 'redux-observable'
import { setUserData } from '../user/actions'

export const SetDeviceTest: AppEpic<ReturnType<typeof setDeviceTest>> = (
  action$,
  state$,
  { audioManagement },
) =>
  action$.pipe(
    filter(setDeviceTest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      return concat(
        audioManagement.setUserIsDeviceTest().pipe(
          switchMap(() => {
            return concat(
              of(
                setUserData({
                  ...state.user,
                  should_show_device_test: false,
                  is_first_login: false,
                }),
              ),
            )
          }),
          catchError(() => {
            return EMPTY
          }),
        ),
      )
    }),
  )
export const audioEpics = combineEpics(SetDeviceTest)
