import { combineEpics } from 'redux-observable'
import { authEpics } from '../../features/authorization/epics'
import { sessionsEpics } from '../../features/sessions/epics'
import { sessionEpics } from '../../features/session/epics'
import { audioManagementEpics } from '../../features/audioManagement/epics'
import { qualitySurveyEpics } from '../../features/qualitySurvey/epics'
import { audioEpics } from '../../features/audio/epics'
import { userEpics } from '../../features/user/epics'

export default combineEpics(
  authEpics,
  sessionsEpics,
  sessionEpics,
  audioManagementEpics,
  qualitySurveyEpics,
  audioEpics,
  userEpics,
)
