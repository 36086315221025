import React from 'react'
import { Box, Flex, IconButton, Text, Tooltip } from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom'
import { PreventChangePath } from '../PreventChangePath/preventChangePath'
import { useCallbackPrompt } from '../../customHooks/useCallbackPrompt'

interface INavigationTooltip {
  tooltipInfo: string
  rightText: string | JSX.Element
  path: string
  refreshCondition: boolean
}
const NavigationTooltip = ({
  tooltipInfo,
  path,
  rightText,
  refreshCondition,
}: INavigationTooltip) => {
  const navigate = useNavigate()
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(refreshCondition)
  return (
    <>
      <PreventChangePath
        refreshCondition={refreshCondition}
        isOpen={showPrompt}
        onConfirm={confirmNavigation}
        onCancel={cancelNavigation}
      />
      <Box alignItems="flex-end" justifyContent="space-between" w="100%">
        <Box>
          <Flex gap="2" alignItems="baseline">
            <Tooltip
              placement="bottom-end"
              hasArrow
              background="#f7fafc"
              label={
                <Flex p="5px" color="black">
                  {tooltipInfo}
                </Flex>
              }
            >
              <IconButton
                variant="ghost"
                aria-label="back"
                size="sm"
                onClick={(event) => {
                  event.preventDefault()
                  navigate(path)
                }}
                icon={<ArrowBackIcon />}
              />
            </Tooltip>
            <Box fontSize="lg" fontWeight="semibold" width="100%">
              {rightText}
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  )
}

export default NavigationTooltip
