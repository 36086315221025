import { Application, Container, DisplayObject, Graphics } from 'pixi.js'
import {
  IAnnotationRef,
  IAnnotationRefBody,
  spinnerElement,
} from './player.utils'
import { IPlayer } from '../sessionAnnotationPanels/sessionAnnotationPanel'
import React from 'react'
import { sound } from '@pixi/sound'
import { ISessionDetails } from '../../../models/session/session'
import { IAudios } from '../audio.utils'

export const updateRef = (ref: any, body: IAnnotationRefBody) => {
  ref.current = body
}

const newAnnotation: any = new Graphics()
newAnnotation.id = 'new_annotation'
newAnnotation.alpha = 0.3

export const drawingAnnotation = ({
  wave,
  height,
  app,
  annotationRef,
  setPlayer,
  player,
  createAnnotation,
  data,
  audios,
  selectedAudio,
  boxRef,
}: {
  boxRef: React.MutableRefObject<any>
  wave: Container<DisplayObject>
  height: number
  app: Application
  annotationRef: IAnnotationRef
  player: IPlayer
  setPlayer: React.Dispatch<React.SetStateAction<IPlayer>>
  createAnnotation?: ({
    position,
    startTime,
    endTime,
    selectedTypeId,
    additionalAction,
  }: {
    position: number
    startTime: number
    endTime: number
    selectedTypeId: string
    additionalAction: () => void
  }) => void
  data?: ISessionDetails
  audios: IAudios[]
  selectedAudio: IAudios | null
}) => {
  wave.on('pointerdown', (e) => {
    if (annotationRef.current.isSaving) {
      return
    }
    if (
      annotationRef.current.annotationModeAdd &&
      annotationRef.current.selectedTypeId
    ) {
      updateRef(annotationRef, {
        ...annotationRef.current,
        downPosition: e.data.global.x,
      })
      if (e.data.global.x) {
        newAnnotation.drawRect(e.data.global.x, 0, 0, 2 * height)
        app.stage.addChild(newAnnotation)
      }
    }
  })

  wave.on('pointermove', (e): void => {
    if (annotationRef.current.isSaving) {
      return
    }
    if (
      annotationRef.current.annotationModeAdd &&
      annotationRef.current.downPosition &&
      annotationRef.current.selectedTypeId
    ) {
      const newAnnotationSegment: any = app.stage.children.find(
        (el: any) => el.id && el.id === 'new_annotation',
      )
      const canCreate =
        (annotationRef.current.downPosition && annotationRef.current.upPosition
          ? Math.abs(
              annotationRef.current.downPosition -
                annotationRef.current.upPosition,
            )
          : 0) > 3

      updateRef(annotationRef, {
        ...annotationRef.current,
        upPosition: e.data.global.x,
      })

      if (newAnnotationSegment && canCreate) {
        const newWidth = e.data.global.x - annotationRef.current.downPosition

        if (newWidth > 1) {
          sound.stopAll()
          setPlayer({
            ...player,
            isPlaying: false,
          })
        }

        newAnnotationSegment.clear()
        newAnnotation.beginFill(
          annotationRef.current.selectedAnnotationType.colorHEX || 0xdadada,
        )
        newAnnotation.lineStyle(
          2,
          annotationRef.current.selectedAnnotationType.colorHEX || 0xdadada,
        )
        newAnnotation.drawRect(
          annotationRef.current.downPosition,
          0,
          newWidth,
          2 * height,
        )
      }
    }
  })

  wave.on('pointerupoutside', () => {
    updateRef(annotationRef, {
      ...annotationRef.current,
      upPosition: null,
      downPosition: null,
    })

    const newAnnotationSegment: any = app.stage.children.find(
      (el: any) => el.id && el.id === 'new_annotation',
    )

    if (newAnnotationSegment) {
      newAnnotationSegment?.clear()
    }
  })

  wave.on('pointerup', async (e: any) => {
    if (annotationRef.current.isSaving) {
      return
    }
    updateRef(annotationRef, {
      ...annotationRef.current,
      upPosition: e.data.global.x,
    })
    const canCreate =
      (annotationRef.current.downPosition && annotationRef.current.upPosition
        ? Math.abs(
            annotationRef.current.downPosition -
              annotationRef.current.upPosition,
          )
        : 0) > 3
    if (!canCreate || !data) {
      updateRef(annotationRef, {
        ...annotationRef.current,
        downPosition: null,
        upPosition: null,
      })
      const newAnnotationSegment: any = app.stage.children.find(
        (el: any) => el.id && el.id === 'new_annotation',
      )

      if (newAnnotationSegment) {
        newAnnotationSegment.clear()
      }
      return
    }
    const waveWidth =
      boxRef.current.parentElement.offsetWidth *
      boxRef.current.parentElement.zoom
    if (
      annotationRef.current.downPosition &&
      annotationRef.current.selectedTypeId &&
      annotationRef.current.annotationModeAdd
    ) {
      if (
        annotationRef.current.downPosition &&
        annotationRef.current.upPosition &&
        annotationRef.current.selectedTypeId &&
        waveWidth
      ) {
        let start = 0
        let end = 0
        if (
          annotationRef.current.downPosition > annotationRef.current.upPosition
        ) {
          start = annotationRef.current.upPosition
          end = annotationRef.current.downPosition
        } else {
          end = annotationRef.current.upPosition
          start = annotationRef.current.downPosition
        }
        const startTime =
          (start / waveWidth) * (data.duration ? data.duration : 0)
        const endTime = (end / waveWidth) * (data.duration ? data.duration : 0)
        const newAnnotationSegment: any = app.stage.children.find(
          (el: any) => el.id && el.id === 'new_annotation',
        )
        if (newAnnotationSegment) {
          const selectedElement = audios.findIndex(
            ({ id }) => id === selectedAudio?.id,
          )
          const heightIndex =
            selectedElement && selectedElement !== -1 ? selectedElement : 0
          newAnnotationSegment.addChild(
            spinnerElement({
              app,
              radius: 6,
              positionX: end - 10,
              positionY: heightIndex * height + 10,
            }),
          )
        }
        if (newAnnotationSegment && createAnnotation) {
          createAnnotation({
            position: e.data.global.x,
            startTime: Math.floor(startTime),
            endTime: Math.floor(endTime),
            selectedTypeId: annotationRef.current.selectedTypeId,
            additionalAction: () => {
              if (newAnnotationSegment) {
                const newAnnotationSegment: any = app.stage.children.find(
                  (el: any) => el.id && el.id === 'new_annotation',
                )
                if (newAnnotationSegment.children) {
                  newAnnotationSegment.removeChild(
                    newAnnotationSegment.children[0],
                  )
                }
                newAnnotationSegment.clear()
              }
            },
          })
        }
      }
    }
  })
}
