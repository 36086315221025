import { ajax } from 'rxjs/ajax'
import { apiVersion } from '../index'
import { ISaveQualitySurvey } from '../../models/qualitySurvey/qualitySurvey'

export const saveQualitySurvey = (body: ISaveQualitySurvey) => {
  return ajax({
    url: `${process.env.REACT_APP_API_URL}/api/${apiVersion}/survey/`,
    method: 'POST',
    body,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
