import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  useMediaQuery,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { Survey } from './survey'
import { SingleValue } from 'chakra-react-select'
import { useAppDispatch, useAppSelector } from '../../utils/reduxUtils'
import { saveQualitySurvey } from '../../features/qualitySurvey/actions'
import { mobileBreakPoint } from '../../utils/mobileBreakPoint'

export const ModalSurvey = ({
  isOpenSurvey,
  onClose,
}: {
  isOpenSurvey: {
    isOpen: boolean
    sessionId: string | null
  }
  onClose: () => void
}) => {
  const [rating, setRating] = useState(0)
  const [issueDescription, setIssueDescription] = useState<string>('')
  const [selectedIssue, setSelectedIssue] = useState<SingleValue<any>>(null)
  const dispatch = useAppDispatch()
  const toast = useToast()
  const [isMobile] = useMediaQuery(mobileBreakPoint)

  const closeModal = () => {
    setRating(0)
    setIssueDescription('')
    onClose()
  }
  const onSubmit = () => {
    const survey = {
      score: rating,
      answer: issueDescription,
      potential_issue_id: selectedIssue ? selectedIssue.value : false,
      session_id: isOpenSurvey.sessionId,
    }
    dispatch(saveQualitySurvey({ data: survey, toast }))

    setRating(0)
    setSelectedIssue(null)
    setIssueDescription('')
    onClose()
  }

  return (
    <Modal
      size={isMobile ? 'md' : '2xl'}
      closeOnOverlayClick={false}
      isOpen={isOpenSurvey.isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent alignSelf="center">
        <ModalHeader textAlign="center">Quality Survey</ModalHeader>
        <ModalCloseButton onClick={closeModal} />
        <ModalBody pb={0}>
          <Survey
            isFirstLogin={false}
            onSubmit={onSubmit}
            setSelectedIssue={setSelectedIssue}
            selectedIssue={selectedIssue}
            issueDescription={issueDescription}
            setIssueDescription={setIssueDescription}
            rating={rating}
            setRating={setRating}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
