import { createAction } from '@reduxjs/toolkit'
import {
  IDetails,
  IEditedSession,
  ISessionDetails,
} from '../../models/session/session'
import { IConversionQuery } from '../../models/recordings/recordings'
import { CreateToastFnReturn } from '@chakra-ui/react'
import { NavigateFunction } from 'react-router-dom'

export const getSession = createAction<{
  id: string | number
  silent: boolean
  getTranscription?: boolean
}>('session/GET_SESSION')

export const setSession = createAction<{
  session: ISessionDetails | Partial<ISessionDetails>
}>('session/SET_SESSION')

export const removeSessionRecordingAndConversions = createAction<{
  sessionId: string
  toast: CreateToastFnReturn
  navigate?: NavigateFunction
  navigateTo?: string
}>('session/REMOVE_SESSION_RECORDING_AND_CONVERSIONS')

export const updateGetterForSession = createAction<{
  isSubscription: boolean
  id?: string | number
}>('session/UPDATE_GETTER_FOR_ONE_SESSION')
export const subscribeSession = createAction<{
  id: string | number
}>('session/SUBSCRIBE_SESSION')

export const saveSession = createAction<{
  toast?: CreateToastFnReturn
  navigate?: NavigateFunction
  body: any
  additionalFunction?: any
}>('session/SAVE_SESSION')

export const saveNewSession = createAction<{
  toast: CreateToastFnReturn
  navigate: NavigateFunction
  nameUpdater: (name: string) => void
  body: {
    name: string
  }
}>('session/SAVE_NEW_SESSION')

export const saveOneConversion = createAction<{
  sessionId: string | number
  toast?: CreateToastFnReturn
  body: any
  silent?: boolean
  additionalFunction?: any
  conversionId?: string
}>('session/SAVE_ONE_CONVERSION')
export const setSavingOneConversion = createAction<boolean>(
  'session/SET_SAVING_ONE_CONVERSION',
)
export const saveLiveSession = createAction<{
  toast?: CreateToastFnReturn
  session: Partial<ISessionDetails>
  conversion: { data: IConversionQuery }
  sessionMeta?: { [key: string]: string | boolean } | null
}>('session/SAVE_LIVE_SESSION')
export const setIsSavingSession = createAction<boolean>(
  'session/SET_IS_SAVING_SESSION',
)
export const removeSession = createAction<{
  id: string | number
  isReloadList: boolean
  toast?: CreateToastFnReturn
  redirect?: any
}>('session/REMOVE_SESSION')
export const setIsRemovingSession = createAction<boolean>(
  'session/SET_IS_REMOVING_SESSION',
)
export const removeOneConversionById = createAction<{
  conversion_id: string | number
  session_id: string | number
  setEditedFields?: any
  toast?: CreateToastFnReturn
  editedFields?: IEditedSession & { prev: IEditedSession }
}>('session/REMOVE_ONE_CONVERSION_BY_ID')
export const isRemovingConversions = createAction<
  { id: string | number; isLoading: boolean }[]
>('session/IS_REMOVING_ONE_CONVERSION_BY_ID')
export const uploadAudioToS3 = createAction<{
  recordData: IDetails & { id: string | number; timeStamp: number }
  file: any
  toast?: CreateToastFnReturn
  isSession: boolean
}>('session/UPLOAD_AUDIO_TO_S3')
export const setIsUpdatingSession = createAction<boolean>(
  'session/SET_IS_UPDATING_SESSION',
)
export const setIsUpdatingConversions = createAction<
  { id: string | number; isLoading: boolean }[]
>('session/SET_IS_UPDATING_CONVERSION')

export const setIsLoadingSession = createAction<boolean>(
  'session/SET_IS_LOADING_SESSION',
)
export const clearSessionReduxData = createAction<number>(
  'session/CLEAR_SESSION',
)
