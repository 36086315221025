import { combineEpics } from 'redux-observable'
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators'
import { concat, EMPTY, of } from 'rxjs'
import { AppEpic } from '../../utils/reduxUtils'
import {
  querySessions,
  setIsLoadingSessions,
  hydrateSessions,
  hydrateMetrics,
  setIsLoadingMetrics,
  setUsedQuery,
} from './actions'
import { EDataType } from '../../models/sessions/sessions'
import { checkIsStatus401 } from '../authorization/actions'
import { endOfDay, startOfDay, startOfMonth, subDays } from 'date-fns'

export const GetSessions: AppEpic<ReturnType<typeof querySessions>> = (
  action$,
  state$,
  { sessions },
) =>
  action$.pipe(
    filter(querySessions.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      if (!action.payload.body.created_at) {
        const today = endOfDay(new Date())
        const thirtyDaysAgo = startOfDay(subDays(today, 30))
        action.payload.body.created_at = {
          from: thirtyDaysAgo.toISOString(),
          to: today.toISOString(),
        }
      }
      return concat(
        // of(
        //   setUsedRecordingsFilers(
        //     action.payload.filters ? action.payload.filters : [],
        //   ),
        // ),
        of(
          hydrateSessions({
            ...state.sessions.response,
            body: action.payload.body,
          }),
        ),
        of(
          action.payload.body.data_type === EDataType.metrics
            ? setIsLoadingMetrics(true)
            : setIsLoadingSessions(true),
        ),
        sessions
          .query({
            ...action.payload,
          })
          .pipe(
            switchMap((AjaxResponse) => {
              const { response } = AjaxResponse
              if (action.payload.body.data_type === EDataType.metrics) {
                return concat(
                  of(
                    setUsedQuery({
                      url: action.payload.url,
                      body: { ...action.payload.body, ...response.body },
                    }),
                  ),
                  of(hydrateMetrics(response.results)),
                  of(
                    hydrateSessions({
                      ...state.sessions.response,
                      facets: response.facets,
                      body: response.body,
                      table_info: response.table_info,
                    }),
                  ),
                  of(setIsLoadingSessions(false)),
                  of(setIsLoadingMetrics(false)),
                )
              }
              return concat(
                of(
                  setUsedQuery({
                    url: action.payload.url,
                    body: {
                      ...action.payload.body,
                      ...response.body,
                    },
                  }),
                ),
                of(setIsLoadingSessions(false)),
                of(setIsLoadingMetrics(false)),
                of(hydrateSessions(response)),
              )
            }),
            catchError((err) => {
              const { response } = err
              if (
                response &&
                response.detail &&
                response.detail === 'Invalid page.' &&
                state.sessions.response.current_page > 1
              ) {
                // return concat(
                //   of(setCurrentPage(state.recordings.currentPage - 1)),
                //   of(setCurrentCounter(state.recordings.count - 1)),
                // )
              }
              return concat(
                of(setIsLoadingSessions(false)),
                of(setIsLoadingMetrics(false)),
                of(checkIsStatus401(err.status)),
              )
            }),
          ),
      )
    }),
  )

export const sessionsEpics = combineEpics(GetSessions)
