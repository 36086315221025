import { useState, useEffect } from 'react'
import {
  Avatar,
  AvatarProps,
  useImage,
  Image as ChakraImage,
} from '@chakra-ui/react'

export const AvatarWithPreload = ({ ...props }: AvatarProps) => {
  const [status, setStatus] = useState<string | null>(null)
  useEffect(() => {
    const img = new Image()
    img.onload = () => setStatus('onload')
    img.onerror = () => setStatus('onerror')
    // @ts-ignore
    img.src = props.src
  }, [props.src])

  return !status || status === 'onload' ? (
    <ChakraImage
      w={12}
      color="white"
      h={12}
      {...props}
      rounded="true"
      loading="lazy"
      data-qa__id="avatar_image"
    />
  ) : (
    <Avatar
      color="white"
      {...props}
      showBorder={false}
      data-qa__id="avatar_image"
    />
  )
}
