import React, { useEffect, useState } from 'react'
import {
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Tooltip,
  useToast,
} from '@chakra-ui/react'
import { ISessionDetails } from '../../../models/session/session'
import { useAppSelector } from '../../../utils/reduxUtils'
import { Icon, LinkIcon } from '@chakra-ui/icons'
import { FaRegFileZipper } from 'react-icons/fa6'
import { IAnnotationsElement } from '../../../models/annotations/annotations'
import JSZip from 'jszip'
// @ts-ignore
import JSZipUtils from 'jszip-utils'
import { opusToWav } from '../../../utils/convertOpusToWav'
import { saveAs } from 'file-saver'
import { IAudios } from '../audio.utils'
import { getTime } from '../../../utils/audioUtils'
import { FiFileText } from 'react-icons/fi'
import { CiZoomIn, CiZoomOut } from 'react-icons/ci'
import { CgTranscript } from 'react-icons/cg'
import { useStartTranscriptionMutation } from '../../../newFeatures/session/service'

const annotate_icon = ({ fill }: { fill: string }) => {
  return (
    <Icon viewBox="0 0 1920 1920" fill={fill}>
      <g fillRule="evenodd" clipRule="evenodd" stroke="none" strokeWidth="1">
        <path d="M1468.235 113v99.388l-112.94 112.941v-99.388H112.94v1468.235h1242.353v-438.211l112.941-112.941v664.094H0V113h1468.235ZM421.271 1163.353c96-9.035 154.729 108.423 190.87 197.647 28.235 68.894 38.4 92.612 72.283 96 33.882 3.388 89.223-58.73 112.94-101.647 16.53-26.51 51.42-34.6 77.93-18.07 26.51 16.529 34.6 51.42 18.07 77.929-9.035 16.94-92.611 160.376-205.552 160.376h-9.036c-70.023-4.517-121.976-48.564-169.411-166.023-47.436-117.46-77.93-127.624-77.93-127.624a484.518 484.518 0 0 0-97.13 225.883c-6.549 31.187-37.14 51.16-68.329 44.611-31.187-6.55-51.16-37.141-44.611-68.33 20.33-94.87 79.059-310.587 199.906-320.752Zm256.376-485.647v112.941H338.824V677.706h338.823ZM903.53 451.824v112.94H338.824v-112.94h564.705Z" />
        <path d="m1903.059 468.765-225.883-225.883a56.47 56.47 0 0 0-80.188 0L919.341 920.53a56.476 56.476 0 0 0-15.813 39.53v282.353h282.354a56.47 56.47 0 0 0 39.53-16.941l677.647-677.647c21.523-21.959 21.523-57.101 0-79.06Zm-740.894 660.706H1016.47V983.776l451.764-451.764 145.694 145.694-451.764 451.765Zm531.953-531.953-145.694-145.694 89.223-89.224 145.694 145.694-89.223 89.224Z" />
      </g>
    </Icon>
  )
}

const downloadZippedData = ({
  session,
  audios,
  annotations,
}: {
  session: Partial<ISessionDetails>
  audios: IAudios[]
  annotations: IAnnotationsElement[]
}) => {
  const zip = new JSZip()
  const zipFilename = `${session.name}.zip`
  const data = {
    session: {
      id: session.id,
      name: session.name,
      duration: session.duration,
      transcription: session.transcription,
      metadata: session.metadata,
      created_at: session.created_at,
      annotations: annotations?.map(
        ({
          annotation_id,
          start,
          end,
          description,
          annotation_type: { annotation_type_id, annotation_type_name },
        }) => ({
          id: annotation_id,
          start,
          end,
          description,
          annotation_type: {
            id: annotation_type_id,
            name: annotation_type_name,
          },
        }),
      ),
    },
    conversions: session.conversions?.map((conversion) => ({
      name: conversion.name,
      transcription: session.transcription,
      metadata: session.metadata,
      created_at: session.created_at,
    })),
  }
  zip.file('data.json', JSON.stringify(data))

  let counter = 0
  const audiosFiltered = audios.filter(({ url }) => !!url)
  audiosFiltered.forEach(({ url, name, isConversion, id }, index) => {
    const startIndex = url.indexOf(id)
    const extension = url
      .substring(startIndex + id.length, url.indexOf('?'))
      .replace('/', '')
    const filename = isConversion
      ? `conversion_${id}${extension}`
      : `session_${id}${extension}`

    JSZipUtils.getBinaryContent(url, async (err: any, data: any) => {
      if (err) {
        throw err
      }
      const wavData = await opusToWav(data)
      counter++
      if (wavData) {
        zip.file(filename.replace(extension, '.wav'), wavData, { binary: true })
      }
      zip.file(filename, data, { binary: true })
      if (counter === audiosFiltered.length) {
        zip.generateAsync({ type: 'blob' }).then(function (content) {
          saveAs(content, zipFilename)
        })
      }
    })
  })
}

let interval: any = null
export const SessionControlButtons = ({
  refetchSession,
  data,
  boxRef,
  scrollBoxRef,
  disabled,
  onClickAnnotationToolActive,
  isAnnotationToolActive,
  audios,
  annotations,
  annotationTypesLoading,
  selectedAudio,
  progress,
  onClickTranscriptionToolActive,
  isTranscriptionToolActive,
  resize,
}: {
  refetchSession?: () => void
  data?: ISessionDetails
  boxRef: React.MutableRefObject<any>
  scrollBoxRef: React.MutableRefObject<any>
  disabled: boolean
  onClickAnnotationToolActive: () => void
  isAnnotationToolActive: boolean
  audios: IAudios[]
  annotations: IAnnotationsElement[]
  annotationTypesLoading: boolean
  selectedAudio: IAudios | null
  progress: { progress: number; duration: number }
  isTranscriptionToolActive: boolean
  onClickTranscriptionToolActive: () => void
  resize: () => void
}) => {
  const toast = useToast()
  const { is_annotator } = useAppSelector((state) => state.user)
  const [zoom, setZoom] = useState(1)

  const zoomWave = (count: number) => {
    if (boxRef.current) {
      const newZoom = zoom + count
      boxRef.current.parentElement.zoom = newZoom
      setZoom(newZoom)
      const canvas = boxRef.current
      const scrollElement = scrollBoxRef.current
      const parentElement = canvas.parentElement
      if (parentElement) {
        const parentWidth = parentElement.offsetWidth
        if (newZoom > 1) {
          canvas.style.width = `${parentWidth * newZoom}px`
          scrollElement.style.width = `${parentWidth * newZoom}px`
        } else {
          canvas.style.width = '100%'
          scrollElement.style.width = '100%'
        }
        resize()
        boxRef.current.parentElement.updateWave()
      }
    }
  }

  const updateZoom = (direction: number) => {
    if (zoom + direction >= 1 && zoom + direction <= 10) {
      zoomWave(direction)
    }
  }

  useEffect(() => {
    if (boxRef.current) {
      boxRef.current.parentElement.updateZoom = updateZoom
    }
  }, [boxRef.current, zoom])

  const [startTranscription] = useStartTranscriptionMutation()
  const [isStartTranscription, setIsStartTranscription] = useState(false)

  const isAnyTranscriptionProcessing =
    data?.transcription_state === 'processing' ||
    (data?.conversions && data.conversions?.length > 0
      ? data.conversions.filter(
          ({ transcription_state }) => transcription_state === 'processing',
        ).length > 0
      : false)

  useEffect(() => {
    if (isAnyTranscriptionProcessing && !!refetchSession) {
      interval = setInterval(() => {
        refetchSession?.()
        setIsStartTranscription(false)
      }, 5000)
    } else {
      if (interval) {
        clearInterval(interval)
      }
    }
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [isAnyTranscriptionProcessing])

  const canShowGenerateTranscription =
    data?.transcription_state !== 'completed' ||
    data?.conversions?.some(
      ({ transcription_state }) => transcription_state !== 'completed',
    )

  return (
    <Flex justifyContent="flex-end" alignItems="center">
      {selectedAudio && (
        <ButtonGroup size="xs">
          {canShowGenerateTranscription &&
            selectedAudio.url &&
            data?.id &&
            refetchSession &&
            is_annotator && (
              <Tooltip
                placement="bottom-end"
                hasArrow
                background="#f7fafc"
                label={
                  <Flex p="5px" color="black">
                    Transcription On Demand
                  </Flex>
                }
              >
                <Button
                  isLoading={
                    data?.transcription_state === 'processing' ||
                    data?.conversions?.some(
                      ({ transcription_state }) =>
                        transcription_state === 'processing',
                    ) ||
                    isStartTranscription
                  }
                  isDisabled={
                    disabled || data?.transcription_state === 'processing'
                  }
                  onClick={async (event) => {
                    setIsStartTranscription(true)
                    startTranscription({ sessionId: data.id })
                      .unwrap()
                      .then(() => {
                        refetchSession?.()
                        toast({
                          title: `Transcription start generating.`,
                          position: 'bottom-left',
                          status: 'success',
                          isClosable: true,
                        })
                      })
                      .catch(() => {
                        setIsStartTranscription(false)
                        toast({
                          title: 'Error while start generating transcription.',
                          position: 'bottom-left',
                          status: 'error',
                          isClosable: true,
                        })
                      })
                  }}
                >
                  <Icon as={CgTranscript}></Icon>
                </Button>
              </Tooltip>
            )}

          {selectedAudio.url && is_annotator && (
            <Tooltip
              placement="bottom-end"
              hasArrow
              background="#f7fafc"
              label={
                <Flex p="5px" color="black">
                  Zoom In
                </Flex>
              }
            >
              <Button
                isDisabled={disabled || zoom >= 10}
                onClick={(event) => {
                  zoomWave(1)
                }}
              >
                <Icon as={CiZoomIn}></Icon>
              </Button>
            </Tooltip>
          )}

          {selectedAudio.url && is_annotator && (
            <Tooltip
              placement="bottom-end"
              hasArrow
              background="#f7fafc"
              label={
                <Flex p="5px" color="black">
                  Zoom Out
                </Flex>
              }
            >
              <Button
                isDisabled={disabled || zoom <= 1}
                onClick={(event) => {
                  zoomWave(-1)
                }}
              >
                <Icon as={CiZoomOut}></Icon>
              </Button>
            </Tooltip>
          )}

          {is_annotator && selectedAudio.url && (
            <Tooltip
              placement="bottom-end"
              hasArrow
              background="#f7fafc"
              label={
                <Flex p="5px" color="black">
                  Annotate
                </Flex>
              }
            >
              <Button
                isLoading={annotationTypesLoading}
                isDisabled={disabled}
                onClick={(event) => {
                  onClickAnnotationToolActive()
                }}
              >
                {annotate_icon({
                  fill: isAnnotationToolActive ? 'brand.500' : 'black',
                })}
              </Button>
            </Tooltip>
          )}
          {selectedAudio.url && is_annotator && (
            <Tooltip
              placement="bottom-end"
              hasArrow
              background="#f7fafc"
              label={
                <Flex p="5px" color="black">
                  {isTranscriptionToolActive
                    ? 'Hide Transcription'
                    : 'Show Transcription'}
                </Flex>
              }
            >
              <Button
                isDisabled={disabled}
                onClick={(event) => {
                  onClickTranscriptionToolActive()
                }}
              >
                <Icon
                  as={FiFileText}
                  color={isTranscriptionToolActive ? 'brand.500' : 'black'}
                ></Icon>
              </Button>
            </Tooltip>
          )}
          {selectedAudio.url && (
            <Tooltip
              placement="bottom-end"
              hasArrow
              background="#f7fafc"
              label={
                <Flex p="5px" color="black">
                  Copy Link
                </Flex>
              }
            >
              <IconButton
                isDisabled={disabled}
                icon={<LinkIcon />}
                _hover={{ color: '#1b91d6' }}
                onClick={(event) => {
                  event.stopPropagation()
                  const url = `${window.location.origin}${window.location.pathname}`
                  navigator.clipboard.writeText(
                    `${url}?t=${
                      !!progress.progress
                        ? Math.floor(
                            progress.progress * progress.duration * 1000,
                          )
                        : 0
                    }&id=${selectedAudio.id}`,
                  )
                  toast({
                    title:
                      progress.progress * progress.duration * 1000 < 2
                        ? 'Link copied to clipboard'
                        : `Link at time ${getTime({
                            duration:
                              progress.progress * progress.duration * 1000,
                          })} copied to clipboard`,
                    position: 'bottom-left',
                    status: 'success',
                    isClosable: true,
                  })
                }}
                aria-label="url_generator"
                data-qa__id="audio_item_url"
              />
            </Tooltip>
          )}

          {data?.download_url && selectedAudio.url && (
            <Tooltip
              placement="bottom-end"
              hasArrow
              background="#f7fafc"
              label={
                <Flex p="5px" color="black">
                  Download
                </Flex>
              }
            >
              <Button
                isDisabled={disabled}
                onClick={() => {
                  downloadZippedData({ session: data, audios, annotations })
                }}
              >
                <FaRegFileZipper />
              </Button>
            </Tooltip>
          )}
        </ButtonGroup>
      )}
    </Flex>
  )
}
