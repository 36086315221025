import { AppEpic } from '../../../utils/reduxUtils'
import {
  getSession,
  saveLiveSession,
  saveSession,
  setIsSavingSession,
  setIsUpdatingConversions,
  setIsUpdatingSession,
  uploadAudioToS3,
} from '../actions'
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators'
import { concat, of } from 'rxjs'
import { fromPromise } from 'rxjs/internal-compatibility'
import { updateMediaState } from '../../audio/actions'
import { checkIsStatus401 } from '../../authorization/actions'

export const UploadAudioToS3: AppEpic<ReturnType<typeof uploadAudioToS3>> = (
  action$,
  state$,
  { session },
) =>
  action$.pipe(
    filter(uploadAudioToS3.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const formData = new FormData()
      Object.entries(
        action.payload.recordData.s3_upload_credentials.fields,
      ).forEach(([key, value]) => {
        // @ts-ignore
        formData.append(key, value)
      })
      formData.append('file', action.payload.file, 'test.wav')
      let fileMeta = {}
      if (action.payload.file.type && action.payload.file.name) {
        const fileMimeType: string = action.payload.file.type
        const fileNamePath: string = action.payload.file.name?.split('.')
        fileMeta = {
          file_mime_type: fileMimeType,
          file_extension: fileNamePath[fileNamePath.length - 1],
        }
      }
      return concat(
        action.payload.isSession
          ? concat(
              of(setIsUpdatingSession(true)),
              of(
                saveSession({
                  toast: action.payload.toast,
                  body: {
                    id: action.payload.recordData.id,
                    data: {
                      ...fileMeta,
                      duration: Math.floor(action.payload.recordData.timeStamp),
                    },
                  },
                }),
              ),
            )
          : of(
              setIsUpdatingConversions([
                ...state.session.isUpdatingConversions,
                { id: action.payload.recordData.id, isLoading: true },
              ]),
            ),
        fromPromise(
          fetch(action.payload.recordData.s3_upload_credentials.url, {
            method: 'POST',
            body: formData,
          }),
        ).pipe(
          switchMap((AjaxResponse) => {
            if (action.payload.toast) {
              action.payload.toast({
                title: `Upload audio passed`,
                position: 'bottom-left',
                status: 'success',
                isClosable: true,
              })
            }
            if (action.payload.isSession) {
              return concat(
                of(
                  getSession({
                    id: action.payload.recordData.id,
                    silent: true,
                  }),
                ),
                of(setIsUpdatingSession(false)),
              )
            } else {
              return concat(
                of(
                  getSession({
                    id: action.payload.recordData.id,
                    silent: true,
                  }),
                ),
                of(
                  setIsUpdatingConversions(
                    state.session.isUpdatingConversions.filter(
                      ({ id }) => id !== action.payload.recordData.id,
                    ),
                  ),
                ),
              )
            }
          }),
          catchError((err: any) => {
            if (action.payload.toast) {
              action.payload.toast({
                title: `Error while s3 upload`,
                position: 'bottom-left',
                status: 'error',
                isClosable: true,
              })
            }
            return concat(
              of(
                setIsUpdatingConversions([
                  ...state.session.isUpdatingConversions,
                  { id: action.payload.recordData.id, isLoading: true },
                ]),
              ),
              of(setIsUpdatingSession(false)),
            )
          }),
        ),
      )
    }),
  )

export const SaveLiveSession: AppEpic<ReturnType<typeof saveLiveSession>> = (
  action$,
  state$,
  { session },
) =>
  action$.pipe(
    filter(saveLiveSession.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      return concat(
        of(updateMediaState({ isUploading: true })),
        of(updateMediaState({ isStartingUpload: false })),
        of(setIsSavingSession(true)),
        session
          .saveSession({
            body: {
              ...action.payload.session,
              recording_id:
                action.payload.session.recording_id ||
                state.audioManagement.liveSession.session_id,
              metadata: action.payload.sessionMeta
                ? action.payload.sessionMeta
                : state.audioManagement.metadata,
            },
            isNew: true,
          })
          .pipe(
            switchMap((recordResponseRecord) => {
              const { response: recordResponse } = recordResponseRecord

              return session
                .saveConversion({
                  isNew: true,
                  sessionId: recordResponse.id,
                  body: action.payload.conversion,
                })
                .pipe(
                  switchMap((AjaxResponseConversion) => {
                    return concat(
                      of(updateMediaState({ isUploading: false })),
                      of(setIsSavingSession(false)),
                    )
                  }),
                  catchError((err) => {
                    if (action.payload.toast) {
                      action.payload.toast({
                        title: 'Error while creating conversion',
                        position: 'bottom-left',
                        status: 'error',
                        isClosable: true,
                      })
                    }
                    return concat(
                      of(setIsSavingSession(false)),
                      of(updateMediaState({ isUploading: false })),
                    )
                  }),
                )
            }),
            catchError((err: any) => {
              if (action.payload.toast) {
                action.payload.toast({
                  title: 'Error while saving session',
                  position: 'bottom-left',
                  status: 'error',
                  isClosable: true,
                })
              }
              return concat(
                of(setIsSavingSession(false)),
                of(updateMediaState({ isUploading: false })),
                of(checkIsStatus401(err.status)),
              )
            }),
          ),
      )
    }),
  )
