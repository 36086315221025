import { ajax } from 'rxjs/ajax'
import { apiVersion } from '../index'
import { v4 as uuidv4 } from 'uuid'

export const setLiveSession = ({ body, user }: { body: any; user: string }) => {
  return ajax({
    url: `${process.env.REACT_APP_API_URL}/api/${apiVersion}/live_management/users/${user}`,
    method: 'POST',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  })
}
export const getLiveSession = (
  user: string,
  tabFingerprint: string,
  browserFingerprint: string,
) => {
  return ajax({
    url: `${process.env.REACT_APP_API_URL}/api/${apiVersion}/live/users/${user}?selfRegister=true&fingerprint=${tabFingerprint}/${browserFingerprint}`,
    method: 'GET',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const setUserIsDeviceTest = () => {
  return ajax({
    url: `${process.env.REACT_APP_API_URL}/api/${apiVersion}/user_device_test`,
    method: 'GET',
    withCredentials: true,
    body: {},
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
