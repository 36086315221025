import React, { ReactNode, useMemo, useState } from 'react'
import {
  Flex,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'
export interface IAlert {
  buttonElement: ReactNode
  headerText: string
  subHeaderText: string
  cancelText: string
  yesText: string
  onClickCancel?: () => void
  onClickYes: () => void
  isDisabled?: boolean
}

export const AlertElement = ({
  buttonElement,
  headerText,
  subHeaderText,
  onClickYes,
  onClickCancel,
  cancelText,
  yesText,
  isDisabled,
}: IAlert) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef: any = React.useRef()

  return (
    <>
      <Flex
        onClick={(event) => {
          if (isDisabled) {
            return
          }
          event.stopPropagation()
          onOpen()
        }}
      >
        {buttonElement}
      </Flex>

      <AlertDialog
        motionPreset="slideInBottom"
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent alignSelf="center" data-qa__id="alert_dialog">
            <AlertDialogHeader
              textAlign="center"
              fontSize="lg"
              fontWeight="bold"
              data-qa__id="alert_dialog_header"
            >
              {headerText}
            </AlertDialogHeader>

            <AlertDialogBody
              textAlign="center"
              data-qa__id="alert_dialog_description"
            >
              {subHeaderText}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => {
                  onClose()
                  if (onClickCancel) {
                    onClickCancel()
                  }
                }}
                data-qa__id="alert_dialog_cancel"
              >
                {cancelText}
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onClose()
                  onClickYes()
                }}
                ml={3}
                data-qa__id="alert_dialog_confirm"
              >
                {yesText}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
