import {
  Box,
  Button,
  Flex,
  FormLabel,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Textarea,
} from '@chakra-ui/react'
import { CloseIcon } from '@chakra-ui/icons'
import React, { useEffect, useRef, useState } from 'react'
import { Select } from 'chakra-react-select'
import {
  IAnnotationsElement,
  IAnnotationTypes,
} from '../../models/annotations/annotations'

export interface IPopoverData {
  x: number
  sessionId: null | string
  annotationId: null | string
  text: string
  startText: string
  start: number
  end: number
}

export const emptyPopoverCommentData = {
  x: 0,
  sessionId: null,
  annotationId: null,
  text: '',
  startText: '',
  start: 0,
  end: 0,
}

export const SessionPopoverComment = ({
  closePopover,
  popoverData,
  height,
  setPopoverData,
  updateAnnotationComment,
  isUpdatingAnnotation,
  annotationTypes,
  annotations,
}: {
  annotations: IAnnotationsElement[]
  closePopover: () => void
  popoverData: IPopoverData
  height: number
  setPopoverData: (data: IPopoverData) => void
  updateAnnotationComment: (selectedTypeId?: string) => void
  isUpdatingAnnotation: boolean
  annotationTypes: IAnnotationTypes[]
}) => {
  const initialFocusRef = useRef<HTMLTextAreaElement>(null)

  const [selectedAnnotationType, setSelectedAnnotationType] = useState<any>([])
  const originalAnnotationType = annotations
    .filter(({ annotation_id }) => annotation_id === popoverData.annotationId)
    .map(({ annotation_type }) => ({
      value: annotation_type.annotation_type_id,
      label: annotation_type.annotation_type_name,
    }))

  const isSelectedAnnotationChanged =
    originalAnnotationType.length > 0 && selectedAnnotationType.length > 0
      ? originalAnnotationType[0].value !== selectedAnnotationType[0].value
      : false

  useEffect(() => {
    setSelectedAnnotationType(originalAnnotationType)
  }, [popoverData.annotationId])

  const updateAnnotation = () => {
    updateAnnotationComment(
      isSelectedAnnotationChanged ? selectedAnnotationType[0].value : null,
    )
  }

  return (
    <Popover
      initialFocusRef={initialFocusRef}
      isOpen={popoverData.x !== 0}
      onClose={() => closePopover()}
    >
      <PopoverTrigger>
        <div
          style={{
            position: 'absolute',
            left: popoverData.x,
            top: height + 10,
          }}
        />
      </PopoverTrigger>
      <PopoverContent _focus={{ outline: 'none', boxShadow: 'none' }}>
        <PopoverBody outline="none">
          <Flex justifyContent="space-between">
            <FormLabel mb="2" fontSize="xs" fontWeight="semibold">
              Annotation comment
            </FormLabel>
            <CloseIcon
              onClick={() => {
                closePopover()
              }}
              cursor="pointer"
              fontSize="10px"
            />
          </Flex>
          <Textarea
            onFocus={(e) => {
              const value = e.target.value
              e.target.value = ''
              e.target.value = value
              e.target.selectionStart = e.target.value.length
            }}
            ref={initialFocusRef}
            mb="2"
            _focus={{
              border: 'none',
            }}
            value={popoverData.text}
            onChange={(e) =>
              setPopoverData({ ...popoverData, text: e.target.value })
            }
            _active={{
              border: 'none',
            }}
            resize="none"
            p="5px"
            fontSize="xs"
            maxLength={1024}
            placeholder="Comment for annotation"
            onKeyDown={(event) => {
              if (event.nativeEvent.code === 'Enter') {
                event.stopPropagation()
                event.preventDefault()
                updateAnnotation()
              }
            }}
          />
          <Flex justifyContent="space-between">
            <Box w="200px">
              <Select
                value={selectedAnnotationType}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                }}
                placeholder={`Select ...`}
                menuPortalTarget={document.body}
                closeMenuOnSelect={true}
                isClearable={false}
                maxMenuHeight={160}
                isMulti={false}
                size="sm"
                onChange={(value: any) => {
                  setSelectedAnnotationType([value])
                  initialFocusRef.current?.focus()
                }}
                isDisabled={isUpdatingAnnotation}
                options={annotationTypes.map(
                  ({ annotation_type_id, annotation_type_name }) => ({
                    value: annotation_type_id,
                    label: annotation_type_name,
                  }),
                )}
                key={popoverData?.annotationId}
              />
            </Box>

            <Button
              isLoading={isUpdatingAnnotation}
              isDisabled={
                popoverData.startText.length === 0
                  ? popoverData.text.length === 0 || isUpdatingAnnotation
                  : isSelectedAnnotationChanged
                  ? false
                  : popoverData.text === popoverData.startText ||
                    isUpdatingAnnotation ||
                    popoverData.text.length === 0
              }
              onClick={updateAnnotation}
              size="sm"
              colorScheme="brand"
            >
              {popoverData.startText.length === 0 ? 'Save' : 'Update'}
            </Button>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
