import { combineReducers } from 'redux'
import { userReducer } from '../../features/user/slice'
import { authReducer } from '../../features/authorization/slice'
import { sessionsReducer } from '../../features/sessions/slice'
import { audioReducer } from '../../features/audio/slice'
import { sessionReducer } from '../../features/session/slice'
import { audioManagementReducer } from '../../features/audioManagement/slice'
import { qualitySurveyReducer } from '../../features/qualitySurvey/slice'
import { sessionApi } from '../../newFeatures/session/service'
import { annotationsApi } from '../../newFeatures/annotations/service'
import { iframeLoginApi } from '../../newFeatures/iframe_login/service'

const rootReducer = combineReducers({
  authorization: authReducer,
  user: userReducer,
  sessions: sessionsReducer,
  session: sessionReducer,
  audio: audioReducer,
  audioManagement: audioManagementReducer,
  qualitySurvey: qualitySurveyReducer,
  [annotationsApi.reducerPath]: annotationsApi.reducer,
  [sessionApi.reducerPath]: sessionApi.reducer,
  [iframeLoginApi.reducerPath]: iframeLoginApi.reducer,
})

export default rootReducer
