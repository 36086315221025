import * as React from 'react'
import {
  Badge,
  Box,
  Button,
  Flex,
  Link,
  Tag,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { Cell } from 'react-table'
import { format } from 'date-fns'
import { timeFormatUtil } from '../../../utils/dateFormats'
import { OverflownText } from '../../../utils/overflownTextCheckTooltip'
const urlPattern = new RegExp('^(http|https)://', 'i')

export const CellComponent = ({ value, column }: Cell) => {
  const { val, type } = value
  if (type === 'text') {
    return (
      <OverflownText
        tooltipChildren={
          <Box p="5px" color="black">
            {val}
          </Box>
        }
        children={
          value.url && urlPattern.test(value.url) ? (
            <Link
              justifyContent="flex-start"
              isExternal
              href={value.url}
              data-qa__id="cell_text_link"
            >
              {val}
            </Link>
          ) : value.component ? (
            value.component
          ) : (
            val
          )
        }
        data-qa__id="cell_text"
      />
    )
  }
  if (type === 'date') {
    return (
      <Box data-qa__id="cell_date">
        {format(
          new Date(val),
          (column as any)?.dateFormat
            ? (column as any)?.dateFormat
            : timeFormatUtil.dayMonthYearHoursMinutesSeconds,
        )}
      </Box>
    )
  }
  if (type === 'checkbox') {
    const isShowFirst = value && value.showFirst
    return (
      <Tooltip
        borderRadius="10px"
        hasArrow
        background="#f7fafc"
        label={
          <Flex gap="5px" p="5px" maxW="md" flexWrap="wrap">
            {Object.keys(val).map((k, index) => (
              <Tag key={`${k}_${index}`}>{k}</Tag>
            ))}
          </Flex>
        }
        isDisabled={Object.keys(val).length === 0}
      >
        <Box
          fontWeight="semibold"
          cursor={Object.keys(val).length > 0 ? 'context-menu' : 'auto'}
          data-qa__id="cell_checkbox"
        >
          {isShowFirst && Object.keys(val).length > 0 ? (
            <Flex fontWeight="normal" alignItems="center" h="100%">
              <Text>{Object.keys(val)[0]}</Text>
              {Object.keys(val).length > 1 &&
                `, +${Object.keys(val).length - 1}`}
            </Flex>
          ) : Object.keys(val).length > 0 ? (
            <Button
              size="sm"
              w="5"
              variant="outline"
              cursor="default"
              _hover={{
                color: 'var(--chakra-colors-brand-500)',
                borderColor: 'var(--chakra-colors-brand-500)',
              }}
              borderWidth="2px"
              aria-label="checkboxes"
            >
              {Object.keys(val).length}
            </Button>
          ) : (
            <>&nbsp;</>
          )}
        </Box>
      </Tooltip>
    )
  }
  if (type === 'tags') {
    return (
      <OverflownText
        tooltipChildren={
          <Flex gap="5px" p="5px" maxW="md" flexWrap="wrap">
            {Object.keys(val).map((k, index) => (
              <Tag key={`${k}_${index}`}>{k}</Tag>
            ))}
          </Flex>
        }
        children={Object.keys(val).map((k, index) => (
          <Tag key={`${k}_${index}`}>{k}</Tag>
        ))}
        data-qa__id="cell_tags"
      />
    )
  }
}
