import React, { useEffect, useMemo, useRef, useState } from 'react'
import { ISessionDetails } from '../../../models/session/session'
import { PlayerCanvasDiv } from './player.components'
import { IAudios } from '../audio.utils'
import { Application, Container, DisplayObject, Graphics } from 'pixi.js'
import {
  Box,
  Button,
  Flex,
  Grid,
  IconButton,
  Text,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'
import { FiPause, FiPlay } from 'react-icons/fi'
import { sound } from '@pixi/sound'
import { getTime } from '../../../utils/audioUtils'
import { mobileBreakPoint } from '../../../utils/mobileBreakPoint'
import { IPlayer } from '../sessionAnnotationPanels/sessionAnnotationPanel'
import { play } from '../session.utils'
import { assignPlayer, IAnnotationRef, resizeRenderer } from './player.utils'
import { useAsyncDebounce } from 'react-table'
import { Text as TextPixi } from '@pixi/text'
import {
  emptyPopoverCommentData,
  IPopoverData,
  SessionPopoverComment,
} from '../../../components/SessionPopoverComment/sessionPopoverComment'
import {
  IAnnotationsElement,
  IAnnotationTypes,
} from '../../../models/annotations/annotations'
import {
  useCreateNewAnnotationMutation,
  useUpdateAnnotationMutation,
} from '../../../newFeatures/annotations/service'
import { updateRef } from './annotations.actions'

export const SessionPlayer = ({
  data,
  scrollBoxRef,
  boxRef,
  audios,
  selectedAudio,
  identifier,
  playhead,
  height,
  isLoadingAudios,
  setPlayer,
  player,
  embedded,
  progress,
  renderProgress,
  setApplication,
  setSelectedAudio,
  application,
  setProgress,
  loader,
  setLoader,
  waves,
  tooltip,
  tooltipText,
  annotationTypesData,
  annotationsData,
  setPopoverData,
  popoverData,
  refetchAnnotations,
  isRemovingAnnotation,
  selectedAnnotationTypeId,
  isAnnotationToolActive,
  selectedAnnotationType,
  resizePlayer,
  annotationRef,
}: {
  data?: ISessionDetails
  boxRef: React.MutableRefObject<any>
  scrollBoxRef: React.MutableRefObject<any>
  audios: IAudios[]
  selectedAudio: IAudios | null
  identifier: string
  playhead: Graphics
  height: number
  isLoadingAudios: boolean
  embedded: boolean
  setPlayer: React.Dispatch<React.SetStateAction<IPlayer>>
  player: IPlayer
  progress: { progress: number; duration: number }
  renderProgress: (progress: number, duration: number) => void
  setApplication: React.Dispatch<React.SetStateAction<Application | null>>
  setSelectedAudio: React.Dispatch<React.SetStateAction<IAudios | null>>
  application: Application | null
  setProgress: React.Dispatch<
    React.SetStateAction<{ progress: number; duration: number }>
  >
  loader: boolean
  setLoader: React.Dispatch<React.SetStateAction<boolean>>
  waves: Container<DisplayObject>[]
  tooltip: Graphics
  tooltipText: TextPixi
  annotationsData?: { data: IAnnotationsElement[] }
  annotationTypesData?: { data: IAnnotationTypes[] }
  setPopoverData: (data: IPopoverData) => void
  popoverData: IPopoverData
  refetchAnnotations: () => void
  isRemovingAnnotation: boolean
  isAnnotationToolActive: boolean
  selectedAnnotationTypeId: string | null
  selectedAnnotationType: {
    id: string | null
    colorHEX: string | null
  }
  annotationRef: IAnnotationRef
  resizePlayer: () => void
}) => {
  const [isMobile] = useMediaQuery(mobileBreakPoint)
  const audioParameter = new URLSearchParams(location.search).get('id')
  const timeParameter = parseInt(
    new URLSearchParams(location.search).get('t') || '0',
    10,
  )
  const toast = useToast()

  const [updateAnnotation] = useUpdateAnnotationMutation()
  const [errors, setErrors] = useState<string[]>([])

  const rerender = (app: Application) => {
    if (application) {
      resizeRenderer({
        app: app ? app : application,
        data,
        height,
        waves,
        renderProgress,
        audios,
        boxRef,
        progress,
        closePopover: () => {
          setPopoverData(emptyPopoverCommentData)
        },
      })
    }
  }

  const resize = (app: Application) => {
    if (application) {
      resizePlayer()
      setPlayer({ ...player, isPlaying: false })
      rerender(app)
    }
  }

  const updateWave = () => {
    if (application) {
      rerender(application)
    }
  }

  useEffect(() => {
    if (boxRef.current) {
      boxRef.current.parentElement.updateWave = updateWave
    }
  }, [boxRef.current])

  const onResize = useAsyncDebounce((app) => {
    resize(app)
  }, 300)

  useEffect(() => {
    updateRef(annotationRef, {
      ...annotationRef.current,
      selectedTypeId: selectedAnnotationTypeId,
      annotationModeAdd: isAnnotationToolActive,
      selectedAnnotationType: selectedAnnotationType,
    })
  }, [selectedAnnotationTypeId, isAnnotationToolActive, selectedAnnotationType])

  const [createNewAnnotation] = useCreateNewAnnotationMutation()

  useEffect(() => {
    if (audios.length === 0 && playhead) {
      return
    }
    assignPlayer({
      selectedAudio,
      annotationRef,
      audios,
      setSelectedAudio,
      setApplication,
      height,
      playhead,
      data,
      identifier,
      audioParameter,
      timeParameter,
      resize: (app) => {
        resize(app)
      },
      onResize,
      boxRef,
      setErrors,
      setLoader,
      errors,
      waves,
      player,
      setPlayer,
      renderProgress,
      setProgress,
      tooltip,
      tooltipText,
      createAnnotation: ({
        position,
        selectedTypeId,
        startTime,
        endTime,
        additionalAction,
      }) => {
        updateRef(annotationRef, {
          ...annotationRef.current,
          isSaving: true,
        })
        if (data) {
          createNewAnnotation({
            sessionId: data.id,
            end: endTime,
            start: startTime,
            description: '',
            annotation_type_id: selectedTypeId,
            is_published: true,
          })
            .unwrap()
            .then(async (response) => {
              await refetchAnnotations()
              additionalAction()
              updateRef(annotationRef, {
                ...annotationRef.current,
                downPosition: null,
                upPosition: null,
                isSaving: false,
              })
              toast({
                title: `Annotation saved`,
                position: 'bottom-left',
                status: 'success',
                isClosable: true,
              })
              setPopoverData({
                x: position - boxRef.current.parentElement?.scrollLeft,
                startText: '',
                sessionId: data ? data.id : '',
                annotationId: response.data.annotation_id,
                text: '',
                start: startTime,
                end: endTime,
              })
            })
            .catch(() => {
              updateRef(annotationRef, {
                ...annotationRef.current,
                downPosition: null,
                upPosition: null,
                isSaving: false,
              })
              toast({
                title: 'Error while saving annotation',
                position: 'bottom-left',
                status: 'error',
                isClosable: true,
              })
            })
        }
      },
    })
  }, [audios.length])

  const CanvasComponent = useMemo(() => {
    playhead.tint = selectedAudio?.isConversion ? 0x986cbd : 0x1b91d6
    return PlayerCanvasDiv({
      audios,
      selectedAudio,
      boxRef,
      height,
      identifier,
      scrollBoxRef,
    })
  }, [audios.length, selectedAudio])

  const [isUpdatingAnnotation, setIsUpdatingAnnotation] = useState(false)

  const updateAnnotationData = async (selectedTypeId?: string) => {
    if (popoverData.annotationId && popoverData.sessionId) {
      setIsUpdatingAnnotation(true)
      await updateAnnotation({
        sessionId: popoverData.sessionId,
        annotationId: popoverData.annotationId,
        start: popoverData.start,
        end: popoverData.end,
        description: popoverData.text,
        selectedTypeId,
      })
        .unwrap()
        .then(() => {
          setPopoverData(emptyPopoverCommentData)
          setIsUpdatingAnnotation(false)
          refetchAnnotations()
          toast({
            title: `Annotation updated`,
            position: 'bottom-left',
            status: 'success',
            isClosable: true,
          })
        })
        .catch((response) => {
          setIsUpdatingAnnotation(false)
          toast({
            title: 'Error while updating annotation',
            position: 'bottom-left',
            status: 'error',
            isClosable: true,
          })
        })
    }
  }

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === '-') {
        boxRef.current.parentElement.updateZoom(-1)
      } else if (event.key === '+') {
        boxRef.current.parentElement.updateZoom(1)
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <>
      <Box position="relative">
        {popoverData.start !== 0 &&
          popoverData.end !== 0 &&
          annotationsData &&
          annotationTypesData && (
            <SessionPopoverComment
              annotations={annotationsData.data}
              annotationTypes={annotationTypesData.data}
              setPopoverData={setPopoverData}
              height={height}
              closePopover={() => setPopoverData(emptyPopoverCommentData)}
              popoverData={popoverData}
              updateAnnotationComment={async (selectedTypeId?: string) => {
                await updateAnnotationData(selectedTypeId)
              }}
              isUpdatingAnnotation={
                isUpdatingAnnotation || isRemovingAnnotation
              }
            />
          )}
        {CanvasComponent}
      </Box>
      <Grid gridTemplateColumns={embedded || isMobile ? '1fr 1fr' : '1fr'}>
        <Flex mb="1" gap="5" alignItems="flex-start" paddingLeft="20px">
          {selectedAudio && !player.isPlaying && (
            <IconButton
              isDisabled={isLoadingAudios || !selectedAudio || loader}
              _disabled={{
                opacity: 1,
                background: '#eaeaea',
                pointerEvents: 'none',
                '&:hover': { background: '#eaeaea' },
              }}
              borderRadius="100%"
              border="2px solid #fff"
              marginLeft="-18px"
              size="lg"
              marginTop="-22px"
              disabled={isLoadingAudios || loader}
              colorScheme={
                selectedAudio?.isConversion ? 'brandViolet' : 'brand'
              }
              icon={<FiPlay size="18px" />}
              onClick={async () => {
                play({
                  name: selectedAudio.name,
                  id: selectedAudio.id,
                  startAt: progress.progress * progress.duration,
                  player,
                  setPlayer,
                  data,
                  renderProgress,
                })
                setPlayer({
                  ...player,
                  isPlaying: true,
                })
              }}
              aria-label="resume"
            />
          )}
          {selectedAudio && player.isPlaying && (
            <IconButton
              borderRadius="100%"
              border="2px solid #fff"
              icon={<FiPause size="18px" />}
              disabled={isLoadingAudios || loader}
              size="lg"
              marginLeft="-18px"
              marginTop="-22px"
              colorScheme={
                selectedAudio?.isConversion ? 'brandViolet' : 'brand'
              }
              onClick={() => {
                setPlayer({ ...player, isPlaying: false })
                sound.stopAll()
              }}
              aria-label="pause"
              data-qa__id="audio_control_pause"
            />
          )}
        </Flex>
        {!embedded && !isMobile ? (
          <Flex justifyContent="flex-end" marginTop="-30px">
            <Flex gap="1" mt="2" fontSize="sm" right="0">
              <Text data-qa__id="audio_duration">
                {getTime({
                  duration:
                    Math.floor(progress.duration * progress.progress) * 1000,
                })}
              </Text>
              {data && data.duration && (
                <Text> / {getTime({ duration: data.duration })}</Text>
              )}
            </Flex>
          </Flex>
        ) : null}
      </Grid>
    </>
  )
}
