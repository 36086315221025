import { AppEpic } from '../../../utils/reduxUtils'
import {
  getSession,
  removeSession,
  removeSessionRecordingAndConversions,
  saveNewSession,
  saveSession,
  setIsLoadingSession,
  setIsRemovingSession,
  setIsSavingSession,
  setSession,
  subscribeSession,
  updateGetterForSession,
} from '../actions'
import {
  catchError,
  delay,
  filter,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators'
import { concat, EMPTY, of, Subject } from 'rxjs'
import { ISessionDetails } from '../../../models/session/session'
import { querySessions } from '../../sessions/actions'
import { checkIsStatus401 } from '../../authorization/actions'

let pause$ = new Subject()
let resume$ = new Subject()
export const UpdateGetterForSession: AppEpic<
  ReturnType<typeof updateGetterForSession>
> = (action$, state$, { session }) => {
  return action$.pipe(
    filter(updateGetterForSession.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      if (!action.payload.isSubscription) {
        pause$.next()
        return EMPTY
      } else if (action.payload.id) {
        resume$.next()

        return of(subscribeSession({ id: action.payload.id }))
      }
      return EMPTY
    }),
  )
}

export const SubscribeForSession: AppEpic<
  ReturnType<typeof subscribeSession>
> = (action$, state$, { session }) => {
  return action$.pipe(
    filter(subscribeSession.match),
    withLatestFrom(state$),
    delay(5000),
    takeUntil(pause$),
    switchMap(([action, state]) => {
      const sessionData = state.session.session

      if (
        (window.location.pathname.includes(action.payload.id.toString()) ||
          window.location.pathname.includes('live')) &&
        (!sessionData.download_url ||
          sessionData.transcription_state === 'processing')
      ) {
        return concat(
          session.getSessionService(action.payload.id, true).pipe(
            switchMap(({ response }) => {
              const shouldUpdateConversionUrl = response.conversions.filter(
                (el: ISessionDetails) =>
                  !el.download_url || el.transcription_state === 'processing',
              ).length
              const stateConversions: any = state.session.session.conversions
              const emptyConversions = stateConversions.filter(
                (el: ISessionDetails) => !el.download_url,
              ).length
              const sessionTranscription =
                response.transcription &&
                response.transcription_state === 'processing'

              if (shouldUpdateConversionUrl > 0 || sessionTranscription) {
                return concat(
                  of(subscribeSession(action.payload)),
                  emptyConversions !== shouldUpdateConversionUrl
                    ? of(setSession({ session: response }))
                    : EMPTY,
                )
              } else {
                return concat(of(setSession({ session: response })))
              }
            }),
            catchError((err) => {
              return concat(of(checkIsStatus401(err.status)))
            }),
          ),
        )
      } else {
        return EMPTY
      }
    }),
  )
}

export const GetSession: AppEpic<ReturnType<typeof getSession>> = (
  action$,
  state$,
  { session },
) =>
  action$.pipe(
    filter(getSession.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      return concat(
        action.payload.silent ? EMPTY : of(setIsLoadingSession(true)),
        session.getSessionService(action.payload.id, true).pipe(
          switchMap((AjaxResponse) => {
            const { response } = AjaxResponse
            const shouldUpdateConversionUrl =
              response.conversions.filter(
                (el: ISessionDetails) =>
                  !el.download_url || el.transcription.length === 0,
              ).length > 0
            const isTranscriptionEmpty = action.payload.getTranscription
              ? response.download_url &&
                response.transcription &&
                response.transcription.length === 0
              : action.payload.getTranscription

            if (shouldUpdateConversionUrl || isTranscriptionEmpty) {
              return concat(
                of(setIsLoadingSession(false)),
                of(setSession({ session: response })),
                of(
                  updateGetterForSession({
                    isSubscription: true,
                    id: action.payload.id,
                  }),
                ),
              )
            }
            return concat(
              of(setIsLoadingSession(false)),
              of(setSession({ session: response })),
            )
          }),
          catchError((err: any) => {
            return concat(
              of(setIsLoadingSession(false)),
              // @ts-ignore
              of(setSession({ session: {} })),
              of(checkIsStatus401(err.status)),
            )
          }),
        ),
      )
    }),
  )

export const SaveNewSession: AppEpic<ReturnType<typeof saveNewSession>> = (
  action$,
  state$,
  { session },
) =>
  action$.pipe(
    filter(saveNewSession.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      return concat(
        of(setIsSavingSession(true)),
        session
          .saveSession({
            body: {
              ...action.payload.body,
              showroom_categories: [],
            },
            isNew: true,
          })
          .pipe(
            switchMap(({ response }) => {
              action.payload.navigate(`/session/${response.id}`)
              return concat(of(setIsSavingSession(false)))
            }),
            catchError((err) => {
              if (action.payload.toast) {
                action.payload.toast({
                  title: err.response.error
                    ? err.response.error
                    : `Error while saving session`,
                  position: 'bottom-left',
                  status: 'error',
                  isClosable: true,
                })
              }
              action.payload.nameUpdater('')
              return concat(
                of(setIsSavingSession(false)),
                of(checkIsStatus401(err.status)),
              )
            }),
          ),
      )
    }),
  )

export const SaveSession: AppEpic<ReturnType<typeof saveSession>> = (
  action$,
  state$,
  { session },
) =>
  action$.pipe(
    filter(saveSession.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      return concat(
        of(setIsSavingSession(true)),
        session
          .saveSession({
            body: action.payload.body,
            isNew: false,
          })
          .pipe(
            switchMap(({ response }) => {
              if (action.payload.additionalFunction) {
                action.payload.additionalFunction()
              }

              if (action.payload.navigate) {
                action.payload.navigate(
                  `/session/${response.data.recording_id}`,
                )
              }
              return concat(
                of(
                  setSession({
                    ...state.session,
                    session: {
                      ...state.session.session,
                      name: response.data.name,
                    },
                  }),
                ),
                of(setIsSavingSession(false)),
              )
            }),
            catchError((err) => {
              if (action.payload.toast) {
                action.payload.toast({
                  title: `Error while updating session`,
                  position: 'bottom-left',
                  status: 'error',
                  isClosable: true,
                })
              }
              if (action.payload.additionalFunction) {
                action.payload.additionalFunction()
              }
              return concat(
                of(setIsSavingSession(false)),
                of(checkIsStatus401(err.status)),
              )
            }),
          ),
      )
    }),
  )

export const RemoveSession: AppEpic<ReturnType<typeof removeSession>> = (
  action$,
  state$,
  { session },
) =>
  action$.pipe(
    filter(removeSession.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      let url = state.sessions.usedQuery.url
      if (
        state.sessions.usedQuery.url &&
        state.sessions.response.results.length === 1
      ) {
        url = state.sessions.response.previous
      }
      return concat(
        of(updateGetterForSession({ isSubscription: false })),
        of(setIsRemovingSession(true)),
        session.removeSession(action.payload.id).pipe(
          switchMap(() => {
            if (action.payload.toast) {
              action.payload.toast({
                title: 'Session removed',
                position: 'bottom-left',
                status: 'success',
                isClosable: true,
              })
            }
            if (action.payload.redirect) {
              action.payload.redirect.navigate()
            }
            return concat(
              of(setIsRemovingSession(false)),
              action.payload.isReloadList
                ? of(querySessions({ ...state.sessions.usedQuery, url }))
                : EMPTY,
            )
          }),
          catchError((err) => {
            if (action.payload.toast) {
              action.payload.toast({
                title: 'Error while deleting session',
                position: 'bottom-left',
                status: 'error',
                isClosable: true,
              })
            }
            return concat(
              of(setIsRemovingSession(false)),
              of(checkIsStatus401(err.status)),
            )
          }),
        ),
      )
    }),
  )
export const RemoveSessionRecordingAndConversions: AppEpic<
  ReturnType<typeof removeSessionRecordingAndConversions>
> = (action$, state$, { session }) =>
  action$.pipe(
    filter(removeSessionRecordingAndConversions.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      return concat(
        of(updateGetterForSession({ isSubscription: false })),
        of(setIsRemovingSession(true)),
        session.removeSessionRecordingAndConv(action.payload.sessionId).pipe(
          switchMap(() => {
            if (action.payload.toast) {
              action.payload.toast({
                title: 'Successfully removed session recording',
                position: 'bottom-left',
                status: 'success',
                isClosable: true,
              })
            }
            if (action.payload.navigate && action.payload.navigateTo) {
              action.payload.navigate(action.payload.navigateTo)
            }

            return concat(
              of(setIsRemovingSession(false)),
              of(getSession({ id: action.payload.sessionId, silent: false })),
            )
          }),
          catchError((err) => {
            if (action.payload.toast) {
              action.payload.toast({
                title: 'Error while removing session recording and coversions',
                position: 'bottom-left',
                status: 'error',
                isClosable: true,
              })
            }
            return concat(
              of(setIsRemovingSession(false)),
              of(checkIsStatus401(err.status)),
            )
          }),
        ),
      )
    }),
  )
