import OBSWebSocket from 'obs-websocket-js'

export async function setAsyncDelayFilter(amount) {
  const obs = new OBSWebSocket()

  try {
    await obs.connect('ws://127.0.0.1:4455', undefined)
    console.log('Connected to OBS WebSocket successfully!')

    const sourceName = 'Meaning'

    await obs
      .call('RemoveSourceFilter', {
        sourceName: sourceName,
        filterName: 'Video Delay (Async)',
      })
      .catch(() =>
        console.log('No existing filter to remove, or removal failed'),
      )

    await obs.call('CreateSourceFilter', {
      sourceName: sourceName,
      filterName: 'Video Delay (Async)',
      filterKind: 'async_delay_filter',
      filterSettings: {
        delay_ms: amount,
      },
    })

    console.log('Async Delay filter set successfully!')
  } catch (error) {
    console.error('Failed to set filter:', error)
  } finally {
    obs.disconnect()
  }
}
