import { createEpicMiddleware } from 'redux-observable'

import rootReducer from './rootReducer'
import rootEpic from './rootEpic'
import services from '../../services'
import { configureStore } from '@reduxjs/toolkit'
import { sessionApi } from '../../newFeatures/session/service'
import { annotationsApi } from '../../newFeatures/annotations/service'
import { iframeLoginApi } from '../../newFeatures/iframe_login/service'

const epicMiddleware: any = createEpicMiddleware<any, any, any, any>({
  dependencies: services,
})

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(epicMiddleware)
      .concat(sessionApi.middleware)
      .concat(annotationsApi.middleware)
      .concat(iframeLoginApi.middleware),
})
epicMiddleware.run(rootEpic)

export default store
