/* eslint-disable guard-for-in */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
import { createSlice } from '@reduxjs/toolkit'
import { IAudio } from '../../models/audio/audio'
import {
  setAudioPermissions,
  setAvailableDevices,
  setSelectedDevices,
  setSelectedVoice,
  updateDeviceTest,
  updateMediaState,
} from './actions'

export const initialAudio: IAudio = {
  deviceTest: {
    isOpen: false,
  },
  areAudioPermissions: false,
  availableDevices: {
    input_devices: null,
    output_devices: null,
  },
  mediaState: {
    isOnALiveCall: false,
    isStartingUpload: false,
    latency: [0, 0, 0, 0, 0],
    isInputConnected: undefined,
    isOutputConnected: undefined,
    isInputAudioRegistered: undefined,
    isOutputAudioRegistered: undefined,
    isConnected: false,
    isConnecting: false,
    isRecording: false,
    canBeRecorded: false,
    shouldBeRecorded: false,
    isUploading: false,
    canJoinLiveCall: false,
    roomId: '',
    datacenter: '',
  },
  selectedVoice: {
    voice: {
      id: '',
      voice_id: '',
      model_id: '',
      display_name: '',
      key: '',
      description: '',
      data_center_id: '',
      latency: 0,
    },
  },
  selectedDevices: {
    output_device: null,
    input_device: null,
  },
}

export const audioSlice = createSlice({
  name: 'audio',
  initialState: initialAudio,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(setAudioPermissions.match, (state, { payload }) => {
      state.areAudioPermissions = payload
    })
    builder.addMatcher(setAvailableDevices.match, (state, { payload }) => {
      state.availableDevices = payload
    })
    builder.addMatcher(setSelectedDevices.match, (state, { payload }) => {
      state.selectedDevices = payload
    })
    builder.addMatcher(setSelectedVoice.match, (state, { payload }) => {
      state.selectedVoice = payload
    })
    builder.addMatcher(updateDeviceTest.match, (state, { payload }) => {
      state.deviceTest = payload
    })
    builder.addMatcher(updateMediaState.match, (state, { payload }) => {
      state.mediaState = {
        ...state.mediaState,
        ...payload,
      }
    })
  },
})

export const audioReducer = audioSlice.reducer
