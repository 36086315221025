import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
  Input,
  Button,
  IconButton,
  Flex,
  Text,
  Box,
  InputGroup,
  InputRightElement,
  Spinner,
  Checkbox,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  InputLeftElement,
  FormLabel,
  ButtonGroup,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Column } from 'react-table'
import { FiFilter } from 'react-icons/fi'
import { Select } from 'chakra-react-select'
import { AddIcon, MinusIcon, SearchIcon } from '@chakra-ui/icons'
import { uniqBy } from 'ramda'
import ReactStars from 'react-stars'
import TimeField from 'react-simple-timefield'

export const Filters = ({
  column,
  usedFilters,
  usedDurations,
  usedLatency,
}: {
  usedFilters: { key: string; value: string }[]
  column: Column
  usedDurations: {
    min?: number
    max?: number
  }
  usedLatency: {
    min?: number
    max?: number
  }
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef: any = React.useRef()
  const filtersInstance = (column as any)?.columns?.filter(
    ({ filterType, canFilter }: any) =>
      filterType !== 'datepicker' && canFilter,
  )
  const hhmmssToSeconds = (time: string) => {
    const [hours, minutes, seconds] = time.split(':').map(Number)
    return hours * 3600 + minutes * 60 + seconds
  }

  const [durationFilter, setDurationFilter] = useState<{
    min: number
    max: number
  }>({
    min: usedDurations.min ? usedDurations.min : 0,
    max: usedDurations.max ? usedDurations.max : 0,
  })

  const [latencyFilter, setLatencyFilter] = useState<{
    min: number
    max: number
  }>({
    min: usedLatency.min ? usedLatency.min : 0,
    max: usedLatency.max ? usedLatency.max : 0,
  })

  useEffect(() => {
    setDurationFilter({
      min: usedDurations.min ? usedDurations.min : 0,
      max: usedDurations.max ? usedDurations.max : 0,
    })
  }, [usedDurations])

  useEffect(() => {
    setLatencyFilter({
      min: usedLatency.min ? usedLatency.min : 0,
      max: usedLatency.max ? usedLatency.max : 0,
    })
  }, [usedLatency])

  const [durationError, setDurationError] = useState(false)
  const [latencyError, setLatencyError] = useState(false)
  useEffect(() => {
    setDurationError(false)
    setLatencyError(false)
  }, [isOpen])

  const secondsToHhmmss = (totalSeconds: number) => {
    const hours = Math.floor(totalSeconds / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)
    const seconds = totalSeconds % 60

    const pad = (num: number) => String(num).padStart(2, '0')
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`
  }

  const isUsedLatency =
    (usedLatency.min && usedLatency.min > 0) ||
    (usedLatency.max && usedLatency.max > 0)
  const isUsedDuration =
    (usedDurations.min && usedDurations.min > 0) ||
    (usedDurations.max && usedDurations.max > 0)

  return (
    <>
      <IconButton
        data-qa__id="table_filters_button"
        ref={btnRef}
        variant="outline"
        px="7"
        onClick={() => {
          onOpen()
        }}
        aria-label={'Filters'}
        icon={
          <Box position="relative">
            <FiFilter />
            {((usedFilters && usedFilters.length > 0) ||
              isUsedLatency ||
              isUsedDuration) && (
              <Flex
                justifyContent="center"
                alignItems="center"
                w="3"
                right="-6px"
                bottom="-6px"
                h="3"
                position="absolute"
                borderRadius="50%"
                background="var(--chakra-colors-brand-500)"
                color="white"
                fontSize="8px"
                pt="1px"
                data-qa__id="table_filters_count"
              >
                {isUsedLatency && isUsedDuration
                  ? usedFilters.length + 2
                  : isUsedDuration || isUsedLatency
                  ? usedFilters.length + 1
                  : usedFilters.length}
              </Flex>
            )}
          </Box>
        }
      />
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton data-qa__id="table_filters_close_button" />
          <DrawerHeader>Filters</DrawerHeader>
          <DrawerBody
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'var(--chakra-colors-brand-500)',
                borderRadius: '24px',
              },
            }}
            data-qa__id="table_filters_body"
          >
            <Accordion
              allowMultiple
              defaultIndex={filtersInstance
                .map((el: any, index: any) =>
                  el.filterValue ? index : undefined,
                )
                .filter((el: any) => el !== undefined)}
            >
              {filtersInstance.map(
                (
                  {
                    id,
                    Header,
                    setFilter,
                    filterHeader,
                    filterKey,
                    filterValue,
                    filterType,
                    onSearchField,
                    selectOptions,
                    placeholder,
                    isLoadingSearchValues,
                    searchResults,
                    isSearchCheckbox,
                  }: any,
                  index: any,
                ) => {
                  const uniqueCheckbox = uniqBy(
                    ({ id }) => id,
                    [...(searchResults || [])],
                  )

                  const filters = usedFilters.filter(
                    (usedFilter) => usedFilter.key === filterKey,
                  )
                  const [searchField, setSearchField] = useState('')
                  useEffect(() => {
                    setSearchField('')
                  }, [])

                  const handleFilterChange = (type: string, value: any) => {
                    const newDuration = {
                      ...durationFilter,
                    }
                    // @ts-ignore
                    newDuration[type] = hhmmssToSeconds(value)

                    setDurationError(newDuration.max < newDuration.min)
                    setDurationFilter(newDuration)
                  }

                  const handleLatencyFilterChange = (
                    type: string,
                    value: any,
                  ) => {
                    const newLatency: any = {
                      ...latencyFilter,
                    }
                    newLatency[type] = value
                    newLatency.min = parseInt(newLatency.min, 10)
                    newLatency.max = parseInt(newLatency.max, 10)

                    setLatencyError(newLatency.max < newLatency.min)
                    setLatencyFilter(newLatency)
                  }

                  const currentFilterValue = durationFilter || {
                    min: usedDurations.min ? usedDurations.min : 0,
                    max: usedDurations.max ? usedDurations.max : 0,
                  }

                  const currentLatencyFilterValue = latencyFilter || {
                    min: usedLatency.min ? usedLatency.min : 0,
                    max: usedLatency.max ? usedLatency.max : 0,
                  }

                  return (
                    ((filterType === 'select' && selectOptions.length > 0) ||
                      filterType !== 'select') && (
                      <AccordionItem
                        key={`filters_instance_${Header}_${index}_${id}`}
                        data-qa__id="table_filters_category"
                      >
                        {({ isExpanded }) => (
                          <>
                            <h2>
                              <AccordionButton>
                                <Box flex="1" textAlign="left">
                                  <Text fontWeight="semibold">
                                    {filterHeader ? filterHeader : Header}
                                  </Text>
                                </Box>
                                {isExpanded ? (
                                  <MinusIcon
                                    fontSize="12px"
                                    data-qa__id="table_filters_category_close"
                                  />
                                ) : (
                                  <AddIcon
                                    fontSize="12px"
                                    data-qa__id="table_filters_category_open"
                                  />
                                )}
                              </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                              {(filterType === 'checkbox' ||
                                filterType === 'scores') && (
                                <Flex flexDirection="column">
                                  {uniqueCheckbox.length > 0 &&
                                    isSearchCheckbox && (
                                      <InputGroup>
                                        <InputLeftElement pointerEvents="auto">
                                          <SearchIcon
                                            cursor="auto"
                                            color="#a0aec0"
                                            boxSize="5"
                                          />
                                        </InputLeftElement>
                                        <Input
                                          onChange={(event) => {
                                            if (onSearchField) {
                                              onSearchField(event.target.value)
                                            }
                                            setSearchField(event.target.value)
                                          }}
                                          value={searchField}
                                          bg="white"
                                          fontSize="sm"
                                          placeholder={
                                            placeholder
                                              ? placeholder
                                              : `${Header} search`
                                          }
                                        />
                                        {isLoadingSearchValues && (
                                          <InputRightElement pointerEvents="none">
                                            <Spinner />
                                          </InputRightElement>
                                        )}
                                      </InputGroup>
                                    )}
                                  {uniqueCheckbox.length > 0 ? (
                                    uniqueCheckbox
                                      .filter(({ label }) => {
                                        return searchField === ''
                                          ? true
                                          : label
                                              .toLowerCase()
                                              .includes(
                                                searchField.toLowerCase(),
                                              )
                                      })
                                      .map((filterItem, index) => {
                                        const isChecked = filters.some(
                                          (filter) =>
                                            filter.value === filterItem.id,
                                        )

                                        return (
                                          <Checkbox
                                            mt="2"
                                            key={`checkbox_${filterItem.id}`}
                                            isChecked={isChecked}
                                            onChange={(e) => {
                                              if (!isChecked) {
                                                setFilter([
                                                  ...filters,
                                                  {
                                                    value: filterItem.id,
                                                    key: filterKey,
                                                  },
                                                ])

                                                return
                                              }

                                              setFilter(
                                                filters.filter(
                                                  (filter) =>
                                                    filter.value !==
                                                    filterItem.id,
                                                ),
                                              )
                                            }}
                                            data-qa__id="table_filters_item"
                                          >
                                            <Flex
                                              fontSize="16px"
                                              fontWeight="400"
                                              alignItems="center"
                                            >
                                              {filterType === 'checkbox' ? (
                                                filterItem.label
                                              ) : (
                                                <ReactStars
                                                  value={filterItem.label}
                                                  half={false}
                                                  count={5}
                                                  edit={false}
                                                  size={12}
                                                  color2="#1b91d6"
                                                />
                                              )}{' '}
                                              ({filterItem.count})
                                            </Flex>
                                          </Checkbox>
                                        )
                                      })
                                  ) : (
                                    <Text pl="2">
                                      {!isSearchCheckbox && 'No results'}
                                    </Text>
                                  )}
                                </Flex>
                              )}

                              {filterType === 'avg_latency' && (
                                <Flex direction="column" gap="2">
                                  <InputGroup gap="2">
                                    <Box>
                                      <FormLabel
                                        htmlFor="min-latency"
                                        fontSize="2xs"
                                      >
                                        Min latency
                                      </FormLabel>
                                      <Input
                                        type="number"
                                        value={
                                          currentLatencyFilterValue.min > 0
                                            ? currentLatencyFilterValue.min.toFixed(
                                                0,
                                              )
                                            : 0
                                        }
                                        borderColor={
                                          latencyError ? 'red.500' : undefined
                                        }
                                        onChange={(event) => {
                                          handleLatencyFilterChange(
                                            'min',
                                            event.target.value || 0,
                                          )
                                        }}
                                      />
                                    </Box>
                                    <Box>
                                      <FormLabel
                                        htmlFor="min-latency"
                                        fontSize="2xs"
                                      >
                                        Max latency
                                      </FormLabel>
                                      <Input
                                        type="number"
                                        value={
                                          currentLatencyFilterValue.max > 0
                                            ? currentLatencyFilterValue.max.toFixed(
                                                0,
                                              )
                                            : 0
                                        }
                                        onChange={(event) => {
                                          handleLatencyFilterChange(
                                            'max',
                                            event.target.value || 0,
                                          )
                                        }}
                                        borderColor={
                                          latencyError ? 'red.500' : undefined
                                        }
                                      />
                                    </Box>
                                  </InputGroup>
                                  {latencyError && (
                                    <Text color="red.500" fontSize="2xs">
                                      Maximum latency cannot be less than
                                      minimum.
                                    </Text>
                                  )}
                                  <ButtonGroup justifyContent="flex-end">
                                    {((usedLatency.max ?? 0) > 0 ||
                                      (usedLatency.min ?? 0) > 0) && (
                                      <Button
                                        onClick={() => {
                                          setLatencyError(false)
                                          setLatencyFilter({
                                            min: 0,
                                            max: 0,
                                          })
                                          setFilter((prev: any) => ({
                                            min: 0,
                                            max: 0,
                                          }))
                                        }}
                                      >
                                        Clear
                                      </Button>
                                    )}
                                    <Button
                                      isDisabled={
                                        latencyError ||
                                        (latencyFilter.min ===
                                          usedLatency.min &&
                                          latencyFilter.max === usedLatency.max)
                                      }
                                      colorScheme="blue"
                                      w="80px"
                                      alignSelf="flex-end"
                                      onClick={() => {
                                        setFilter((prev: any) => latencyFilter)
                                      }}
                                    >
                                      Apply
                                    </Button>
                                  </ButtonGroup>
                                </Flex>
                              )}

                              {filterType === 'duration' && (
                                <Flex direction="column" gap="2">
                                  <InputGroup gap="2">
                                    <Box>
                                      <FormLabel
                                        htmlFor="min-duration"
                                        fontSize="2xs"
                                      >
                                        Min duration (hh:mm:ss)
                                      </FormLabel>
                                      <TimeField
                                        value={secondsToHhmmss(
                                          currentFilterValue.min,
                                        )}
                                        onChange={(event, value) => {
                                          handleFilterChange('min', value || '')
                                        }}
                                        input={
                                          <Input
                                            borderColor={
                                              durationError
                                                ? 'red.500'
                                                : undefined
                                            }
                                          />
                                        }
                                        colon=":"
                                        showSeconds
                                      />
                                    </Box>
                                    <Box>
                                      <FormLabel
                                        htmlFor="min-duration"
                                        fontSize="2xs"
                                      >
                                        Max duration (hh:mm:ss)
                                      </FormLabel>
                                      <TimeField
                                        value={secondsToHhmmss(
                                          currentFilterValue.max,
                                        )}
                                        onChange={(event, value) => {
                                          handleFilterChange('max', value || '')
                                        }}
                                        input={
                                          <Input
                                            borderColor={
                                              durationError
                                                ? 'red.500'
                                                : undefined
                                            }
                                          />
                                        }
                                        colon=":"
                                        showSeconds
                                      />
                                    </Box>
                                  </InputGroup>
                                  {durationError && (
                                    <Text color="red.500" fontSize="2xs">
                                      Maximum duration cannot be less than
                                      minimum.
                                    </Text>
                                  )}
                                  <ButtonGroup justifyContent="flex-end">
                                    {((usedDurations.max ?? 0) > 0 ||
                                      (usedDurations.min ?? 0) > 0) && (
                                      <Button
                                        onClick={() => {
                                          setDurationError(false)
                                          setFilter((prev: any) => ({
                                            min: 0,
                                            max: 0,
                                          }))
                                        }}
                                      >
                                        Clear
                                      </Button>
                                    )}

                                    <Button
                                      isDisabled={
                                        durationError ||
                                        (durationFilter.min === 0 &&
                                          durationFilter.max === 0) ||
                                        (durationFilter.min ===
                                          usedDurations.min &&
                                          durationFilter.max ===
                                            usedDurations.max)
                                      }
                                      colorScheme="blue"
                                      w="80px"
                                      alignSelf="flex-end"
                                      onClick={() => {
                                        setFilter((prev: any) => durationFilter)
                                      }}
                                    >
                                      Apply
                                    </Button>
                                  </ButtonGroup>
                                </Flex>
                              )}
                              {filterType === 'text' && (
                                <Input
                                  onChange={(e) => {
                                    setFilter(e.target.value || undefined)
                                  }}
                                  bg="white"
                                  value={filterValue || ''}
                                  fontSize="sm"
                                  placeholder={Header}
                                />
                              )}
                              {filterType === 'select' && (
                                <Select
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  menuPortalTarget={document.body}
                                  closeMenuOnSelect={false}
                                  isClearable={true}
                                  maxMenuHeight={160}
                                  classNamePrefix="filter_select"
                                  isMulti={true}
                                  value={filterValue || ''}
                                  onChange={(value) =>
                                    setFilter(value || undefined)
                                  }
                                  options={selectOptions}
                                />
                              )}
                            </AccordionPanel>
                          </>
                        )}
                      </AccordionItem>
                    )
                  )
                },
              )}
            </Accordion>
          </DrawerBody>

          <DrawerFooter>
            <Button
              variant="outline"
              mr={3}
              onClick={() => {
                onClose()
              }}
              data-qa__id="table_filters_footer_close_button"
            >
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}
