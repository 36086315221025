import React, { useEffect, useRef, useState } from 'react'
import { SessionHeader } from '../sessionHeader/sessionHeader'
import { Flex, Tabs, useMediaQuery, useToast } from '@chakra-ui/react'
import { ISessionDetails } from '../../../models/session/session'
import { SessionControlButtons } from '../sessionControlButtons/controlButtons'
import { mobileBreakPoint } from '../../../utils/mobileBreakPoint'
import { useAppSelector } from '../../../utils/reduxUtils'
import { IAudios } from '../audio.utils'
import {
  useDeleteAnnotationMutation,
  useGetAllAnnotationsForSessionQuery,
  useGetAnnotationTypesQuery,
  useUpdateAnnotationMutation,
} from '../../../newFeatures/annotations/service'
import { SessionVoicesControl } from '../sessionVoicesControll/sessionVoicesControl'
import { SessionFooter } from '../SessionFooter/sessionFooter'
import { SessionAnnotationTypes } from '../sessionAnnotationTypes/sessionAnnotationTypes'
import {
  refreshAnnotationTypes,
  textTooltip,
  tooltipElement,
  updateAnnotationOnWave,
  updateWave,
} from '../annotations.utils'
import {
  emptyPlayer,
  IPlayer,
} from '../sessionAnnotationPanels/sessionAnnotationPanel'
import { sound } from '@pixi/sound'
import { playAnnotation } from '../session.utils'
import { Application, Container, Graphics } from 'pixi.js'
import { SessionPlayer } from '../sessionPlayer/sessionPlayer'
import { assignTooltip, stop } from '../sessionPlayer/player.utils'
import {
  emptyPopoverCommentData,
  IPopoverData,
} from '../../../components/SessionPopoverComment/sessionPopoverComment'
import { updateRef } from '../sessionPlayer/annotations.actions'

let annotationModeAdd: boolean = false

let selectedTypeId: string | null = null
let selectedAnnotationType: { id: string | null; colorHEX: string | null } = {
  id: null,
  colorHEX: null,
}
let isResizing = false

export const SessionComponent = ({
  refetchSession,
  data,
  embedded,
  disabled,
  audios,
  isDemo,
  isLoadingAudios,
  voicesList,
  flags,
  setVoice,
  selectedIndex,
}: {
  refetchSession?: () => void
  data?: ISessionDetails
  embedded: boolean
  disabled: boolean
  audios: IAudios[]
  isDemo?: boolean
  isLoadingAudios: boolean
  voicesList?: string[]
  selectedIndex?: number
  flags?: { [id: string]: string }
  setVoice?: (index: number) => void
}) => {
  const toast = useToast()
  const [isMobile] = useMediaQuery(mobileBreakPoint)
  const height = embedded || isMobile ? 120 : 140
  const [loader, setLoader] = useState<boolean>(true)
  const [waves, setWaves] = useState(
    Array.from(
      {
        length: isDemo
          ? audios.length
          : (data?.conversions ? data?.conversions.length : 0) + 1,
      },
      () => new Container(),
    ),
  )

  const [tooltip] = useState(tooltipElement)
  const [tooltipText] = useState(textTooltip)
  assignTooltip({ tooltipText, tooltip })

  const [progress, setProgress] = useState({ progress: 0, duration: 0 })
  const boxRef = useRef<any>()
  const scrollBoxRef = useRef<any>()
  const [playhead] = useState(
    new Graphics().beginFill(0xffffff).drawRect(-2, 0, 1, height),
  )
  const [application, setApplication] = useState<Application | null>(null)
  const [annotationsChildren, setAnnotationsChildren] = useState<any>([])

  const [isAnnotationToolActive, setIsAnnotationToolActive] =
    useState<boolean>(false)
  const { is_annotator } = useAppSelector((state) => state.user)
  const {
    data: annotationTypesData,
    isLoading: annotationTypesLoading,
    refetch: refetchAnnotationTypes,
  } = useGetAnnotationTypesQuery(
    { id: data?.id },
    { skip: embedded || isMobile || isDemo },
  )
  const {
    data: annotationsData,
    isLoading: annotationsLoading,
    refetch: refetchAnnotations,
  } = useGetAllAnnotationsForSessionQuery(
    { id: data?.id },
    { skip: embedded || isMobile || isDemo },
  )
  const [selectedAudio, setSelectedAudio] = useState<IAudios | null>(
    isDemo && audios.length > 0
      ? audios[0]
      : audios.filter((audio) => audio.isConversion).length > 0
      ? audios.filter((audio) => audio.isConversion)[0]
      : audios.length > 0
      ? audios[0]
      : null,
  )
  const [isTranscriptionToolActive, setIsTranscriptionToolActive] =
    useState<boolean>(false)
  const [selectedAnnotationTypeId, setSelectedAnnotationTypeId] = useState<
    string | null
  >(null)
  const handleSelectedAnnotationType = (annotationType: {
    id: string | null
    colorHEX: string | null
  }) => {
    selectedAnnotationType = annotationType
  }
  const [visibleAnnotationTypes, setVisibleAnnotationTypes] = useState<
    string[]
  >([])

  const [player, setPlayer] = useState<IPlayer>(emptyPlayer)
  const [popoverData, setPopoverData] = useState<IPopoverData>(
    emptyPopoverCommentData,
  )
  useEffect(() => {
    if (annotationTypesData && annotationTypesData.data) {
      refreshAnnotationTypes({
        setTypeId: (typeId: string | null) => {
          selectedTypeId = typeId
          setSelectedAnnotationTypeId(typeId)
        },
        selectedAnnotationType: selectedAnnotationType,
        setSelectedAnnotationType: handleSelectedAnnotationType,
        annotation_types: annotationTypesData.data,
      })
    }
  }, [annotationTypesData])

  const renderProgress = (progress: number, duration: number) => {
    if (boxRef.current) {
      const wavePosition =
        boxRef.current.parentElement.offsetWidth *
        boxRef.current.parentElement.zoom *
        progress
      setProgress({ progress, duration })
      playhead.x = wavePosition
      updateWave({ wavePosition, waves, application, annotationModeAdd })
    }
  }

  useEffect(() => {
    if (selectedAudio) {
      stop({
        name: selectedAudio.name,
        id: selectedAudio.id,
        player,
        setPlayer,
      })
    }
  }, [audios])
  const annotationRef = useRef({
    downPosition: null,
    upPosition: null,
    annotationModeAdd: false,
    selectedTypeId: null,
    isSaving: false,
    selectedAnnotationType: {
      id: null,
      colorHEX: null,
    },
  })
  const [updateAnnotation] = useUpdateAnnotationMutation()
  const [deleteAnnotation] = useDeleteAnnotationMutation()
  const [isRemovingAnnotation, setIsRemovingAnnotation] = useState(false)
  const updateAnnotationsElements = () => {
    if (!annotationsData) {
      return
    }
    updateAnnotationOnWave({
      annotationRef,
      setPopoverData,
      setIsResizing: (drooping) => {
        isResizing = drooping
      },
      isAnnotation: isAnnotationToolActive,
      annotations: annotationsData.data,
      audio: selectedAudio,
      application,
      setAnnotationsChildren,
      audios,
      data,
      annotation_types: annotationTypesData?.data,
      visibleAnnotationTypes,
      boxRef,
      height,
      selectedAudio,
      waves,
      tooltipText,
      tooltip,
      onCreateAnnotation: ({ upPosition }) => {},
      onRemoveAnnotation: ({ sessionId, annotationId }) => {
        if (isRemovingAnnotation) {
          return
        }
        setIsRemovingAnnotation(true)
        deleteAnnotation({ sessionId, annotationId })
          .unwrap()
          .then(() => {
            setIsRemovingAnnotation(false)
            refetchAnnotations()
            toast({
              title: `Annotation deleted`,
              position: 'bottom-left',
              status: 'success',
              isClosable: true,
            })
          })
          .catch(() => {
            setIsRemovingAnnotation(false)
            toast({
              title: 'Error while deleting annotation',
              position: 'bottom-left',
              status: 'error',
              isClosable: true,
            })
          })
      },
      updateAnnotation: async ({
        annotationId,
        start,
        end,
        additionalAction,
      }) => {
        if (!annotationsData || !data) {
          refetchAnnotations()
          return
        }
        const annotation = annotationsData.data.filter(
          ({ annotation_id }) => annotation_id === annotationId,
        )
        await updateAnnotation({
          sessionId: data.id,
          annotationId,
          start,
          end,
          description: annotation[0].description,
          selectedTypeId: annotation[0].annotation_type.annotation_type_id,
        })
          .unwrap()
          .then(() => {
            additionalAction()
            toast({
              title: `Annotation updated`,
              position: 'bottom-left',
              status: 'success',
              isClosable: true,
            })
            refetchAnnotations()
          })
          .catch(() => {
            additionalAction()
            toast({
              title: 'Error while updating annotation',
              position: 'bottom-left',
              status: 'error',
              isClosable: true,
            })
            refetchAnnotations()
          })
      },
      playAnnotation: ({ annotation, duration, audio }) => {
        if (!isResizing) {
          playAnnotation({
            audio,
            duration,
            annotation,
            data,
            player,
            setPlayer,
            sound,
            renderProgress,
          })
        }
      },
    })
  }

  useEffect(() => {
    if (!isDemo) {
      updateAnnotationsElements()
    }
  }, [
    annotationsData,
    isAnnotationToolActive,
    visibleAnnotationTypes,
    selectedAudio,
  ])

  useEffect(() => {
    if (boxRef.current) {
      boxRef.current.parentElement.zoom = 1
    }
  }, [boxRef.current])

  return (
    <Flex
      h="100%"
      w="100%"
      flexDirection="column"
      justifyContent="space-between"
    >
      {!isDemo && <SessionHeader data={data} />}
      <Tabs
        index={
          selectedAudio
            ? audios.findIndex((el) => el.id === selectedAudio.id) === -1
              ? audios.length - 1
              : audios.findIndex((el) => el.id === selectedAudio.id)
            : audios.length - 1
        }
        size="sm"
        height="100%"
        display="grid"
        width="100%"
        gridTemplateRows={
          isDemo
            ? '70px 1fr'
            : embedded || isMobile
            ? `50px ${
                is_annotator && isAnnotationToolActive ? 'auto' : ''
              } 120px 30px 1fr`
            : `50px ${
                is_annotator && isAnnotationToolActive ? 'auto' : ''
              } 140px 30px 1fr`
        }
      >
        <Flex justifyContent="space-between">
          <SessionVoicesControl
            renderProgress={renderProgress}
            setSelectedAudio={setSelectedAudio}
            application={application}
            embedded={embedded}
            data={data}
            audios={audios}
            setPlayer={setPlayer}
            player={player}
            progress={progress}
            voicesList={voicesList}
            setVoice={setVoice}
            selectedIndex={selectedIndex}
            flags={flags}
          />
          {!embedded && (
            <SessionControlButtons
              refetchSession={refetchSession}
              scrollBoxRef={scrollBoxRef}
              resize={() => {
                updateAnnotationsElements()
              }}
              boxRef={boxRef}
              isTranscriptionToolActive={isTranscriptionToolActive}
              onClickTranscriptionToolActive={() => {
                setIsTranscriptionToolActive(!isTranscriptionToolActive)
              }}
              progress={progress}
              selectedAudio={selectedAudio}
              annotationTypesLoading={annotationTypesLoading}
              audios={audios}
              annotations={annotationsData ? annotationsData.data : []}
              data={data}
              disabled={disabled || loader}
              onClickAnnotationToolActive={() => {
                updateRef(annotationRef, {
                  ...annotationRef.current,
                  isSaving: false,
                })
                setIsAnnotationToolActive(!isAnnotationToolActive)
                annotationModeAdd = !isAnnotationToolActive
              }}
              isAnnotationToolActive={isAnnotationToolActive}
            />
          )}
        </Flex>
        {!isDemo && is_annotator && isAnnotationToolActive && (
          <SessionAnnotationTypes
            data={data}
            refetchAnnotationTypes={refetchAnnotationTypes}
            refetchAnnotations={refetchAnnotations}
            setVisibleAnnotationTypes={setVisibleAnnotationTypes}
            visibleAnnotationTypes={visibleAnnotationTypes}
            selectedTypeId={selectedTypeId}
            onSelectAnnotationType={(
              annotation_type_id: string,
              colorHEX: string,
            ) => {
              selectedTypeId = annotation_type_id
              setSelectedAnnotationTypeId(annotation_type_id)
              handleSelectedAnnotationType({
                id: annotation_type_id,
                colorHEX: colorHEX,
              })
            }}
            annotationTypes={
              annotationTypesData ? annotationTypesData.data : []
            }
          />
        )}
        <SessionPlayer
          annotationRef={annotationRef}
          resizePlayer={() => {
            updateAnnotationsElements()
          }}
          scrollBoxRef={scrollBoxRef}
          selectedAnnotationType={selectedAnnotationType}
          isAnnotationToolActive={isAnnotationToolActive}
          selectedAnnotationTypeId={selectedAnnotationTypeId}
          isRemovingAnnotation={isRemovingAnnotation}
          refetchAnnotations={refetchAnnotations}
          popoverData={popoverData}
          setPopoverData={setPopoverData}
          annotationsData={annotationsData}
          annotationTypesData={annotationTypesData}
          waves={waves}
          setLoader={setLoader}
          loader={loader}
          identifier="player"
          selectedAudio={selectedAudio}
          audios={audios}
          data={data}
          boxRef={boxRef}
          height={height}
          playhead={playhead}
          isLoadingAudios={isLoadingAudios}
          embedded={embedded}
          player={player}
          progress={progress}
          setPlayer={setPlayer}
          renderProgress={renderProgress}
          setApplication={setApplication}
          setSelectedAudio={setSelectedAudio}
          application={application}
          setProgress={setProgress}
          tooltip={tooltip}
          tooltipText={tooltipText}
        />
        {!isDemo && (
          <SessionFooter
            application={application}
            setPopoverData={setPopoverData}
            refetchAnnotations={refetchAnnotations}
            selectedAnnotationTypeId={selectedAnnotationTypeId}
            setPlayer={setPlayer}
            renderProgress={renderProgress}
            annotationsChildren={annotationsChildren}
            playAnnotation={(annotation) => {
              if (!isResizing) {
                playAnnotation({
                  ...annotation,
                  data,
                  player,
                  setPlayer,
                  sound,
                  renderProgress,
                })
              }
            }}
            boxRef={boxRef}
            visibleAnnotationTypes={visibleAnnotationTypes}
            annotations={annotationsData ? annotationsData.data : []}
            annotationTypes={
              annotationTypesData ? annotationTypesData.data : []
            }
            isTranscriptionToolActive={isTranscriptionToolActive}
            isAnnotationToolActive={isAnnotationToolActive}
            embedded={embedded}
            data={data}
            progress={progress}
            selectedAudio={selectedAudio}
            player={player}
          />
        )}
      </Tabs>
    </Flex>
  )
}
