import React, { useState } from 'react'
import { Button, Grid, Input, Tag, useToast } from '@chakra-ui/react'
import { rgbToRgba } from '../../../utils/colors'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { AlertElement } from '../../../storybook/components/Alert/index'
import { AddIcon, CloseIcon } from '@chakra-ui/icons'
import { IAnnotationTypes } from '../../../models/annotations/annotations'
import { showHideAnnotations } from '../annotations.utils'
import {
  useCreateMutation,
  useDeleteMutation,
} from '../../../newFeatures/annotations/service'
import { ISessionDetails } from '../../../models/session/session'
export const SessionAnnotationTypes = ({
  annotationTypes,
  onSelectAnnotationType,
  selectedTypeId,
  visibleAnnotationTypes,
  setVisibleAnnotationTypes,
  data,
  refetchAnnotations,
  refetchAnnotationTypes,
}: {
  annotationTypes: IAnnotationTypes[]
  onSelectAnnotationType: (annotation_type_id: string, colorHEX: string) => void
  selectedTypeId: string | null
  visibleAnnotationTypes: string[]
  setVisibleAnnotationTypes: (data: string[]) => void
  data?: ISessionDetails
  refetchAnnotationTypes: any
  refetchAnnotations: () => void
}) => {
  const toast = useToast()
  const [isRemovingType, setIsRemovingType] = useState(false)
  const [isSavingNewType, setIsSavingNewType] = useState(false)
  const [isStartSaving, setIsStartSaving] = useState(false)
  const [isNewAnnotationType, setIsNewAnnotationType] = useState<boolean>(false)
  const [annotationTypeInput, setAnnotationTypeInput] = useState<string>('')
  const [createAnnotationType] = useCreateMutation()
  const [deleteAnnotationType] = useDeleteMutation()

  const createAnnotationTypeElement = () => {
    setIsStartSaving(true)
    if (annotationTypeInput.length === 0 || !data || !data.id) {
      return
    }
    setIsSavingNewType(true)
    createAnnotationType({
      sessionId: data.id,
      typeName: annotationTypeInput,
    })
      .unwrap()
      .then(() => {
        toast({
          title: `Annotation type saved`,
          position: 'bottom-left',
          status: 'success',
          isClosable: true,
        })
        setIsSavingNewType(false)
        setIsStartSaving(false)
        setAnnotationTypeInput('')
        setIsNewAnnotationType(false)
        refetchAnnotationTypes()
      })
      .catch((response) => {
        toast({
          title: response.details[Object.keys(response.details)[0]],
          position: 'bottom-left',
          status: 'error',
          isClosable: true,
        })
        setIsSavingNewType(false)
        setIsStartSaving(false)
      })
  }

  return (
    <Grid
      ml="24px"
      display="flex"
      gap="2"
      mb="10px"
      maxW="100%"
      flexWrap="wrap"
      border="1px solid transparent"
    >
      {annotationTypes.map(
        (
          {
            annotation_type_id,
            annotation_type_name,
            is_default,
            color,
            colorHEX,
          },
          index,
        ) => {
          return (
            <Tag
              gap="2"
              cursor="pointer"
              key={`${annotation_type_name}_${annotation_type_id}_${index}`}
              opacity={annotation_type_id === selectedTypeId ? 1 : 0.3}
              color={
                selectedTypeId
                  ? annotation_type_id === selectedTypeId
                    ? 'white'
                    : 'black'
                  : 'white'
              }
              background={
                selectedTypeId
                  ? annotation_type_id === selectedTypeId
                    ? color
                    : rgbToRgba(color, 0.2)
                  : color
              }
              onClick={() => {
                onSelectAnnotationType(annotation_type_id, colorHEX)
              }}
            >
              {annotation_type_name}
              {visibleAnnotationTypes.length > 0 ? (
                visibleAnnotationTypes?.filter(
                  (el) => el === annotation_type_id,
                ).length > 0 ? (
                  <FiEye
                    onClick={(event) => {
                      showHideAnnotations({
                        event,
                        annotation_type_id,
                        setVisibleAnnotationTypes,
                        visibleAnnotationTypes,
                      })
                    }}
                  />
                ) : (
                  <FiEyeOff
                    onClick={(event) => {
                      showHideAnnotations({
                        event,
                        annotation_type_id,
                        setVisibleAnnotationTypes,
                        visibleAnnotationTypes,
                      })
                    }}
                  />
                )
              ) : (
                <FiEye
                  onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    setVisibleAnnotationTypes([
                      ...visibleAnnotationTypes,
                      annotation_type_id,
                    ])
                  }}
                />
              )}

              <AlertElement
                isDisabled={isRemovingType}
                buttonElement={<CloseIcon fontSize="10px" />}
                headerText="Are you sure to delete annotation type?"
                subHeaderText="This action will delete all annotation with this type."
                cancelText="Cancel"
                yesText="Delete"
                onClickYes={() => {
                  if (data?.id) {
                    setIsRemovingType(true)
                    deleteAnnotationType({
                      sessionId: data.id,
                      annotationTypeId: annotation_type_id,
                    })
                      .unwrap()
                      .then(() => {
                        refetchAnnotations()
                        refetchAnnotationTypes()
                        setIsRemovingType(false)
                        toast({
                          title: `Annotation type deleted`,
                          position: 'bottom-left',
                          status: 'success',
                          isClosable: true,
                        })
                      })
                      .catch(() => {
                        setIsRemovingType(false)
                        toast({
                          title: 'Error while deleting annotation type',
                          position: 'bottom-left',
                          status: 'error',
                          isClosable: true,
                        })
                      })
                  }
                }}
              />
            </Tag>
          )
        },
      )}

      <Tag
        border={`1px solid ${
          isStartSaving && annotationTypeInput.length === 0
            ? 'red'
            : 'transparent'
        }`}
        gap="2"
      >
        {isNewAnnotationType ? (
          <>
            <Input
              maxW="100px"
              _focus={{
                boxShadow: 'none',
              }}
              onChange={(e) => {
                setAnnotationTypeInput(e.target.value)
              }}
              value={annotationTypeInput}
              size="xs"
              variant="unstyled"
              placeholder="Annotation type"
              autoFocus={true}
              onKeyDown={(event) => {
                if (event.nativeEvent.code === 'Enter') {
                  event.stopPropagation()
                  event.preventDefault()
                  createAnnotationTypeElement()
                }
              }}
            />
            <CloseIcon
              cursor="pointer"
              onClick={() => {
                setAnnotationTypeInput('')
                setIsNewAnnotationType(false)
              }}
              fontSize="10px"
            />
            <Button
              onClick={() => {
                createAnnotationTypeElement()
              }}
              maxH="15px"
              size="xs"
              colorScheme="brand"
              isDisabled={isSavingNewType}
              isLoading={isSavingNewType}
            >
              Save
            </Button>
          </>
        ) : (
          <AddIcon
            fontSize="12px"
            color="gray.500"
            _hover={{ color: 'black' }}
            onClick={() => setIsNewAnnotationType(true)}
          />
        )}
      </Tag>
    </Grid>
  )
}
