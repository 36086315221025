import * as React from 'react'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  Text,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  Button,
  ButtonGroup,
} from '@chakra-ui/react'
import { useEffect } from 'react'

export const PreventChangePath = ({
  isOpen,
  onConfirm,
  onCancel,
  contentText,
  refreshCondition,
}: {
  isOpen: any
  onCancel: any
  onConfirm: any
  contentText?: string
  refreshCondition?: boolean
}) => {
  // prevent to refresh page when editing
  useEffect(() => {
    const unloadCallback = (event: any) => {
      if (refreshCondition) {
        event.preventDefault()
        event.returnValue =
          'Leaving this page will stop the current action, do you want to continue?'
        return 'Leaving this page will stop the current action, do you want to continue?'
      }
    }
    window.addEventListener('beforeunload', unloadCallback)
    return () => {
      window.removeEventListener('beforeunload', unloadCallback)
    }
  }, [refreshCondition])

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent alignSelf="center">
        <ModalHeader
          alignItems="center"
          justifyContent="center"
          display="flex"
          flexDirection="column"
          textAlign="center"
        >
          Warning
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign="center">
          <Text>
            {contentText
              ? contentText
              : 'Leaving this page will stop the current action, do you want to continue?'}
          </Text>
        </ModalBody>

        <ModalFooter display="flex" justifyContent="space-around">
          <ButtonGroup>
            <Button variant="ghost" onClick={onCancel}>
              Discard
            </Button>
            <Button colorScheme="blue" mr={3} onClick={onConfirm}>
              Confirm
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
