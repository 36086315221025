import { Box, Button, Divider, Flex, Text } from '@chakra-ui/react'
import * as React from 'react'
import { useAppSelector, useAppDispatch } from '../../utils/reduxUtils'
import { Select } from 'chakra-react-select'
import { useEffect, useRef, useState } from 'react'
import {
  setAvailableDevices,
  setSelectedDevices,
  updateDeviceTest,
} from '../../features/audio/actions'
import { GoGear } from 'react-icons/go'

import {
  IAvailableDevicesState,
  ISelectedDevicesState,
} from '../../models/audio/audio'

import {
  broadcastSystemEvent,
  ESystemMessage,
} from '../../pages/Live/liveSession'

export interface IAudioDevice {
  api_id: string
  device_id: string | null
  device_name: string
  full_device_name: string
  is_input_device: boolean
  is_output_device: boolean
  is_default_device: boolean
}

export const userHasVirtualMicrophone = new RegExp(
  '^(VB-Cable|Cable|Meaning Microphone|.*Virtual.*)',
)

export const selectVirtualMicrophonePriorityReg = new RegExp(
  '^(VB-Cable|Cable)',
)

export const selectVirtualMicrophoneReg = new RegExp('^(.*Virtual.*)')

export const DevicesController = ({
  isOpenDeviceController,
  isModalView,
}: {
  isOpenDeviceController: boolean
  isModalView?: boolean
}) => {
  const { areAudioPermissions, selectedDevices, mediaState } = useAppSelector(
    (state) => state.audio,
  )
  const { meaning_user_id, user } = useAppSelector((state) => state.user)

  const isTestUser =
    meaning_user_id === '0f1e06a3-8729-4105-b5fc-c694fdaf9241' ||
    meaning_user_id === '1f73aa4b-3bde-452c-a9a0-4249e82a9ca8' ||
    user.includes('QA_Automation_')

  const [devices, setDevices] = useState<IAudioDevice[]>([])
  const [preselectedInputDevice, setPreselectedInputDevice] =
    useState<IAudioDevice>()
  const [preselectedOutputDevice, setPreselectedOutputDevice] =
    useState<IAudioDevice>()
  const [areDevicesSelected, setDevicesSelected] = useState(false)

  const dispatch = useAppDispatch()
  const apiModel = (usedDevices: any[]) =>
    usedDevices.map(({ device_name, is_default_device }) => ({
      device_name,
      is_default_device,
    }))

  const updateDevices = () => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const mappedDevices = devices
          .filter(
            (device) =>
              device.kind === 'audioinput' ||
              (device.kind === 'audiooutput' && device.deviceId !== 'default'),
          )
          .map((device) => {
            return {
              api_id: device.groupId,
              device_id: device.deviceId,
              device_name:
                device.deviceId === 'default' ? `Same as system` : device.label,
              full_device_name: device.label,
              is_input_device: device.kind === 'audioinput',
              is_output_device: device.kind === 'audiooutput',
              is_default_device: device.deviceId === 'default',
            }
          })
        if (mappedDevices.find((device) => device?.device_id)) {
          setDevices([
            {
              api_id: 'null',
              device_id: null,
              device_name: 'None',
              full_device_name: 'None',
              is_input_device: false,
              is_output_device: true,
              is_default_device: false,
            },
            ...mappedDevices,
          ])

          const usedDevices: IAvailableDevicesState = {
            input_devices: apiModel(
              mappedDevices.filter((device) => device.is_input_device),
            ),
            output_devices: apiModel(
              mappedDevices.filter((device) => device.is_output_device),
            ),
          }

          dispatch(setAvailableDevices(usedDevices))
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    if (selectedDevices.input_device && selectedDevices.output_device) {
      setDevicesSelected(true)
    } else {
      setDevicesSelected(false)
    }
  }, [selectedDevices])

  useEffect(() => {
    if (areAudioPermissions) {
      updateDevices()
      navigator.mediaDevices.ondevicechange = (e) => {
        updateDevices()
      }
    }
  }, [areAudioPermissions])

  useEffect(() => {
    if (devices.length) {
      broadcastSystemEvent(ESystemMessage.AVAILABLE_INPUT_DEVICES, [
        ...devices.filter((device) => device.is_input_device),
      ])
      broadcastSystemEvent(ESystemMessage.AVAILABLE_OUTPUT_DEVICES, [
        ...devices.filter((device) => device.is_output_device),
      ])

      const preselectedInputDeviceData = localStorage.getItem('input_device')
      const preselectedOutputDeviceData = localStorage.getItem('output_device')
      let currentPreselectedInputDevice = null
      let currentPreselectedOutputDevice = devices[0]

      if (preselectedInputDeviceData) {
        currentPreselectedInputDevice = JSON.parse(preselectedInputDeviceData)
      }

      if (preselectedOutputDeviceData) {
        currentPreselectedOutputDevice = JSON.parse(preselectedOutputDeviceData)
      }

      if (!preselectedInputDeviceData) {
        const inputDevice = devices.filter(
          ({ is_input_device, is_default_device }) =>
            is_input_device && is_default_device,
        )
        if (inputDevice.length > 0) {
          currentPreselectedInputDevice = inputDevice[0]
        }
      }

      if (
        !currentPreselectedOutputDevice ||
        currentPreselectedOutputDevice.device_name === 'None'
      ) {
        const outputDevices = devices
          .filter(
            ({ is_output_device, device_name }) =>
              is_output_device &&
              device_name &&
              userHasVirtualMicrophone.test(device_name),
          )
          .sort((a, b) => {
            const priorityA = selectVirtualMicrophonePriorityReg.test(
              a.device_name,
            )
            const priorityB = selectVirtualMicrophonePriorityReg.test(
              b.device_name,
            )

            return priorityA === priorityB ? 0 : priorityA ? -1 : 1
          })

        const defaultOutputDevices = devices.filter(
          ({ is_output_device, is_default_device }) =>
            is_output_device && is_default_device,
        )

        if (outputDevices.length === 0 && defaultOutputDevices.length > 0) {
          currentPreselectedOutputDevice = defaultOutputDevices[0]
        } else if (outputDevices.length > 0) {
          currentPreselectedOutputDevice = outputDevices[0]
        }
      }

      dispatch(
        setSelectedDevices({
          input_device:
            currentPreselectedInputDevice ||
            devices.find(
              (device) =>
                device.device_id === selectedDevices.input_device?.device_id ||
                (device.is_input_device && device.is_default_device),
            ),
          output_device:
            currentPreselectedOutputDevice ||
            devices.find(
              (device) =>
                device.device_id === selectedDevices.output_device?.device_id,
            ),
        }),
      )

      setPreselectedInputDevice(currentPreselectedInputDevice)
      setPreselectedOutputDevice(currentPreselectedOutputDevice)
    }
  }, [devices])

  let inputDevices = [...devices.filter((device) => device.is_input_device)]

  if (preselectedInputDevice) {
    inputDevices = [
      preselectedInputDevice,
      ...inputDevices.filter(
        (device) => device.device_id != preselectedInputDevice.device_id,
      ),
    ]
  }

  let outputDevices = [...devices.filter((device) => device.is_output_device)]

  if (preselectedOutputDevice) {
    outputDevices = [
      preselectedOutputDevice,
      ...outputDevices.filter(
        (device) => device.device_id != preselectedOutputDevice.device_id,
      ),
    ]
  }

  const data = useAppSelector((state) => state.user)

  useEffect(() => {
    const messageHandler = (event: MessageEvent) => {
      if (mediaState.isConnected || mediaState.isConnecting) {
        window.parent.postMessage(
          {
            type: 'error',
            details: `Can't change device configuration`,
          },
          '*',
        )
        return
      }
      if (event.data.type === 'update_device') {
        let newSelectedDevices: Partial<ISelectedDevicesState> = {}

        const input_device = inputDevices.filter(
          ({ device_id }) => device_id === event.data.input_device,
        )

        const output_device = outputDevices.filter(
          ({ device_id }) => device_id === event.data.output_device,
        )

        if (event.data?.input_device && input_device.length > 0) {
          try {
            localStorage.setItem(
              'input_device',
              JSON.stringify(input_device[0]),
            )
            newSelectedDevices.input_device = input_device[0]
          } catch (error) {
            console.warn(`Error setting input device in localStorage ${error}`)
          }
        }

        if (event.data?.output_device && output_device.length > 0) {
          try {
            localStorage.setItem(
              'output_device',
              JSON.stringify(output_device[0]),
            )
            newSelectedDevices.output_device = output_device[0]
          } catch (error) {
            console.warn(`Error setting output device in localStorage ${error}`)
          }
        }

        dispatch(
          setSelectedDevices({
            ...selectedDevices,
            ...newSelectedDevices,
          }),
        )
      }
    }

    window.addEventListener('message', messageHandler, false)

    return () => {
      window.removeEventListener('message', messageHandler)
    }
  })

  useEffect(() => {
    if (
      areAudioPermissions &&
      selectedDevices.input_device !== null &&
      selectedDevices.output_device !== null
    ) {
      window.parent.postMessage(
        {
          type: 'selected_devices',
          device_configuration: selectedDevices,
          available_devices: {
            input_devices: inputDevices,
            output_devices: outputDevices,
          },
        },
        '*',
      )
    }
  }, [selectedDevices, areAudioPermissions])

  const is_controlled = !!data.features.find(
    (feature) =>
      feature === 'is_controlled_by_live_session_api' ||
      feature === 'is_controlled_by_external_site',
  )

  const [isOpen, setIsOpen] = useState(isOpenDeviceController)
  useEffect(() => {
    if (isOpenDeviceController) {
      setIsOpen(isOpenDeviceController)
    } else {
      const timer = setTimeout(() => {
        setIsOpen(isOpenDeviceController)
        clearTimeout(timer)
      }, 150)
    }
  }, [isOpenDeviceController])

  return isOpen ? (
    <>
      {[
        { type: 'Input', val: inputDevices },
        { type: 'Output', val: outputDevices },
      ].map((el, index) => {
        let value: any = {}

        if (el.type.toLowerCase() === 'input' && selectedDevices.input_device) {
          const input =
            el.val &&
            el.val
              .filter(
                ({ device_name }) =>
                  selectedDevices.input_device?.device_name === device_name,
              )
              .map((item) => ({
                value: item,
                label: item.device_name,
              }))
          value = input && input.length > 0 ? input[0] : undefined
        }

        if (
          el.type.toLowerCase() === 'output' &&
          selectedDevices.output_device
        ) {
          const output =
            el.val &&
            el.val
              .filter(
                ({ device_name }) =>
                  selectedDevices.output_device?.device_name === device_name,
              )
              .map((item) => ({
                value: item,
                label: item.device_name,
              }))
          value = output && output.length > 0 ? output[0] : undefined
        }

        return (
          <Box key={`device_select_${index}`} w="100%">
            {index !== 0 && !isModalView && <Divider size="sm" my="3" />}
            <Text mb="2" fontSize="sm" fontWeight="semibold">
              {el.type}
            </Text>

            <Select
              isLoading={!areAudioPermissions}
              value={value}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
              }}
              placeholder={`Select ${el.type}`}
              menuPortalTarget={document.body}
              closeMenuOnSelect={true}
              isClearable={false}
              maxMenuHeight={160}
              isMulti={false}
              onChange={(value: any) => {
                let newSelectedDevices: Partial<ISelectedDevicesState> = {}
                if (el.type.toLowerCase() === 'input') {
                  localStorage.setItem(
                    'input_device',
                    JSON.stringify(value.value),
                  )
                  newSelectedDevices.input_device = value.value
                }
                if (el.type.toLowerCase() === 'output') {
                  localStorage.setItem(
                    'output_device',
                    JSON.stringify(value.value),
                  )
                  newSelectedDevices.output_device = value.value
                }
                dispatch(
                  setSelectedDevices({
                    ...selectedDevices,
                    ...newSelectedDevices,
                  }),
                )
              }}
              options={el.val
                .filter((item) => {
                  return isTestUser || el.type === 'Input'
                    ? true
                    : selectVirtualMicrophoneReg.test(item.device_name) ||
                        selectVirtualMicrophonePriorityReg.test(
                          item.device_name,
                        ) ||
                        !item.device_id
                })
                .map((item) => ({
                  value: item,
                  label: item.device_name,
                }))}
              // using id as custom attributes(data-qa__id) are not registered
              data-qa__id={`${el.type.toLowerCase()}_device_select`}
              id={`${el.type.toLowerCase()}_device_select`}
            />
          </Box>
        )
      })}
      {!isModalView && !is_controlled && (
        <>
          <Divider size="sm" my="3" />
          <Flex>
            <Button
              isDisabled={mediaState.isConnected}
              justifySelf="center"
              colorScheme="brand"
              variant="outline"
              size="sm"
              onClick={() => {
                dispatch(updateDeviceTest({ isOpen: true }))
              }}
              color="brand.500"
              leftIcon={<GoGear />}
              aria-label="test_setup"
            >
              Test setup
            </Button>
          </Flex>
        </>
      )}
    </>
  ) : (
    <></>
  )
}
