const AUDIO_ENCODER_CONFIG = {
  codec: 'opus',
  sampleRate: 16_000,
  numberOfChannels: 1,
  bitrate: 16_000,
  opus: {
    application: 'voip',
    useinbandfec: false,
    usedtx: true,
  },
}

class OpusEncoder {
  private encoder: any
  private writer: any
  private lastErrorHandler: any

  public constructor(writer: any, lastErrorHandler: any) {
    this.writer = writer
    this.lastErrorHandler = lastErrorHandler

    this.encoder = new (window as any).AudioEncoder({
      output: (encoded: any, _: any) => {
        const buffer = new ArrayBuffer(encoded.byteLength)
        encoded.copyTo(buffer)

        const padding = new Uint8Array([1, 2, 3, 255, 0, 255, 3, 2, 1])
        const data = new Uint8Array(buffer)

        const extendedData = new Uint8Array(data.length + padding.length)

        extendedData.set(data, 0)
        extendedData.set(padding, data.length)

        this.writer.write(extendedData).catch(() => {
          if (this.lastErrorHandler) {
            this.lastErrorHandler()
          }
        })
      },
      error: (e: any) => {
        console.log(e)
      },
    })
    this.encoder.configure(AUDIO_ENCODER_CONFIG)
  }

  public encode(audio: Float32Array, sample_rate: number): void {
    const audioData = new (window as any).AudioData({
      format: 'f32',
      data: audio,
      sampleRate: sample_rate,
      numberOfChannels: 1,
      numberOfFrames: audio.length,
      timestamp: new Date().getTime(),
    })
    this.encoder.encode(audioData)
  }
}

class OpusEncoderCollection {
  private static instance: OpusEncoderCollection
  private _encoders: Map<string, OpusEncoder>

  private constructor() {
    this._encoders = new Map()
  }

  public static getInstance() {
    if (!OpusEncoderCollection.instance) {
      OpusEncoderCollection.instance = new OpusEncoderCollection()
    }
    return OpusEncoderCollection.instance
  }

  public getEncoder(
    session_id: string,
    voice_id: string,
    model_id: string,
    writer: any,
    lastErrorHandler: any,
  ): OpusEncoder {
    const key = `${session_id}|${voice_id}|${model_id}`

    if (!this._encoders.has(key)) {
      const encoder = new OpusEncoder(writer, lastErrorHandler)
      this._encoders.set(key, encoder)
    }

    return this._encoders.get(key)!
  }
}

export { OpusEncoder, OpusEncoderCollection }
