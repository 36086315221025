import { AppEpic } from '../../../utils/reduxUtils'
import {
  getSession,
  isRemovingConversions,
  removeOneConversionById,
  saveOneConversion,
  setIsUpdatingConversions,
  setSavingOneConversion,
  setSession,
} from '../actions'
import {
  catchError,
  filter,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators'
import { concat, EMPTY, of } from 'rxjs'
import { checkIsStatus401 } from '../../authorization/actions'

export const SaveOneConversion: AppEpic<
  ReturnType<typeof saveOneConversion>
> = (action$, state$, { session }) =>
  action$.pipe(
    filter(saveOneConversion.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      return concat(
        of(setSavingOneConversion(true)),
        action.payload.conversionId
          ? of(
              setIsUpdatingConversions([
                ...state.session.isUpdatingConversions,
                { id: action.payload.conversionId, isLoading: true },
              ]),
            )
          : EMPTY,
        session
          .saveConversion({
            sessionId: action.payload.sessionId,
            body: action.payload.body,
            isNew: !action.payload.conversionId,
          })
          .pipe(
            switchMap(({ response }) => {
              if (action.payload.additionalFunction) {
                action.payload.additionalFunction({ prev: [], conversion: [] })
              }
              if (action.payload.toast) {
                action.payload.toast({
                  title: 'Successfully created conversion',
                  position: 'bottom-left',
                  status: 'success',
                  isClosable: true,
                })
              }
              if (action.payload.silent && action.payload.conversionId) {
                return concat(
                  of(
                    setSession({
                      session: {
                        ...state.session.session,
                        conversions: state.session.session.conversions?.map(
                          (el) => {
                            return {
                              ...el,
                              name:
                                el.id === action.payload.conversionId
                                  ? action.payload.body.data.name
                                  : el.name,
                              description:
                                el.id === action.payload.conversionId
                                  ? action.payload.body.data.description
                                  : el.description,
                            }
                          },
                        ),
                      },
                    }),
                  ),
                  of(setSavingOneConversion(false)),
                )
              }

              return concat(
                of(getSession({ id: action.payload.sessionId, silent: false })),
                of(setSavingOneConversion(false)),
              )
            }),
            catchError((err) => {
              if (action.payload.additionalFunction) {
                action.payload.additionalFunction()
              }
              return concat(
                of(setSavingOneConversion(false)),
                of(checkIsStatus401(err.status)),
              )
            }),
          ),
      )
    }),
  )

export const RemoveOneConversionById: AppEpic<
  ReturnType<typeof removeOneConversionById>
> = (action$, state$, { session }) =>
  action$.pipe(
    filter(removeOneConversionById.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      return concat(
        of(
          isRemovingConversions([
            ...state.session.isRemovingConversions,
            { id: action.payload.conversion_id, isLoading: true },
          ]),
        ),
        session
          .removeConversion({
            conversion_id: action.payload.conversion_id,
            session_id: action.payload.session_id,
          })
          .pipe(
            mergeMap(({ response }) => {
              if (action.payload.toast) {
                action.payload.toast({
                  title: 'Successfully removed conversion',
                  position: 'bottom-left',
                  status: 'success',
                  isClosable: true,
                })
              }
              const session: any = {
                ...state.session.session,
                conversions: state.session.session.conversions
                  ? state.session.session.conversions.filter(
                      ({ id }) => id !== action.payload.conversion_id,
                    )
                  : [],
              }
              return concat(
                of(
                  setSession({
                    session,
                  }),
                ),
                of(
                  isRemovingConversions(
                    state.session.isRemovingConversions.filter(
                      ({ id }) => id !== action.payload.conversion_id,
                    ),
                  ),
                ),
              )
            }),
            catchError((err) => {
              if (action.payload.toast) {
                action.payload.toast({
                  title: 'Error while deleting conversion',
                  position: 'bottom-left',
                  status: 'error',
                  isClosable: true,
                })
              }

              return concat(
                of(
                  isRemovingConversions(
                    state.session.isRemovingConversions.filter(
                      ({ id }) => id !== action.payload.conversion_id,
                    ),
                  ),
                ),
                of(checkIsStatus401(err.status)),
              )
            }),
          ),
      )
    }),
  )
