/* eslint-disable guard-for-in */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
import { createSlice } from '@reduxjs/toolkit'
import { IAuthorization } from '../../models/authorization/authorization'
import {
  isLoading,
  setError,
  setIsForceAuth,
  setIsLogged,
} from './actions'

export const initialAuth: IAuthorization = {
  isForceAuth: false,
  loading: true,
  isLogged: false,
  error: {},
}

export const authSlice = createSlice({
  name: 'authorization',
  initialState: initialAuth,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(setIsLogged.match, (state, { payload }) => {
      state.loading = false
      state.isLogged = payload.isLogged
    })
    builder.addMatcher(isLoading.match, (state, { payload }) => {
      state.loading = payload
    })
    builder.addMatcher(setIsForceAuth.match, (state, { payload }) => {
      state.isForceAuth = payload
    })
    builder.addMatcher(setError.match, (state, { payload }) => {
      state.error = payload.error
    })
  },
})

export const authReducer = authSlice.reducer
