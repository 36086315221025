import 'regenerator-runtime/runtime'
import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import store from './appConfig/store'
import { ChakraProvider } from '@chakra-ui/react'
import Wrapper from './AppWrapper'
import { myTheme } from './theme'

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ChakraProvider resetCSS theme={myTheme}>
        <Wrapper />
      </ChakraProvider>
    </Provider>
  )
}

export default App
