import React, { useEffect, useState } from 'react'
import Carousel, {
  arrowsPlugin,
  slidesToShowPlugin,
} from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'
import { Flex, IconButton, useMediaQuery } from '@chakra-ui/react'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { mobileBreakPoint } from '../../utils/mobileBreakPoint'

export const CarouselComponent = ({
  children,
  size,
  maxSliders,
  componentName,
}: {
  children: any
  size: { max: string; w: string }
  maxSliders: number
  componentName?: string
}) => {
  const [isMobile] = useMediaQuery(mobileBreakPoint)
  const [loaded, setLoaded] = useState(false)
  const maxSlidersForComponent = isMobile
    ? componentName === 'deviceTest'
      ? 1
      : 2
    : maxSliders
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true)
      clearTimeout(timer)
    }, 170)
  }, [])

  const [carouselValue, setCarouselValue] = useState<number>(0)
  const onChange = (value: number) => {
    setCarouselValue(value)
  }

  const arrows = (elements: number) => ({
    resolve: arrowsPlugin,
    options: {
      arrowLeft: (
        <IconButton
          bg="brand.500"
          _hover={{ background: 'brand.100' }}
          color="white"
          aria-label="left-icon"
          icon={<BsChevronLeft />}
          data-qa__id="avatar_carousel_left"
        />
      ),
      arrowLeftDisabled: (
        <IconButton
          cursor="not-allowed"
          pointerEvents="auto"
          aria-label="left-icon"
          icon={<BsChevronLeft />}
          data-qa__id="avatar_carousel_left_disabled"
        />
      ),
      arrowRight:
        children.length - elements <= carouselValue ? (
          <IconButton
            onClick={(event) => {
              event.stopPropagation()
            }}
            cursor="not-allowed"
            aria-label="right-icon"
            icon={<BsChevronRight />}
            data-qa__id="avatar_carousel_right_disabled"
          />
        ) : (
          <IconButton
            disabled={children.length - elements <= carouselValue}
            bg="brand.500"
            _hover={{ background: 'brand.100' }}
            color="white"
            aria-label="right-icon"
            icon={<BsChevronRight />}
            data-qa__id="avatar_carousel_right"
          />
        ),
      arrowRightDisabled: (
        <IconButton
          cursor="not-allowed"
          aria-label="right-icon"
          icon={<BsChevronRight />}
        />
      ),
      addArrowClickHandler: true,
    },
  })

  return (
    <Flex
      sx={{
        '.BrainhubCarousel__custom-arrowLeft': {
          visibility: loaded ? '' : 'hidden',
        },
      }}
      w={size.w}
      maxWidth={size.max}
      justifyContent="center"
      data-qa__id="avatar_carousel"
    >
      {children.length > maxSlidersForComponent ? (
        <Carousel
          value={carouselValue}
          onChange={onChange}
          breakpoints={{
            [componentName === 'deviceTest' ? 1000 : 640]: {
              plugins: [
                'clickToChange',
                arrows(isMobile ? (componentName === 'deviceTest' ? 1 : 2) : 2),
                {
                  resolve: slidesToShowPlugin,
                  options: {
                    numberOfSlides: isMobile
                      ? componentName === 'deviceTest'
                        ? 1
                        : 2
                      : 2,
                  },
                },
              ],
            },
            [componentName === 'deviceTest' ? 1100 : 900]: {
              plugins: [
                'clickToChange',
                arrows(3),
                {
                  resolve: slidesToShowPlugin,
                  options: {
                    numberOfSlides: 3,
                  },
                },
              ],
            },
          }}
          draggable={false}
          plugins={[
            'clickToChange',
            arrows(maxSlidersForComponent),
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: maxSlidersForComponent,
              },
            },
          ]}
        >
          {...children}
        </Carousel>
      ) : (
        <Flex gap="10" justifyContent="center" data-qa__id="avatar_flex">
          {...children}
        </Flex>
      )}
    </Flex>
  )
}
