import { Box, Flex } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../utils/reduxUtils'
import { useLocation, useParams } from 'react-router-dom'
import { iframeLogin } from '../../features/authorization/actions'
import Live from '../Live/live'
import { loader } from '../../PrivateRoute'
import { DevicesController } from '../../components/DevicesController/devicesController'

const LiveIframe = () => {
  const search = useLocation().search

  const { token } = useParams()
  const { loading, isLogged, error } = useAppSelector(
    (state) => state.authorization,
  )
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (token) {
      const can_be_recorded = new URLSearchParams(search).get('can_be_recorded')
      const can_control_recording = new URLSearchParams(search).get(
        'can_control_recording',
      )
      const should_be_recorded = new URLSearchParams(search).get(
        'should_be_recorded',
      )

      dispatch(
        iframeLogin({
          token,
          features: {
            can_be_recorded,
            can_control_recording,
            should_be_recorded,
          },
        }),
      )
      return () => {
        localStorage.removeItem('next')
      }
    }
  }, [])

  return (
    <>
      {isLogged && !loading && (
        <Box w="100%" h="100%" p="2">
          <Flex gap="2" flexDirection="column">
            <DevicesController
              isOpenDeviceController={true}
              isModalView={true}
            />
          </Flex>
          <Live isIframe={true} />
        </Box>
      )}
      {error && error.token && (
        <Flex alignItems="center" color="red" justifyContent="center" h="100%">
          {error.token}
        </Flex>
      )}
      {loading && (
        <Flex alignItems="center" justifyContent="center" w="100%" h="100%">
          {loader}
        </Flex>
      )}
    </>
  )
}

export default LiveIframe
