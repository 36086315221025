import { Text } from '@chakra-ui/react'
import React from 'react'

export const WordElement = ({
  isTooltip,
  text,
  timestamp,
  word,
  start,
  time,
  isCurrent,
  end,
  annotationElement,
  playAnnotation,
  onHighlight,
  onAnnotationPointerEnter,
  onAnnotationPointerLeave,
}: {
  isTooltip: boolean
  annotationElement: any
  isCurrent: boolean
  end: number
  time: number
  text: string
  timestamp: number[]
  word: string
  start: number
  playAnnotation: (annotationId: string) => void
  onAnnotationPointerEnter?: (annotationId: string) => void
  onAnnotationPointerLeave?: () => void
  onHighlight: ({
    word,
    start,
    end,
    up,
    position,
  }: {
    word: string
    start: number
    up: boolean
    end: number
    position: any
  }) => void
}) => {
  return (
    <Text
      onPointerLeave={() => {
        onAnnotationPointerLeave?.()
      }}
      onPointerEnter={() => {
        if (isTooltip) {
          onAnnotationPointerEnter?.(annotationElement[0].id)
        }
      }}
      onPointerUp={(event) => {
        onHighlight({ word, position: event.clientX, start, end, up: true })
      }}
      onPointerDown={(event) => {
        onHighlight({ word, position: event.clientX, start, end, up: false })
      }}
      cursor={isTooltip ? 'pointer' : 'auto'}
      borderBottom={
        annotationElement && annotationElement.length > 0
          ? `2px solid ${annotationElement[0]?.color}`
          : 'none'
      }
      onClick={() => {
        if (annotationElement.length > 0 && annotationElement[0].id) {
          playAnnotation(annotationElement[0].id)
        }
      }}
      color={time >= start && time <= end && isCurrent ? 'brand.500' : 'black'}
      fontWeight={isCurrent ? 'normal' : 'normal'}
    >
      {word}
    </Text>
  )
}
