import Lottie, { useLottie } from 'lottie-react'
import React from 'react'
import { logoAnimation } from './animation'

export interface IAnimatedLogo {
  maxAnimationHeight: string
  isLoop?: boolean
  speed?: number
}

const AnimatedLogo = ({ maxAnimationHeight, isLoop, speed }: IAnimatedLogo) => {
  const style = {
    height: maxAnimationHeight,
  }
  const options = {
    animationData: logoAnimation(speed ? speed : 1.5),
    loop: isLoop ? isLoop : false,
    autoplay: true,
  }

  const { View } = useLottie(options, style)

  return View
}

export default AnimatedLogo
