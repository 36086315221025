import React from 'react'
import { ISessionDetails } from '../../../models/session/session'
import {
  Box,
  Flex,
  Heading,
  IconButton,
  TabPanel,
  TabPanels,
  Tooltip,
  useToast,
} from '@chakra-ui/react'
import { blueScroll } from '../../../utils/scroll'
import { FiPause, FiPlay } from 'react-icons/fi'
import { assignSessionData } from '../sessionPlayer/player.utils'
import { useAppSelector } from '../../../utils/reduxUtils'
import { IPlayer } from '../sessionAnnotationPanels/sessionAnnotationPanel'
import { WordElement } from './word.components'
import { play } from '../session.utils'
import { stop } from '../sessionPlayer/player.utils'
import { useCreateNewAnnotationMutation } from '../../../newFeatures/annotations/service'
import { sound } from '@pixi/sound'
import { IAudios } from '../audio.utils'
import { IPopoverData } from '../../../components/SessionPopoverComment/sessionPopoverComment'

let startPosition = { start: -1, position: -1 }

export const SessionTranscriptionPanel = ({
  selectedAnnotationTypeId,
  data,
  boxRef,
  progress,
  player,
  isAnnotationToolActive,
  annotationsChildren,
  playAnnotation,
  onAnnotationPointerLeave,
  onAnnotationPointerEnter,
  setPlayer,
  renderProgress,
  refetchAnnotations,
  selectedAudio,
  setPopoverData,
}: {
  selectedAnnotationTypeId: string | null
  data?: ISessionDetails
  progress: { progress: number; duration: number }
  boxRef: React.MutableRefObject<any>
  isAnnotationToolActive: boolean
  renderProgress: (progress: number, duration: number) => void
  setPlayer: React.Dispatch<React.SetStateAction<IPlayer>>
  annotationsChildren: any
  player: IPlayer
  playAnnotation: (annotationId: string) => void
  onAnnotationPointerLeave: () => void
  onAnnotationPointerEnter: (id: string) => void
  refetchAnnotations: () => void
  selectedAudio: IAudios | null
  setPopoverData: (data: IPopoverData) => void
}) => {
  const { is_annotator } = useAppSelector((state) => state.user)
  const [createNewAnnotation] = useCreateNewAnnotationMutation()
  const toast = useToast()

  const createAnnotation = (end: number) => {
    if (data && selectedAnnotationTypeId && selectedAudio) {
      const soundsElements = sound.find(selectedAudio?.id)
      const duration = data.duration / 1000
      const audioDuration = soundsElements.duration
      const progressStart = (startPosition.start * 1000) / audioDuration
      const progressEnd = (end * 1000) / audioDuration
      const startTime = progressStart * duration + 10
      const endTime = progressEnd * duration - 10
      const waveWidth =
        boxRef?.current?.parentElement?.offsetWidth *
        boxRef?.current?.parentElement?.zoom
      const position =
        ((startPosition.start > end ? endTime : startTime) / data.duration) *
        waveWidth

      if (
        boxRef?.current?.parentElement?.zoom &&
        boxRef?.current?.parentElement?.zoom > 1
      ) {
        boxRef.current?.parentElement.scrollTo({
          left:
            (startPosition.start / audioDuration) * waveWidth -
            boxRef?.current?.parentElement?.offsetWidth / 2,
        })
      }

      createNewAnnotation({
        sessionId: data.id,
        start: Math.floor(startTime),
        end: Math.floor(endTime),
        description: '',
        is_published: true,
        annotation_type_id: selectedAnnotationTypeId,
      })
        .then(async (response: any) => {
          await refetchAnnotations()
          const positionX =
            (boxRef.current.parentElement.offsetWidth * position) /
            (boxRef.current.parentElement.zoom *
              boxRef.current.parentElement.offsetWidth)
          setPopoverData({
            x:
              position < 300
                ? 300
                : position > 0
                ? positionX
                : position / boxRef.current.parentElement.zoom,
            startText: '',
            sessionId: data ? data.id : '',
            annotationId: response.data.data.annotation_id,
            text: '',
            start: startTime,
            end: endTime,
          })
          toast({
            title: `Annotation saved`,
            position: 'bottom-left',
            status: 'success',
            isClosable: true,
          })
        })
        .catch(() => {
          toast({
            title: 'Error while saving annotation',
            position: 'bottom-left',
            status: 'error',
            isClosable: true,
          })
        })
    }
    startPosition = { start: -1, position: -1 }
  }
  return (
    <TabPanels h="100%" overflow="hidden">
      {data &&
        assignSessionData(data)
          .filter(
            ({ transcription }) =>
              transcription && transcription.length > 0 && is_annotator,
          )
          .map(({ id, name, transcription }, index) => {
            return (
              <TabPanel
                p="0"
                key={`transcription_panel_${name}_${id}_${index}`}
                h="calc(100% - 50px)"
              >
                <Heading as="h4" size="xs" mb="10px">
                  Transcription
                </Heading>
                <Flex
                  flexDirection="column"
                  css={blueScroll}
                  id={`transcription_scroll_${name}`}
                  overflow="auto"
                  maxH="25vh"
                  pr="15px"
                >
                  {transcription?.map(
                    ({ text, timestamp, word_results }, index) => {
                      const time = progress.duration * progress.progress

                      const waveWidth =
                        boxRef?.current?.parentElement?.offsetWidth *
                        boxRef?.current?.parentElement?.zoom
                      const annotationsList = (
                        isAnnotationToolActive ? annotationsChildren : []
                      ).map((element: any) => {
                        const startProgress = element.start / waveWidth
                        const endProgress = element.end / waveWidth

                        return startProgress &&
                          endProgress &&
                          element.children.length > 0
                          ? {
                              id: element.id.replaceAll('annotation', ''),
                              startAnnotation: startProgress,
                              endAnnotation: endProgress,
                              duration: element.duration,
                              description: element.description,
                              color: element.color ? element.color : 'white',
                              text: element.children[2]._text,
                            }
                          : null
                      })
                      const isCurrent =
                        player.isPlaying &&
                        timestamp[0] <= time &&
                        timestamp[1] > time
                      return (
                        <Flex
                          key={`transcription_panel_flex_${name}_${id}_${index}_${word_results}`}
                          gap="2"
                          alignItems="flex-start"
                          fontSize="13"
                          mb="2"
                        >
                          <IconButton
                            size="xs"
                            icon={isCurrent ? <FiPause /> : <FiPlay />}
                            onClick={async () => {
                              !isCurrent
                                ? play({
                                    name,
                                    id,
                                    startAt: timestamp[0],
                                    data,
                                    renderProgress,
                                    setPlayer,
                                    player,
                                    endAt: timestamp[1],
                                    isTranscription: true,
                                    transcriptionName:
                                      text.replaceAll(' ', '') + '_' + id,
                                  })
                                : stop({
                                    name,
                                    id,
                                    player,
                                    setPlayer,
                                  })
                            }}
                            aria-label={`play_sprite_${text}`}
                          />
                          <Flex mb="1" gap="1" maxW="100%" flexWrap="wrap">
                            {word_results.map(({ word, start, end }, index) => {
                              const annotationElement = annotationsList?.filter(
                                (element: any) => {
                                  if (
                                    element &&
                                    element.duration &&
                                    element.duration > 0
                                  ) {
                                    const condition =
                                      start / element.duration <
                                        element.endAnnotation &&
                                      end / element.duration >
                                        element.startAnnotation
                                    return condition
                                  } else {
                                    return false
                                  }
                                },
                              )

                              return annotationElement &&
                                annotationElement.length > 0 &&
                                annotationElement[0]?.text ? (
                                <Tooltip
                                  key={`transcription_word_${word}_${start}_${stop}_${index}`}
                                  placement="bottom-end"
                                  borderRadius="10px"
                                  hasArrow={false}
                                  size="md"
                                  background="white"
                                  border={`2px solid ${annotationElement[0]?.color}`}
                                  label={
                                    <Box
                                      fontSize="xs"
                                      color="black"
                                      borderRadius="10px"
                                      p="1"
                                    >
                                      <Box mb="2px">
                                        <strong>
                                          {annotationElement[0]?.text}
                                        </strong>
                                      </Box>
                                      {annotationElement[0]?.description}
                                    </Box>
                                  }
                                >
                                  {WordElement({
                                    isTooltip: true,
                                    text,
                                    timestamp,
                                    word,
                                    start,
                                    time,
                                    isCurrent,
                                    end,
                                    annotationElement,
                                    playAnnotation,
                                    onAnnotationPointerLeave,
                                    onAnnotationPointerEnter,
                                    onHighlight: ({
                                      word,
                                      start,
                                      end,
                                      up,
                                      position,
                                    }) => {
                                      if (!up) {
                                        startPosition = {
                                          start,
                                          position,
                                        }
                                      } else {
                                        if (
                                          Math.abs(
                                            position - startPosition.position,
                                          ) > 10
                                        ) {
                                          createAnnotation(end)
                                        } else {
                                          startPosition = {
                                            start: -1,
                                            position: -1,
                                          }
                                        }
                                      }
                                    },
                                  })}
                                </Tooltip>
                              ) : (
                                <Box
                                  key={`transcription_word_${word}_${start}_${stop}_${index}`}
                                >
                                  {WordElement({
                                    isTooltip: false,
                                    text,
                                    timestamp,
                                    word,
                                    start,
                                    time,
                                    isCurrent,
                                    end,
                                    annotationElement,
                                    playAnnotation,
                                    onAnnotationPointerLeave,
                                    onAnnotationPointerEnter,
                                    onHighlight: ({
                                      word,
                                      start,
                                      end,
                                      up,
                                      position,
                                    }) => {
                                      if (!up) {
                                        startPosition = {
                                          start,
                                          position,
                                        }
                                      } else {
                                        if (
                                          Math.abs(
                                            position - startPosition.position,
                                          ) > 10
                                        ) {
                                          createAnnotation(end)
                                        } else {
                                          startPosition = {
                                            start: -1,
                                            position: -1,
                                          }
                                        }
                                      }
                                    },
                                  })}
                                </Box>
                              )
                            })}
                          </Flex>
                        </Flex>
                      )
                    },
                  )}
                </Flex>
              </TabPanel>
            )
          })}
    </TabPanels>
  )
}
