import { IAudios } from '../../../models/waveAudio/waveAudio'
import React, { useEffect } from 'react'
import { blueScroll, blueScrollX } from '../../../utils/scroll'
import { Box } from '@chakra-ui/react'

export const PlayerCanvasDiv = ({
  height,
  selectedAudio,
  boxRef,
  audios,
  identifier,
  scrollBoxRef,
}: {
  height: number
  selectedAudio: IAudios | null
  boxRef: React.MutableRefObject<any>
  audios: IAudios[]
  identifier?: string | null
  scrollBoxRef: React.MutableRefObject<null>
}) => (
  <>
    <Box
      css={{
        ...blueScroll,
        '&::-webkit-scrollbar': {
          height: `0px`,
        },
      }}
      style={
        {
          width: 'calc(100% - 24px)',
          WebkitMaskImage: '-webkit-radial-gradient(white, black)',
          position: 'relative',
          cursor: 'pointer',
          height: height,
          maxHeight: height,
          marginLeft: '24px',
          overflowX: 'auto',
          overflowY: 'hidden',
          borderRadius: '10px',
          border: `1px solid ${
            selectedAudio?.isConversion ? '#986cbd' : '#1b91d6'
          }`,
        } as any // Because of the webkit-mask-image property, this fixes border-radius-clip on safari
      }
      onScroll={(event) => {
        const scrollPosition = boxRef?.current.parentElement?.scrollLeft
        // @ts-ignore
        scrollBoxRef.current.parentElement.scrollTo({
          left: scrollPosition,
        })
      }}
    >
      <canvas
        ref={boxRef}
        style={{
          overflow: 'hidden',
          height: height * audios.length,
          position: 'absolute',
          left: 0,
          top: `-${
            (audios.findIndex((audio) => audio.id === selectedAudio?.id) || 0) *
            height
          }px`,
          width: '100%',
          borderRadius: '10px',
        }}
        id={`waveform_${identifier}`}
        data-qa__id="audio_waveform"
      />
    </Box>
    <Box
      style={{
        width: 'calc(100% - 24px)',
        marginLeft: '24px',
        overflowX: 'auto',
        position: 'absolute',
      }}
      css={blueScrollX}
      onScroll={(event) => {
        // @ts-ignore
        const scrollPosition = scrollBoxRef?.current.parentElement?.scrollLeft
        boxRef.current?.parentElement.scrollTo({
          left: scrollPosition,
        })
      }}
    >
      <Box
        ref={scrollBoxRef}
        id="scroll_for_wave"
        style={{
          bottom: '-6px',
          height: '1px',
          width: '100%',
        }}
      />
    </Box>
  </>
)
