import { createAction } from '@reduxjs/toolkit'

export const logout = createAction<{ url: string }>('login/LOGOUT')

export const login = createAction<{
  tokenOrEmailOrDomain: string | undefined
  isSSO: boolean
}>('login/LOGIN')
export const iframeLogin = createAction<{
  token: string | undefined
  features: {
    can_be_recorded: string | null
    can_control_recording: string | null
    should_be_recorded: string | null
  }
}>('login/IFRAME_LOGIN')
export const isLoading = createAction<boolean>('login/IS_LOADING')
export const setIsForceAuth = createAction<boolean>('login/SET_IS_FORCE_AUTH')
export const setIsLogged = createAction<{ isLogged: boolean }>(
  'login/SET_IS_LOGGED',
)

export const setError = createAction<{ error: { [key: string]: string } }>(
  'login/SET_ERROR',
)

export const checkIfLogged = createAction('login/CHECK_IF_LOGGED')
export const setTray = createAction<boolean>('login/SET_IS_TRAY')
export const checkIsStatus401 = createAction<number>(
  'login/CHECK_IS_AUTHORIZED',
)
