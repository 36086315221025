import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiVersion } from '../../services'
import {
  IAnnotationsElement,
  IAnnotationTypes,
} from '../../models/annotations/annotations'
import { annotationsColors, rgbToHex } from '../../utils/colors'
import { BaseQueryArg } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
const assignColorForAnnotationType = (annotation_types: IAnnotationTypes[]) => {
  return annotation_types.map((element, index) => ({
    ...element,
    colorHEX: annotationsColors[index]
      ? rgbToHex(annotationsColors[index])
      : '',
    color: annotationsColors[index] ? annotationsColors[index] : '',
  }))
}

const apiUrl = process.env.REACT_APP_API_URL + `/api/${apiVersion}`
export const annotationsApi = createApi({
  reducerPath: 'annotationsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
    prepareHeaders(headers, { getState }) {
      return headers
    },
    credentials: 'include',
  }),
  endpoints: (builder) => ({
    // Annotation types
    getAnnotationTypes: builder.query<
      { data: IAnnotationTypes[] },
      { id: string | undefined }
    >({
      query: ({ id }: { id: string | undefined }) => ({
        url: `/annotations/${id}/types/`,
        method: 'GET',
      }),
      transformResponse: (response: any): any => {
        return { data: assignColorForAnnotationType(response.data) }
      },
    }),
    // Annotation types
    delete: builder.mutation<
      any,
      { sessionId: string; annotationTypeId: string }
    >({
      query: ({
        sessionId,
        annotationTypeId,
      }: {
        sessionId: string
        annotationTypeId: string
      }) => ({
        url: `/annotations/${sessionId}/types/${annotationTypeId}/`,
        method: 'DELETE',
      }),
    }),
    // Annotation types
    create: builder.mutation<any, { sessionId: string; typeName: string }>({
      query: ({
        sessionId,
        typeName,
      }: {
        sessionId: string
        typeName: string
      }) => ({
        url: `/annotations/${sessionId}/types/`,
        method: 'POST',
        body: {
          data: {
            annotation_type_name: typeName,
            description: '',
          },
        },
      }),
    }),
    // Annotation types
    getAllAnnotationsForSession: builder.query<
      { data: IAnnotationsElement[] },
      { id: string | undefined }
    >({
      query: ({ id }: { id: string | undefined }) => ({
        url: `/annotations/${id}/`,
        method: 'GET',
      }),
    }),
    // Annotation
    deleteAnnotation: builder.mutation<
      { data: any },
      {
        sessionId: string
        annotationId: string
      }
    >({
      query: ({ sessionId, annotationId }) => {
        return {
          url: `/annotations/${sessionId}/annotation/${annotationId}/`,
          method: 'DELETE',
        }
      },
    }),
    // Annotation
    updateAnnotation: builder.mutation<
      { data: any },
      {
        sessionId: string
        start: number
        end: number
        annotationId: string
        description: string
        selectedTypeId?: string
      }
    >({
      query: ({
        sessionId,
        annotationId,
        description,
        start,
        end,
        selectedTypeId,
      }) => {
        let data: {
          start: number
          end: number
          description: string
          annotation_type_id?: string
        } = {
          start,
          end,
          description,
        }

        if (selectedTypeId) {
          data['annotation_type_id'] = selectedTypeId
        }
        return {
          url: `/annotations/${sessionId}/annotation/${annotationId}/`,
          method: 'POST',
          body: {
            data,
          },
        }
      },
    }),
    // Annotation
    createNewAnnotation: builder.mutation<
      { data: any },
      {
        sessionId: string
        start: number
        end: number
        description: string
        is_published: boolean
        annotation_type_id: string
      }
    >({
      query: ({
        sessionId,
        annotation_type_id,
        description,
        start,
        end,
        is_published,
      }) => {
        return {
          url: `/annotations/${sessionId}/`,
          method: 'POST',
          body: {
            data: {
              start,
              end,
              is_published,
              description,
              annotation_type_id,
            },
          },
        }
      },
    }),
  }),
})

export const {
  useGetAnnotationTypesQuery,
  useGetAllAnnotationsForSessionQuery,
  useDeleteMutation,
  useCreateMutation,
  useUpdateAnnotationMutation,
  useDeleteAnnotationMutation,
  useCreateNewAnnotationMutation,
} = annotationsApi
