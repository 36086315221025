import { Application, Container, DisplayObject, Graphics } from 'pixi.js'
import { Sound, sound } from '@pixi/sound'
import React from 'react'
import { IAudios } from '../audio.utils'
import { ISessionDetails } from '../../../models/session/session'
import { Text as TextPixi } from '@pixi/text'
import { lightenColor } from '../../../utils/colors'
import { IPlayer } from '../sessionAnnotationPanels/sessionAnnotationPanel'
import { getTime } from '../../../utils/audioUtils'
import { drawingAnnotation } from './annotations.actions'
import { render } from './render.wave.utils'

let loadedCount: number = 0
let elementsToRender: (() => void)[] = []
let waveWidth: number | null = null

const getWaveData = ({
  isConversion,
  id,
  data,
}: {
  isConversion: boolean
  id: any
  data: ISessionDetails
}) => {
  const waveArray = !isConversion
    ? data && data.wave
      ? data.wave
      : []
    : data &&
      data.conversions &&
      data.conversions.filter(({ id: conversionId }) => conversionId === id)
        .length > 0
    ? data.conversions.filter(({ id: conversionId }) => conversionId === id)[0]
        .wave
    : []
  return waveArray
}

export interface IAudiosWithTranscription {
  url: string
  name: string
  id: string
  description: string
  transcription?: {
    text: string
    timestamp: number[]
    word_results: { word: string; end: number; start: number }[]
  }[]
  isConversion: boolean
}

export const assignSessionData = (
  session: ISessionDetails,
): IAudiosWithTranscription[] =>
  session && Object.keys(session).length > 0
    ? [
        ...(session as ISessionDetails).conversions?.map(
          ({ id, download_url, description, name, transcription }: any) => ({
            isConversion: true,
            url: download_url,
            id,
            description: description,
            name: name,
            transcription,
          }),
        ),
        {
          isConversion: false,
          url: (session as any).download_url,
          id: (session as any).id,
          description: (session as any).description,
          transcription: (session as any).transcription,
          name: (session as any).user?.username,
        },
      ]
    : []

export const spinnerElement = ({
  app,
  radius,
  positionY,
  positionX,
}: {
  app: Application
  radius: number
  positionX: number
  positionY: number
}) => {
  const spinner = new Graphics()
  const startAngle = -Math.PI / 4
  const endAngle = Math.PI / 4
  const segments = 4
  const lineWidth = 2
  const deltaAngle = (endAngle - startAngle) / segments
  spinner.lineStyle(lineWidth, 0x171923)

  for (let i = 0; i <= segments; i++) {
    const angle = startAngle + i * deltaAngle
    const x = radius * Math.cos(angle)
    const y = radius * Math.sin(angle)

    spinner.drawCircle(x, y, lineWidth / 2)
  }

  spinner.pivot.set(0, 0)
  spinner.position.set(positionX, positionY)

  app?.ticker?.add(() => {
    if (spinner) {
      spinner.rotation += 0.1
    }
  })
  return spinner
}

const getLoader = (
  isSpinner: boolean,
  app: Application,
  url: any,
  isConversion: boolean,
  x: number,
  y: number,
) => {
  if (isSpinner) {
    return spinnerElement({ app, positionX: x, positionY: y, radius: 12 })
  } else {
    const startText = new TextPixi(
      `'Error with ${isConversion ? 'conversion' : ''}audio url`,
      {
        fontFamily: 'Arial',
        fontSize: 14,
        fill: 0x171923,
        align: 'center',
      },
    )
    startText.y = y
    startText.x = x
    return startText
  }
}

export interface IAnnotationRefBody {
  annotationModeAdd: boolean
  downPosition: number | null
  upPosition: number | null
  isSaving: boolean
  selectedTypeId: string | null
  selectedAnnotationType: {
    id: string | null
    colorHEX: string | null
  }
}

export type IAnnotationRef = React.MutableRefObject<IAnnotationRefBody>

interface IProps {
  annotationRef: IAnnotationRef
  data?: ISessionDetails
  playhead: Graphics
  audios: IAudios[]
  identifier: string
  height: number
  setApplication: React.Dispatch<React.SetStateAction<Application | null>>
  setSelectedAudio: React.Dispatch<React.SetStateAction<IAudios | null>>
  selectedAudio: IAudios | null
  audioParameter: string | null
  timeParameter: number
  resize: (app: Application) => void
  onResize: (app: Application) => void
  boxRef: React.MutableRefObject<any>
  setErrors: React.Dispatch<React.SetStateAction<string[]>>
  errors: string[]
  setLoader: React.Dispatch<React.SetStateAction<boolean>>
  waves: Container<DisplayObject>[]
  setProgress: React.Dispatch<
    React.SetStateAction<{ progress: number; duration: number }>
  >
  player: IPlayer
  setPlayer: React.Dispatch<React.SetStateAction<IPlayer>>
  renderProgress: (progress: number, duration: number) => void
  tooltip: Graphics
  tooltipText: TextPixi
  createAnnotation?: ({
    position,
    startTime,
    endTime,
    selectedTypeId,
    additionalAction,
  }: {
    position: number
    startTime: number
    endTime: number
    selectedTypeId: string
    additionalAction: () => void
  }) => void
}

export const assignPlayer = (props: IProps) => {
  const {
    setApplication,
    setSelectedAudio,
    audios,
    identifier,
    height,
    playhead,
    data,
    resize,
    onResize,
    audioParameter,
    timeParameter,
    boxRef,
    errors,
    setErrors,
    setLoader,
    waves,
    setProgress,
    annotationRef,
  } = props
  loadedCount = 0
  elementsToRender = []

  const canvasBox = document.getElementById(
    `waveform_${identifier}`,
  ) as HTMLCanvasElement

  if (canvasBox) {
    playhead.height = height * audios.length
    const app: any = new Application({
      height: height * audios.length,
      width: canvasBox.parentElement?.parentElement?.offsetWidth || 0,
      resolution: window.devicePixelRatio,
      preserveDrawingBuffer: true,
      autoStart: true,
      backgroundColor: 0xffffff,
      view: canvasBox,
    })
    const background = new Graphics()
    background.beginFill(0xffffff)
    background.drawRect(0, 0, (data?.duration || 0) / 100, height)
    app.stage.addChild(background)

    resize(app)
    requestAnimationFrame(() => {
      setApplication(app)
      renderSounds({
        ...props,
        options: {
          id: audioParameter,
          time: timeParameter,
        },
        boxRef,
        height,
        playhead,
        audios,
        data,
        setLoader,
        errors,
        setErrors,
        waves,
        app,
      })

      document.getElementById('waves')?.appendChild(app.view)
      window.addEventListener('resize', () => onResize(app))
      app.stage.addChild(playhead)

      requestAnimationFrame(() => {
        resize(app)
      })
    })

    return () => {
      window.removeEventListener('resize', () => onResize(app))
      sound.stopAll()
      sound.removeAll()
      while (app.stage.children[0]) {
        app.stage.removeChild(app.stage.children[0])
      }
      app.destroy()
      setApplication(null)
    }
  }
}

const renderSounds = (
  props: IProps & {
    app: Application
    options: {
      time: number
      id: string | null
    }
  },
) => {
  const {
    app,
    options,
    boxRef,
    audios,
    height,
    playhead,
    setErrors,
    errors,
    setLoader,
    data,
    waves,
  } = props
  if (
    !app ||
    !boxRef?.current ||
    !app.renderer?.view?.parentElement?.parentElement?.offsetWidth
  ) {
    return
  }

  setErrors(audios.map(({ id }) => id))

  audios.forEach(({ url, name, id, isConversion }, index) => {
    const textY = height * index + (height / 2 - 10)
    const textX =
      (boxRef.current.parentElement.offsetWidth *
        boxRef.current.parentElement.zoom) /
      2
    const loader = getLoader(
      isConversion ? true : !!url,
      app,
      url,
      isConversion,
      textX,
      textY,
    )

    app.stage.addChild(loader)

    if (!url) {
      setLoader(false)
      return
    }
    if (!data) {
      return
    }
    const sessionData = assignSessionData(data)
    const sprites = sessionData.filter((el) => el.id === id)
    const spritesObject =
      sprites.length === 0
        ? {}
        : sprites[0]?.transcription?.reduce((acc: any, item: any) => {
            const { text, timestamp } = item
            acc[text.replaceAll(' ', '') + '_' + id] = {
              start: timestamp[0],
              end: timestamp[1],
            }
            return acc
          }, {})
    sound.disableAutoPause = true
    const soundsElements = sound.add(id, {
      url: url,
      sprites: spritesObject,
      singleInstance: true,
      preload: true,
      loaded(err) {
        const currentProps = { ...props }

        if (err) {
          const errText = new TextPixi('Error while getting audio', {
            fontFamily: 'Arial',
            fontSize: 14,
            fill: 0x171923,
            align: 'center',
          })

          errText.y = textY
          errText.x = textX
          app.stage.addChild(errText)

          return
        }

        setErrors(errors.filter((elementId) => elementId !== id))
        loadedCount += 1

        elementsToRender = [
          ...elementsToRender,
          () => {
            renderElement({
              ...currentProps,
              options,
              color: isConversion ? 0x986cbd : 0x1b91d6,
              app,
              soundsElements,
              name,
              id,
              positionY: index * height,
              isConversion,
            })
          },
        ]

        if (loadedCount === audios.length) {
          setLoader(false)
          app.stage.removeChild(loader)
          elementsToRender.forEach((element) => element())
        }

        requestAnimationFrame(() => {
          app.stage.addChild(playhead)
        })
      },
    })
  })
}

const renderElement = (
  props: IProps & {
    options?: { id: string | null; time: number | null }
    color: number
    app: any
    soundsElements: Sound
    name: string
    id: string
    positionY: number
    isConversion: boolean
  },
) => {
  const {
    options,
    selectedAudio,
    color,
    app,
    soundsElements,
    positionY,
    name,
    id,
    isConversion,
    audios,
    boxRef,
    height,
    waves,
    player,
    data,
    setProgress,
    setPlayer,
    setSelectedAudio,
    tooltip,
    tooltipText,
    annotationRef,
    renderProgress,
    createAnnotation,
  } = props
  const waveIndex = audios.findIndex((audio) => audio.id === id)
  const wave = waves[waveIndex]
  if (!data) {
    return
  }

  const blocks = render(
    soundsElements,
    {
      width:
        boxRef.current.parentElement.offsetWidth *
        boxRef.current.parentElement.zoom,
      height: height,
      fill: isConversion ? 0x986cbd : 0x1b91d6,
      isConversion: isConversion,
    },
    getWaveData({ isConversion, id, data }),
  )

  drawingAnnotation({
    audios,
    selectedAudio,
    wave,
    height,
    app,
    annotationRef,
    setPlayer,
    player,
    createAnnotation,
    data,
    boxRef,
  })
  const progress = (options?.time || 0) / 1000
  const duration = soundsElements.duration

  const whiteBackground: any = new Graphics()
  whiteBackground.beginFill(lightenColor(color, 1))
  const offsetProgress = progress / duration

  whiteBackground.drawRect(
    0,
    0,
    boxRef.current.parentElement.offsetWidth *
      boxRef.current.parentElement.zoom,
    height,
  )
  wave.addChild(whiteBackground)

  blocks.forEach((block) => {
    wave.addChild(block)
  })

  wave.name = id
  wave.position = { x: 0, y: positionY }
  wave.interactive = true

  tooltipRenderer({
    app,
    element: wave,
    duration: soundsElements.duration,
    tooltip,
    boxRef,
    tooltipText,
  })

  const onClick = async (e: any) => {
    const newAnnotationSegment = app.stage.children.find(
      (el: any) => el.id && el.id === 'new_annotation',
    )

    let isCreatingAnnotation = false

    if (newAnnotationSegment) {
      newAnnotationSegment.calculateBounds()
      isCreatingAnnotation = newAnnotationSegment.width > 0
    }

    if (soundsElements && !isCreatingAnnotation) {
      playerClickAction({
        data,
        e,
        soundsElements,
        wave,
        boxRef,
        audios,
        player,
        setPlayer,
        renderProgress,
      })
    }
  }

  wave.on('pointerdown', async (e) => {
    await onClick(e)
  })
  wave.on('click', async (e) => {
    await onClick(e)
  })

  // @ts-ignore
  wave.additionalOptions = {
    soundsElements,
    id,
  }

  setProgress({ progress: progress / duration, duration: duration })
  renderProgress(progress / duration, duration)

  if (options?.id) {
    const audio = audios.find((el) => el.id === options.id)
    if (audio) {
      setPlayer({
        ...player,
        name: audio.name,
        id: audio.id,
      })
      setSelectedAudio(audio)
    }
  }

  app.stage.addChild(wave)
}

const playerClickAction = ({
  data,
  e,
  soundsElements,
  wave,
  boxRef,
  setPlayer,
  player,
  audios,
  renderProgress,
}: {
  data?: ISessionDetails
  e: any
  soundsElements: Sound
  wave: Container<DisplayObject>
  boxRef: React.MutableRefObject<any>
  audios: IAudios[]
  renderProgress: (progress: number, duration: number) => void
  setPlayer: React.Dispatch<React.SetStateAction<IPlayer>>
  player: IPlayer
}) => {
  const progress =
    e.data.global.x /
    (boxRef.current.parentElement.offsetWidth *
      boxRef.current.parentElement.zoom)
  const duration = soundsElements.duration
  const wasPlayingWhenClicked = soundsElements.isPlaying

  renderProgress(progress, duration)

  const audio = audios.filter(({ id }) => wave.name === id)
  sound.stopAll()
  setPlayer({
    ...player,
    isPlaying: false,
  })

  if (wasPlayingWhenClicked) {
    play({
      name: audio[0].name,
      id: audio[0].id,
      startAt: progress * duration,
      data,
      renderProgress,
      setPlayer,
      player,
    })
  } else {
    stop({
      name: audio[0].name,
      id: audio[0].id,
      setPlayer,
      player,
    })
  }
}

export const resizeRenderer = ({
  app,
  waves,
  boxRef,
  height,
  audios,
  data,
  renderProgress,
  progress,
  closePopover,
}: {
  app: Application
  waves: Container<DisplayObject>[]
  boxRef: React.MutableRefObject<any>
  height: number
  audios: IAudios[]
  data?: ISessionDetails
  renderProgress: (progress: number, duration: number) => void
  progress: { progress: number; duration: number }
  closePopover: () => void
}) => {
  closePopover()
  if (app.renderer?.view?.parentElement?.parentElement?.offsetWidth != null) {
    waveWidth =
      boxRef.current.parentElement.offsetWidth *
      boxRef.current.parentElement.zoom
    app.renderer.resize(
      app.renderer.view.parentElement.offsetWidth *
        boxRef.current.parentElement.zoom,
      height * audios.length,
    )

    if (!data) {
      return
    }

    waves.forEach((wave, i) => {
      if (wave.children.length) {
        const id = audios[i].id
        const color = audios[i].isConversion ? 0x986cbd : 0x1b91d6
        const soundsElements = sound.find(id)
        const blocks = render(
          soundsElements,
          {
            width:
              boxRef.current.parentElement.offsetWidth *
              boxRef.current.parentElement.zoom,
            height: height,
            fill: color,
            isConversion: audios[i].isConversion,
          },
          getWaveData({ isConversion: audios[i].isConversion, id, data }),
        )
        const whiteBackground: any = new Graphics()
        whiteBackground.beginFill(lightenColor(color, 1))
        whiteBackground.drawRect(
          0,
          0,
          boxRef.current.parentElement.offsetWidth *
            boxRef.current.parentElement.zoom,
          height,
        )

        while (wave.children[0]) {
          wave.removeChild(wave.children[0])
        }
        wave.addChild(whiteBackground)
        blocks.forEach((block) => {
          wave.addChild(block)
        })
        renderProgress(progress.progress, progress.duration)
      }
    })
  }
}
const tooltipPadding = 25

export const tooltipRenderer = ({
  app,
  element,
  duration,
  tooltip,
  boxRef,
  tooltipText,
}: {
  app: Application
  element: any
  duration: number
  tooltip: any
  boxRef: any
  tooltipText: any
}) => {
  element.on('pointerover', () => {
    app.stage?.addChild(tooltip)
  })
  element.on('pointerout', () => {
    app.stage?.removeChild(tooltip)
  })
  element.on('pointermove', (e: any) => {
    if (boxRef.current?.parentElement?.parentElement) {
      const yRange =
        boxRef.current.parentElement.offsetHeight - 1.75 * tooltipPadding
      const progress =
        e.data.global.x /
        (boxRef.current.parentElement.offsetWidth *
          boxRef.current.parentElement.zoom)
      const offset = e.data.global.y % boxRef.current.parentElement.offsetHeight
      const slot = Math.floor(
        e.data.global.y / boxRef.current.parentElement.offsetHeight,
      )
      tooltip.x = e.data.global.x
      tooltip.y =
        slot * boxRef.current.parentElement.offsetHeight +
        tooltipPadding / 2 +
        (yRange * offset) / boxRef.current.parentElement.offsetHeight

      const progressX =
        e.data.originalEvent.clientX / boxRef.current.parentElement.offsetWidth

      if (progressX > 0.8) {
        tooltip.x = tooltip.x - tooltip.width
      } else {
        tooltip.x = tooltip.x + tooltipPadding
      }
      tooltipText.text = `${getTime({
        duration: progress * duration * 1000,
      })}`

      const parent = tooltip.parent

      if (parent) {
        parent.removeChild(tooltip)
        parent.addChild(tooltip)
      }

      tooltipText.updateText(false)
    }
  })
}

const play = async ({
  name,
  id,
  startAt,
  endAt,
  isTranscription,
  transcriptionName,
  player,
  setPlayer,
  data,
  renderProgress,
}: {
  name: string
  id: string
  startAt?: number
  endAt?: number
  isTranscription?: boolean
  transcriptionName?: string
  player: IPlayer
  setPlayer: React.Dispatch<React.SetStateAction<IPlayer>>
  data?: ISessionDetails
  renderProgress: (progress: number, duration: number) => void
}) => {
  if (!data) {
    return
  }
  const time = isTranscription ? {} : { time: startAt ? startAt : 0 }
  let startPosition: number

  setPlayer({
    ...player,
    isPlaying: true,
    ...time,
    name,
    id,
  })
  sound.stopAll()

  const soundsElements = sound.find(id)
  const songInstance = await soundsElements?.play(
    isTranscription
      ? transcriptionName
      : {
          start: startAt ? startAt : 0,
          end: endAt ? endAt : 0,
          complete: (sound) => {
            console.log('complete')
          },
        },
  )

  songInstance?.on('end', () => {
    if (endAt != undefined && startAt != undefined && data.duration) {
      const duration = data.duration / 1000
      renderProgress(startPosition, duration)
      setPlayer({ ...player, isPlaying: false, name: '', id: '' })
    } else {
      renderProgress(0, 0)
      setPlayer({
        ...player,
        isPlaying: false,
        name: player.name,
        id: player.id,
      })
    }
  })

  songInstance?.on('progress', async (progress: number, duration: number) => {
    if (startPosition === undefined) {
      startPosition = progress
    }

    renderProgress(progress, duration)
  })
}

export const stop = async ({
  name,
  id,
  player,
  setPlayer,
}: {
  name: string
  id: string
  player: IPlayer
  setPlayer: React.Dispatch<React.SetStateAction<IPlayer>>
}) => {
  setPlayer({
    ...player,
    isPlaying: false,
    name,
    id,
  })
  sound.stopAll()
}

export const assignTooltip = ({
  tooltip,
  tooltipText,
}: {
  tooltip: Graphics
  tooltipText: TextPixi
}) => {
  tooltip.cursor = 'pointer !important'
  tooltip.addChild(tooltipText)
  tooltipText.anchor.x = 0
  tooltipText.anchor.y = 0
  tooltip.pivot.y = 0
  tooltip.pivot.x = 0
}
