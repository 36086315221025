import {
  Button,
  Container,
  Input,
  Stack,
  Heading,
  Flex,
  Text,
  InputGroup,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react'
import { CSSTransition } from 'react-transition-group'
import React, { useEffect, useMemo, useState } from 'react'
import AnimatedLogo from '../AnimatedLogo/LogoAnimation/index'
import { Divider } from '@chakra-ui/layout'

export interface ILogin {
  signIn: (token: string) => void
  buttonTitle: string
  placeholder: string
  isAdditionalAuth?: boolean
  additionalAuthList?: { id: string; text: string }[]
  onAdditionalAuthClick?: (value: string) => void
  footerText?: string | JSX.Element
  loginError: {
    [key: string]: string
  }
  onAdditionalForceAuthClick?: (value: boolean) => void
  isAdditionalForceAuth?: boolean
  isAdditionalForceAuthChecked?: boolean
  isLoading: boolean
  isAutoComplete?: boolean
  headerText: string
  loginBackground?: any
  isTranslation?: boolean
  loginInputType?: string
  onInputChange?: (value: string) => void
}

const Login = ({
  signIn,
  buttonTitle,
  placeholder,
  isAdditionalAuth,
  additionalAuthList,
  onAdditionalAuthClick,
  footerText,
  onAdditionalForceAuthClick,
  isAdditionalForceAuthChecked,
  isAdditionalForceAuth,
  isLoading,
  loginError,
  onInputChange,
  headerText,
  isAutoComplete,
  loginBackground,
  isTranslation,
  loginInputType,
}: ILogin) => {
  const [token, handleToken] = useState('')

  useEffect(() => {
    handleToken('')
    if (onInputChange) {
      onInputChange('')
    }
  }, [isAdditionalForceAuth])

  const opacityTime = '1s'

  const LogoElement = useMemo(
    () => <AnimatedLogo maxAnimationHeight="auto" />,
    [],
  )

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        if (token && !isLoading) {
          signIn(token)
        }
      }
    }
    document.addEventListener('keydown', keyDownHandler)
    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [isLoading, token])

  return (
    <>
      <Box
        w="100%"
        className="login-page__login__background"
        h="100%"
        sx={
          isTranslation
            ? {
                '.login_transition-appear': {
                  opacity: 0.01,
                },
                '.login_transition-appear-done': {
                  opacity: 1,
                  transition: `opacity ${opacityTime} ease-in`,
                },
              }
            : undefined
        }
      >
        <CSSTransition
          in={true}
          classNames="login_transition"
          timeout={{ appear: 50 }}
          appear
        >
          <Flex
            pos="absolute"
            alignItems="center"
            justifyContent="center"
            minW="100%"
            minH="100vh"
            bgGradient={loginBackground ? loginBackground : undefined}
            bg="gray.100"
          />
        </CSSTransition>
      </Box>

      <Box
        w="calc(100% - 60px)"
        h="100%"
        margin="0 30px"
        className="login-page__login__inner"
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          minW="100%"
          minH="100vh"
          py={{ base: '0', md: '24' }}
        >
          <Container
            maxW="md"
            zIndex="10"
            py={{ base: '8' }}
            px={{ base: '10' }}
            bg="white"
            pb="50px"
            boxShadow={{ base: 'xl' }}
            borderRadius={{ base: 'xl' }}
            style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
            sx={
              isTranslation
                ? {
                    '.login_transition-appear': {
                      opacity: 0.01,
                    },
                    '.login_transition-appear-done': {
                      opacity: 1,
                      transition: `opacity ${opacityTime} ease-in`,
                    },
                  }
                : undefined
            }
          >
            <Stack spacing="6" width="100%">
              <Stack gap="15px" align="center" w="100%">
                <Box position="relative" h="55px" w="100%">
                  <Box
                    position="absolute"
                    className="logo_element"
                    top="-70px"
                    transform="scale(0.8)"
                    data-qa__id="login_logo"
                  >
                    {LogoElement}
                  </Box>
                </Box>
                <CSSTransition
                  in={true}
                  classNames="login_transition"
                  timeout={{ appear: 50 }}
                  appear
                >
                  <Stack spacing="3" textAlign="center">
                    <Heading size="xs" mb="5px" fontWeight="500">
                      {headerText}
                    </Heading>
                  </Stack>
                </CSSTransition>
              </Stack>
              <CSSTransition
                in={true}
                classNames="login_transition"
                timeout={{ appear: 50 }}
                appear
              >
                <Stack spacing="6">
                  <Stack gap="15px">
                    {!isAdditionalForceAuth && (
                      <FormControl isInvalid={!!loginError.token} mb="0">
                        <InputGroup>
                          <Input
                            display={
                              isAdditionalForceAuth ? 'initial' : 'hidden'
                            }
                            w="100%"
                            autoFocus={true}
                            value={token}
                            type={loginInputType ? loginInputType : 'password'}
                            id={isAutoComplete ? 'token_username' : undefined}
                            name={isAutoComplete ? 'token_username' : undefined}
                            autoComplete={
                              isAutoComplete && !isAdditionalForceAuth
                                ? 'token_username'
                                : 'off'
                            }
                            isInvalid={!!loginError.token}
                            errorBorderColor="red.300"
                            onChange={(ev) => {
                              handleToken(ev.target.value)
                              if (onInputChange) {
                                onInputChange(ev.target.value)
                              }
                            }}
                            bg="white"
                            placeholder={placeholder}
                            data-qa__id="login_input"
                          />
                        </InputGroup>
                        <FormErrorMessage
                          fontSize="xs"
                          pos="absolute"
                          marginTop="56px !important"
                          right="0"
                          data-qa__id="login_error"
                        >
                          {loginError.token}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                    {isAdditionalForceAuth && (
                      <FormControl
                        isInvalid={!!loginError.token}
                        variant="floating"
                        mb="15px"
                      >
                        <InputGroup>
                          <Input
                            autoFocus={true}
                            value={token}
                            isInvalid={!!loginError.token}
                            errorBorderColor="red.300"
                            onChange={(ev) => {
                              handleToken(ev.target.value)
                              if (onInputChange) {
                                onInputChange(ev.target.value)
                              }
                            }}
                            bg="white"
                            placeholder=" "
                            data-qa__id="login_input"
                          />
                          <FormLabel>{placeholder}</FormLabel>
                        </InputGroup>
                        <FormErrorMessage
                          fontSize="xs"
                          pos="absolute"
                          marginTop="56px !important"
                          right="0"
                          data-qa__id="login_error"
                        >
                          {loginError.token}
                        </FormErrorMessage>
                        {/*Force auth*/}
                        {/*<Flex*/}
                        {/*  right="0"*/}
                        {/*  mt="1"*/}
                        {/*  pos="absolute"*/}
                        {/*  justifyContent="flex-end"*/}
                        {/*  alignItems="center"*/}
                        {/*>*/}
                        {/*  <Checkbox*/}
                        {/*    isInvalid={false}*/}
                        {/*    isChecked={!!isAdditionalForceAuthChecked}*/}
                        {/*    onChange={(e) => {*/}
                        {/*      if (onAdditionalForceAuthClick) {*/}
                        {/*        onAdditionalForceAuthClick(e.target.checked)*/}
                        {/*      }*/}
                        {/*    }}*/}
                        {/*  >*/}
                        {/*    Force re-authentication*/}
                        {/*  </Checkbox>*/}
                        {/*</Flex>*/}
                      </FormControl>
                    )}
                    <Button
                      isLoading={isLoading}
                      type="submit"
                      onClick={() => signIn(token)}
                      disabled={!token || isLoading}
                      variant="primary"
                      data-qa__id="login_button"
                      pb="4px"
                      pt="4px"
                      pl="16px"
                      pr="16px"
                      height="52px"
                      fontSize="16px"
                    >
                      {buttonTitle}
                    </Button>

                    {isAdditionalAuth && (
                      <>
                        <Flex align="center">
                          <Divider />
                          <Box>
                            <Text
                              whiteSpace="nowrap"
                              color="gray.300"
                              size="xs"
                              px="3"
                            >
                              or login with
                            </Text>
                          </Box>
                          <Divider />
                        </Flex>
                        {additionalAuthList?.map(({ text, id }, index) => {
                          return (
                            <Button
                              key={`additional_auth_${id}_${index}`}
                              variant="secondary"
                              onClick={() => {
                                if (onAdditionalAuthClick) {
                                  onAdditionalAuthClick(id)
                                }
                              }}
                            >
                              {text}
                            </Button>
                          )
                        })}
                      </>
                    )}
                  </Stack>
                </Stack>
              </CSSTransition>
              <CSSTransition
                in={true}
                classNames="login_transition"
                timeout={{ appear: 50 }}
                appear
              >
                {footerText &&
                  (typeof footerText === 'string' ? (
                    <Text fontSize="xs" color="subtle" textAlign="center">
                      {footerText}
                    </Text>
                  ) : (
                    footerText
                  ))}
              </CSSTransition>
            </Stack>
          </Container>
        </Flex>
      </Box>
    </>
  )
}
export default Login
