import React, { useEffect, useMemo, useState } from 'react'

import {
  Box,
  Flex,
  Divider,
  Spacer,
  keyframes,
  ScaleFade,
  useToast,
  Text,
  useColorModeValue,
  Spinner,
  Switch,
} from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '../../utils/reduxUtils'

import {
  setAudioPermissions,
  setSelectedVoice,
  updateMediaState,
} from '../../features/audio/actions'
import { WhiteContainer } from '../../components/WhiteContainer/whiteContainer'
import { IVoice } from '../../models/user/user'
import LiveSession from './liveSession'
import { AvatarWithPreload } from '../../components/AvatarWithPreload/avatarWithPreload'
import { CarouselComponent } from '../../components/Carousel/carousel'
import { useAsyncDebounce } from 'react-table'
import LiveSlider from './liveSlider'

export const newVoiceElements = () => ({
  voice_id: '',
  avatar_identifier: '',
  model_id: '',
  description: '',
  display_name: '',
  id: '',
  key: '',
  data_center_id: '',
  latency: 0,
})

const Live = ({ isIframe = false }: { isIframe?: boolean }) => {
  const [metadata, setMetadata] = useState({})
  const toast = useToast()
  const dispatch = useAppDispatch()
  const {
    areAudioPermissions,
    selectedVoice,
    selectedDevices,
    mediaState,
    deviceTest,
  } = useAppSelector((state) => state.audio)

  const data = useAppSelector((state) => state.user)
  const { metadata: sessionMetadata } = useAppSelector((state) => state.audioManagement)
  const { first_name, s3_models } = data

  const is_controlled = !!data.features.find(
    (feature) =>
      feature === 'is_controlled_by_live_session_api' ||
      feature === 'is_controlled_by_external_site',
  )
  const can_control_recording = !!data.features.find(
    (feature) => feature === 'can_control_recording',
  )
  const can_be_recorded = !!data.features.find(
    (feature) => feature === 'can_be_recorded',
  )
  const should_be_recorded = !!data.features.find(
    (feature) => feature === 'should_be_recorded',
  )

  useEffect(() => {
    if (Object.keys(metadata).length === 0) {
      setMetadata(sessionMetadata)
    }
  }, [sessionMetadata])

  const [canRecord, setCanRecord] = useState(should_be_recorded)

  const getMediaPrivileges = () => {
    return {
      shouldBeRecorded: should_be_recorded,
      canBeRecorded: can_be_recorded && canRecord,
      canJoinLiveCall: !!data.features.find(
        (feature) => feature === 'can_join_live_call',
      ),
    }
  }

  useEffect(() => {
    dispatch(updateMediaState({ ...getMediaPrivileges() }))
  }, [data, canRecord, can_be_recorded])

  useEffect(() => {
    {
      ;(async () => {
        navigator.mediaDevices
          .getUserMedia({ audio: true, video: false })
          .then(() => {
            dispatch(setAudioPermissions(true))
          })
          .catch((err) => {
            dispatch(setAudioPermissions(false))
            toast({
              title: 'Audio permissions needed',
              position: 'bottom-left',
              status: 'error',
              isClosable: false,
            })
          })
      })()
    }
  }, [])

  const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`
  const pulse = keyframes`
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255,0,0, 0.4);
    box-shadow: 0 0 0 0 rgba(255,0,0, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 5px rgba(255,0,0, 0);
      box-shadow: 0 0 0 5px rgba(255,0,0, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(255,0,0, 0);
      box-shadow: 0 0 0 0 rgba(255,0,0, 0);
  }
`

  const rippleInactive = keyframes`
  0% {
    box-shadow: 0 0 0 0px white, 0 0 0 0px #dadada;
  }
  100% {
    box-shadow: 0 0 0 58px white, 0 0 0px 59px #fcfcfc;
  }
  `

  const rippleActive = keyframes`
  0% {
    box-shadow: 0 0 0 0px #fcfcfc, 0 0 0 0px rgba(146, 96, 184, 0.5),
      0 0 0 0 #fcfcfc, 0 0 0 0 rgba(146, 96, 184, 0.8);
  }
  100% {
    box-shadow: 0 0 0 20px #fcfcfc, 0 0 0px 20px rgba(146, 96, 184, 0),
      0 0 0 56px #fcfcfc, 0 0 0 60px rgba(146, 96, 184, 0);
  }
`
  const bg = useColorModeValue('sm', 'sm-dark')
  const bgXl = useColorModeValue('md', 'md-dark')

  const [selectedUser, setSelectedUser] = useState({
    avatar: '',
    isActive: false,
  })

  const selectVoiceHandler = (voice: IVoice) => {
    if (mediaState.isUploading) {
      return
    }
    let newVoice = { ...voice }
    if (
      newVoice.voice_id === selectedVoice.voice?.voice_id &&
      newVoice.model_id === selectedVoice.voice?.model_id
    ) {
      newVoice = newVoiceElements()
    }
    dispatch(
      setSelectedVoice({
        voice: newVoice,
      }),
    )
  }

  const selectedName =
    selectedVoice.voice.display_name !== '' && !deviceTest.isOpen
      ? selectedVoice.voice.display_name
      : first_name

  const recorder = useMemo(() => {
    return (
      <Flex m="20px 0 40px 0" justifyContent="center">
        <Flex
          onClick={() => {
            if (is_controlled) {
              return
            }
            if (mediaState.isConnected) {
              const newVoice = newVoiceElements()
              dispatch(
                setSelectedVoice({
                  voice: newVoice,
                }),
              )
              return
            }
            setSelectedUser({
              ...selectedUser,
              isActive: selectedUser.avatar ? !selectedUser.isActive : false,
            })
          }}
          borderRadius="50%"
          position="relative"
          alignItems="center"
          flexDirection="column"
          zIndex={1}
          data-qa__id="user_avatar"
        >
          <Box
            w="230px"
            h="230px"
            zIndex="10"
            animation={`${spin} ${
              mediaState.isConnected && !deviceTest.isOpen ? '15s' : '30s'
            } infinite linear`}
            backgroundImage={
              !deviceTest.isOpen && mediaState.isConnected
                ? '/images/circle_colored.svg'
                : '/images/circle_not_colored.svg'
            }
            borderRadius="50%"
            data-qa__id="user_avatar_spinner"
          />

          {selectedName && selectedName.length > 0 && (
            <Box
              fontSize="60px"
              color="white"
              filter="grayscale(1)"
              position="absolute"
              top="30%"
              fontWeight="semibold"
              zIndex="15"
              data-qa__id="user_avatar_initial"
            >
              {selectedName[0].toUpperCase()}
            </Box>
          )}
          {selectedName && selectedName.length > 0 && (
            <Flex
              position="absolute"
              top="25px"
              borderRadius="50%"
              css={{
                animationIterationCount:
                  mediaState.isConnected && !deviceTest.isOpen ? 1 : 'infinite',
              }}
              animation={`${
                mediaState.isConnected && !deviceTest.isOpen
                  ? rippleActive
                  : rippleInactive
              } ${
                mediaState.isConnected && !deviceTest.isOpen ? '0.8s' : '0.9s'
              } linear`}
              zIndex="0"
            >
              <Flex
                bg={
                  mediaState.isConnected && !deviceTest.isOpen
                    ? 'rgb(146, 96, 184)'
                    : '#dadada'
                }
                borderRadius="50%"
                w="180px"
                h="180px"
                zIndex="0"
                textAlign="center"
                alignItems="center"
                justifyContent="center"
              >
                <Flex
                  fontWeight="400"
                  bg="white"
                  zIndex="120"
                  textAlign="center"
                  position="absolute"
                  bottom="0"
                  w="100%"
                  height="45px"
                  fontSize="16px"
                  justifyContent="center"
                  alignItems="center"
                  pb="0"
                  data-qa__id="user_avatar_name"
                >
                  <Box
                    maxW="110px"
                    textAlign="center"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {selectedName.length > 1
                      ? selectedName.charAt(0).toUpperCase() +
                        selectedName.slice(1)
                      : selectedName.charAt(0).toUpperCase()}
                  </Box>
                </Flex>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
    )
  }, [mediaState])
  const [information, setInformation] = useState(false)
  const debounce = useAsyncDebounce(() => setInformation(!information), 50)
  useEffect(() => {
    debounce()
  }, [
    mediaState.isUploading,
    mediaState.isStartingUpload,
    mediaState.isConnected,
    areAudioPermissions,
  ])

  const recorderInformation = useMemo(() => {
    return (
      areAudioPermissions &&
      can_be_recorded && (
        <Flex alignItems="center" justifyContent="center" my="2" gap="2">
          <>
            {mediaState.isConnected && !deviceTest.isOpen ? (
              <Flex gap="2" alignItems="center">
                {canRecord ? (
                  <>
                    <Text fontSize="sm" color="red">
                      Recording
                    </Text>
                    <Box
                      animation={`${pulse} 2s infinite`}
                      w="3"
                      bg="red"
                      h="3"
                      borderRadius="50%"
                    />
                  </>
                ) : (
                  <Text fontSize="sm" color="red">
                    Session is Active
                  </Text>
                )}
              </Flex>
            ) : can_control_recording && can_be_recorded ? (
              <Box>
                Enable recording?
                <Switch
                  isChecked={canRecord}
                  onChange={(event) => {
                    setCanRecord(event.target.checked)
                  }}
                  ml="2"
                  size="sm"
                />
              </Box>
            ) : (
              <Text fontSize="sm" color="muted">
                {should_be_recorded
                  ? 'All live sessions are recorded'
                  : "This session won't be recorded"}
              </Text>
            )}
          </>
        </Flex>
      )
    )
  }, [information, deviceTest, canRecord])

  const isUploading = mediaState.isUploading
  // prevent to refresh page when editing
  useEffect(() => {
    const unloadCallback = (event: any) => {
      if (isUploading) {
        event.preventDefault()
        event.returnValue =
          'Leaving this page will stop the current action, do you want to continue?'
        return 'Leaving this page will stop the current action, do you want to continue?'
      }
    }
    window.addEventListener('beforeunload', unloadCallback)
    return () => window.removeEventListener('beforeunload', unloadCallback)
  }, [isUploading])

  const metaData = useMemo(() => {
    return (
      <Box fontSize="0.7em">
        <pre
          style={{
            display: 'block',
            margin: '0',
            overflow: 'scroll',
          }}
        >
          {JSON.stringify(sessionMetadata, null, 2)}
        </pre>
      </Box>
    )
  }, [sessionMetadata])
  
  const [isLoadingPermissions, setIsLoadingPermissions] = useState(false)
  const [hasCheckedPermissions, setHasCheckedPermissions] = useState(false)
  useEffect(() => {
    if (
      !hasCheckedPermissions &&
      !isLoadingPermissions &&
      !areAudioPermissions
    ) {
      setIsLoadingPermissions(true)
      setHasCheckedPermissions(true)
      return
    }
    if (hasCheckedPermissions && isLoadingPermissions) {
      setIsLoadingPermissions(false)
    }
  }, [areAudioPermissions])

  interface NumberByString {
    [key: string]: number
  }

  const sortOrder: NumberByString = {
    soften: 1,
    shift: 2,
    transform: 3,
  }

  const carousel = ({
    canShowConnected,
    size,
    maxSliders,
    componentName,
    isIframe,
  }: {
    canShowConnected: boolean
    componentName?: string
    maxSliders: number
    isIframe?: boolean
    size: { max: string; w: string }
  }) =>
    areAudioPermissions ? (
      <Flex justifyContent="center" flexWrap="wrap" mt="5" gap="10">
        <CarouselComponent
          maxSliders={maxSliders}
          componentName={componentName}
          size={size}
          children={s3_models
            ?.reduce<IVoice[]>((prev, curr) => {
              return [...curr.voices, ...prev]
            }, [])
            .sort((a, b) => {
              const descriptionA = sortOrder[a.description] || 4
              const descriptionB = sortOrder[b.description] || 4
              if (descriptionA !== descriptionB) {
                return descriptionA - descriptionB
              }

              return a.display_name.localeCompare(b.display_name)
            })
            .map((voice, index) => {
              const {
                display_name,
                key,
                description,
                avatar_identifier,
                model_id,
              } = voice

              const model_parameters = data.s3_models.find(
                ({ model_id: id }) => id === model_id,
              )

              const parameterExist =
                model_parameters &&
                model_parameters?.parameters?.length > 1 &&
                data.can_change_parameter

              return (
                <Flex
                  key={key}
                  w="150px"
                  gridGap="5px"
                  bg={
                    display_name === selectedVoice.voice.display_name &&
                    mediaState.isConnected &&
                    canShowConnected
                      ? 'rgb(146, 96, 184)'
                      : 'bg-surface'
                  }
                  opacity={
                    (mediaState.isConnecting && canShowConnected) ||
                    (is_controlled && !mediaState.isConnected)
                      ? 0.5
                      : 1.0
                  }
                  cursor={
                    (mediaState.isConnecting && canShowConnected) ||
                    (display_name !== selectedVoice.voice.display_name &&
                      mediaState.isConnected &&
                      canShowConnected)
                      ? 'not-allowed'
                      : 'pointer'
                  }
                  filter={
                    mediaState.isConnecting ||
                    (display_name !== selectedVoice.voice.display_name &&
                      mediaState.isConnected &&
                      canShowConnected)
                      ? 'grayscale(100%)'
                      : 'grayscale(0%)'
                  }
                  transform={'translateZ(0)'}
                  boxShadow={bg}
                  transition="all .1s ease-out"
                  _hover={{
                    boxShadow: bgXl,
                    transform: 'scale(1.05)',
                  }}
                  borderRadius="lg"
                  m={5}
                  p={{ base: '5' }}
                  flexDirection="column"
                  justifyContent="center"
                  textAlign="center"
                  onClick={() => {
                    if (is_controlled) {
                      return
                    }
                    if (
                      key !== selectedVoice.voice.key &&
                      mediaState.isConnected &&
                      canShowConnected
                    ) {
                      return
                    }
                    if (!mediaState.isConnecting) {
                      selectVoiceHandler({
                        ...voice,
                      })
                    }
                  }}
                  data-qa__id={canShowConnected ? 'avatar_card' : ''}
                >
                  <AvatarWithPreload
                    alignSelf="center"
                    w={isIframe || parameterExist ? '50px' : '110px'}
                    h={isIframe || parameterExist ? '50px' : '110px'}
                    maxW={isIframe || parameterExist ? '50px' : '110px'}
                    maxH={isIframe || parameterExist ? '50px' : '110px'}
                    src={`/images/avatars/${avatar_identifier?.replace(
                      /(_.*)?$/,
                      '',
                    )}.svg`}
                    name={display_name}
                    data-qa__id={canShowConnected ? 'avatar_card_image' : ''}
                  />
                  <Text
                    mt="4"
                    fontSize="md"
                    color={
                      display_name === selectedVoice.voice.display_name &&
                      mediaState.isConnected &&
                      canShowConnected
                        ? 'white'
                        : 'auto'
                    }
                    fontWeight="semibold"
                    data-qa__id={canShowConnected ? 'avatar_card_name' : ''}
                  >
                    {display_name}
                  </Text>
                  {description && (
                    <Text
                      color={
                        description === 'soften'
                          ? 'rgb(22,175,204)'
                          : description === 'shift'
                          ? 'rgb(132,84,176)'
                          : 'rgb(254,145,9)'
                      }
                      fontSize="xs"
                      fontWeight="semibold"
                      data-qa__id={
                        canShowConnected ? 'avatar_card_description' : ''
                      }
                    >
                      {description}
                    </Text>
                  )}
                  {parameterExist && (
                    <>
                      <Spacer />
                      <Divider />
                      <LiveSlider
                        model_parameters={model_parameters}
                        voice={voice}
                      />
                    </>
                  )}
                </Flex>
              )
            })}
        />
      </Flex>
    ) : isLoadingPermissions ? (
      <Flex justifyContent="center" w="100%" alignItems="center">
        <Spinner size="xl" />
      </Flex>
    ) : (
      <Flex flex="1" w="100%" h="100%" justifyContent="center">
        <Text fontWeight="semibold" data-qa__id="live_permissions_missing">
          Audio permissions missing
        </Text>
      </Flex>
    )

  return (
    <ScaleFade initialScale={0.001} in={true}>
      <LiveSession
        canShowDeviceTest={!isIframe}
        carousel={carousel({
          size: { w: '60vw', max: '600px' },
          canShowConnected: isIframe ? false : deviceTest.isOpen,
          maxSliders: 3,
          componentName: 'deviceTest',
          isIframe,
        })}
      >
        {isIframe ? (
          <>
            <Box pt="2">{recorderInformation}</Box>
            {carousel({
              size: { w: '90vw', max: '900px' },
              canShowConnected: isIframe ? true : !deviceTest.isOpen,
              maxSliders: 4,
              isIframe,
            })}
          </>
        ) : (
          <WhiteContainer>
            <Flex flex="1" w="100%" flexDirection="column">
              <Box pt="5">
                {recorder}
                {recorderInformation}
              </Box>

              {carousel({
                size: { w: '90vw', max: '900px' },
                canShowConnected: isIframe ? true : !deviceTest.isOpen,
                maxSliders: 4,
                isIframe,
              })}
            </Flex>
          </WhiteContainer>
        )}
      </LiveSession>
    </ScaleFade>
  )
}

export default Live
