import * as authorization from './authorization'
import * as sessions from './sessions'
import * as session from './session'
import * as audioManagement from './audioManagement'
import * as annotations from './annotations'
import * as qualitySurvey from './qualitySurvey'
export const apiVersion = 'v1alpha1'
import * as user from './user'

export default {
  authorization,
  sessions,
  session,
  audioManagement,
  annotations,
  qualitySurvey,
  user,
}
