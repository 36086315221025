import { OpusEncoderCollection } from './opusEncoder'
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  intervalToDuration,
  toDate,
} from 'date-fns'
import { IUser } from '../models/user/user'
import { IMediaState } from '../models/audio/audio'

const DOMAIN = process.env.REACT_APP_DOMAIN
const PORT = process.env.REACT_APP_INFERENCE_PORT || 443

let writer: any
let reader: any
let pingReader: any
let pingWriter: any
let transport: any
let lastErrorHandler: (delayError: boolean) => void
let pingTransport: any

export const infer = async (
  audio: Float32Array,
  session_id: string,
  voice_id: string,
  model_id: string,
  sample_rate: number,
) => {
  if (writer) {
    const encoders = OpusEncoderCollection.getInstance()
    const encoder = encoders.getEncoder(
      session_id,
      voice_id,
      model_id,
      writer,
      lastErrorHandler,
    )
    encoder.encode(audio, sample_rate)
  }
}

export const closeInferenceServiceTransport = async () => {
  if (transport) {
    try {
      transport.close()
    } catch (e) {
      return
    }
  }

  transport = null
  reader = null
  writer = null
}

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))
const attemptConnection = async (url: string): Promise<any> => {
  const transport = new (window as any).WebTransport(url)

  try {
    await transport.ready
    return transport
  } catch (error) {
    if (error == 'WebTransportError: Opening handshake failed.') {
      return 429
    }

    return null
  }
}

const connectWithRetries = async (
  maxRetry: number,
  retryDelay: number,
  url: string,
): Promise<any> => {
  let retryCounter = 0
  let transport = null

  while (retryCounter < maxRetry) {
    transport = await attemptConnection(url)

    if (transport === 429) {
      retryCounter += 1
      console.log(`Retrying connection (${retryCounter}/${maxRetry})...`)
      await delay(retryDelay)
      continue
    }

    break
  }

  return transport
}

const connect = async ({
  url,
  errorHandler,
  closeHandler,
}: {
  url: string
  errorHandler: (delayError: boolean) => void
  closeHandler: () => void
}): Promise<{ reader: any; writer: any }> => {
  const maxRetries = 3
  const retryDelay = 100

  transport = await connectWithRetries(maxRetries, retryDelay, url)
  if (!transport || !transport.closed) {
    errorHandler(true)
    return { reader: null, writer: null }
  }

  transport.closed
    .then(() => {
      console.log('Connection closed')
      closeHandler()
    })
    .catch((error: any) => {
      errorHandler(false)
    })

  return transport
}

export const closePingServiceTransport = async () => {
  if (pingTransport) {
    try {
      pingTransport.close()
    } catch (e) {
      return
    }
  }

  pingTransport = null
  pingReader = null
  pingWriter = null
}

export const openPingServiceTransport = async (
  sessionId: string,
  custom_inference_url: string,
  dataCenterId: string,
  closeHandler: () => void,
  errorHandler: () => void,
) => {
  let url =
    custom_inference_url !== ''
      ? custom_inference_url.replace('/webtransport', '/ping') +
        `?session_id=${sessionId}`
      : process.env.REACT_APP_INFERENCE_URL
      ? process.env.REACT_APP_INFERENCE_URL
      : `https://inference-http-${dataCenterId}-${DOMAIN}:${PORT}/ping?session_id=${sessionId}`

  pingTransport = new (window as any).WebTransport(url)
  pingTransport.closed
    .then(() => {
      closeHandler()
    })
    .catch(() => {
      errorHandler()
    })
  await pingTransport.ready

  const { readable, writable } = await pingTransport.createBidirectionalStream()
  pingReader = readable.getReader()
  pingWriter = writable.getWriter()
  return { pingReader, pingWriter }
}

export const openInferenceServiceTransport = async (
  isOpenDeviceTest: boolean | undefined,
  metadata: { [key: string]: any },
  mediaState: IMediaState,
  custom_inference_url: string,
  dataCenterId: string,
  sessionId: string,
  voiceId: string,
  modelId: string,
  user_data: IUser,
  openHandler: (session_id: string) => void,
  closeHandler: () => void,
  errorHandler: (delayError: boolean) => void,
  voiceDisplayName?: string,
) => {
  let url =
    custom_inference_url !== ''
      ? custom_inference_url
      : process.env.REACT_APP_INFERENCE_URL
      ? process.env.REACT_APP_INFERENCE_URL
      : `https://inference-http-${dataCenterId}-${DOMAIN}:${PORT}/webtransport`

  const should_record = mediaState.canBeRecorded && mediaState.shouldBeRecorded

  const selected_avatar_identifier = user_data.s3_models
    .find(({ model_id }) => modelId === model_id)
    ?.voices.find(({ avatar_identifier }) => avatar_identifier === voiceId)
    ?.avatar_identifier

  const parameters: any = user_data.selected_parameters.find(
    ({ avatar_identifier }) => avatar_identifier === selected_avatar_identifier,
  )

  const webtransport_parameters = [
    `user_id=${user_data.meaning_user_id}`,
    `tenant_id=${user_data.tenant_id}`,
    `user_name=${user_data.user}`,
    `tenant_display_name=${user_data.tenant_display_name}`,
    `can_record=${mediaState.canBeRecorded}`,
    `session_id=${sessionId}`,
    `voice_id=${voiceId}`,
    `model_id=${modelId}`,
    `should_record=${should_record}`,
    `voice_display_name=${voiceDisplayName}`,
    `metadata=${JSON.stringify(
      isOpenDeviceTest ? { is_test: true } : metadata,
    )}`,
  ]

  if (
    parameters &&
    parameters.temperature >= 0 &&
    parameters.top_k >= 0 &&
    parameters.repetition_penalty >= 0
  ) {
    webtransport_parameters.push(`top_k=${parameters.top_k}`)
    webtransport_parameters.push(`temperature=${parameters.temperature}`)
    webtransport_parameters.push(
      `repetition_penalty=${parameters.repetition_penalty}`,
    )
  }

  url = `${url}?${webtransport_parameters.join('&')}`

  lastErrorHandler = errorHandler

  const transport: any = await connect({ url, errorHandler, closeHandler })
  if (!transport) {
    return { reader, writer }
  }

  try {
    const { readable, writable } = await transport.createBidirectionalStream()
    reader = readable.getReader()
    writer = writable.getWriter()
    openHandler(sessionId)
  } catch (error) {
    console.error(`Create Stream Error: ${error}`)
    errorHandler(false)
    return { reader, writer }
  }

  return { reader, writer }
}

export const getTime = ({
  duration,
  isOnlyMinutesAndHours,
  isOnlyMinutes,
}: {
  duration: number
  isOnlyMinutesAndHours?: boolean
  isOnlyMinutes?: boolean
}) => {
  const interval: any = intervalToDuration({ start: 0, end: duration })
  let time = ''

  if (isOnlyMinutes) {
    const start = toDate(0)
    const end = toDate(duration)

    const minutes = Math.abs(differenceInMinutes(end, start))
    return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`
  }

  if (isOnlyMinutesAndHours) {
    const start = toDate(0)
    const end = toDate(duration)

    const hours = Math.abs(differenceInHours(end, start))
    if (hours === 1) {
      time += `${hours} hour, `
    } else if (hours > 1 || hours === 0) {
      time += `${hours} hours, `
    }
    if (interval?.minutes === 1) {
      time += `${interval.minutes} minute`
    } else if (interval?.minutes > 1 || interval?.minutes === 0) {
      time += `${interval.minutes} minutes`
    }
    return time
  }

  if (interval) {
    if (interval?.days > 0) {
      const start = toDate(0)
      const end = toDate(duration)
      const days = Math.abs(differenceInDays(end, start))
      time += `${days}d:`
    }
    if (interval?.hours > 9) {
      time += `${interval.hours}h:`
    } else {
      time += `0${interval.hours}h:`
    }
    if (interval?.minutes > 9) {
      time += `${interval.minutes}m:`
    } else {
      time += `0${interval.minutes}m:`
    }
    if (interval?.seconds > 9) {
      time += `${interval.seconds}s`
    } else {
      time += `0${interval.seconds}s`
    }
  }
  return time
}
