import { Flex, FlexProps } from '@chakra-ui/react'
import { footerHeight, navHeight } from '../../utils/heightHelper'
import { ReactNode } from 'react'

export const WhiteContainer = ({
  children,
  props,
}: {
  children?: ReactNode
  props?: FlexProps
}) => {
  return (
    <Flex
      p={{ base: '0', sm: '6' }}
      minH={`calc(100vh - ${navHeight(document)} - ${footerHeight(
        document,
      )} - 40px)`}
      borderRadius="var(--chakra-radii-lg)"
      boxShadow="var(--chakra-shadows-sm)"
      bg="whiteAlpha.900"
      direction="column"
      w="full"
      h="100%"
      alignItems="center"
      pt="4"
      {...props}
    >
      {children}
    </Flex>
  )
}
