import { Avatar, Wrap, WrapItem } from '@chakra-ui/react'

import React from 'react'

export interface IVoiceSelector {
  voices: {
    src: string
    name: string
    size: string
  }[]
}

const VoiceSelector = ({ voices }: IVoiceSelector) => (
  <Wrap>
    {voices.map(({ name, src, size }) => (
      <WrapItem key={name}>
        <Avatar name={name} src={src} size={size} />
      </WrapItem>
    ))}
  </Wrap>
)

export default VoiceSelector
