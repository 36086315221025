import React from 'react'
import { Box, Flex, keyframes } from '@chakra-ui/react'

const drop = ({ maxTop, maxH }: { maxTop: string; maxH: string }) => keyframes`
        0% {
          top: 0;
          height: 0;
          opacity: 1;
        }
        50% {
          height: 100%;
          top: 0;
        }
        100% {
          height: ${maxH};
          top: ${maxTop};
        }
`

const logoElements = [
  {
    bg: '#976cbd',
    maxH: '15%',
    maxTop: '40%',
  },
  {
    bg: '#2dbcd6',
    maxH: '40%',
    maxTop: '25%',
  },
  {
    bg: '#f9324d',
    maxH: '45%',
    maxTop: '30%',
  },
  {
    bg: '#fca202',
    maxH: '38%',
    maxTop: '27%',
  },
  {
    bg: '#1a90d6',
    maxH: '15%',
    maxTop: '40%',
  },
]

export interface IDroppingLogo {
  maxAnimationHeight: number
  animationTime: number
}

const DroppingLogo = ({ maxAnimationHeight, animationTime }: IDroppingLogo) => {
  return (
    <Flex>
      {logoElements.map(({ bg, maxH, maxTop }, index) => (
        <Box
          ml="5px"
          w="10px"
          key={`logo_${index}_${bg}`}
          h={maxAnimationHeight}
          pos="relative"
        >
          <Box
            position="absolute"
            sx={{
              'animation-delay': `${index * animationTime * 0.2}s`,
              'animation-fill-mode': 'forwards',
            }}
            animation={`${drop({ maxTop, maxH })} ${animationTime}s linear`}
            borderRadius="10px"
            w="100%"
            top={maxTop}
            h={0}
            bg={bg}
          />
        </Box>
      ))}
    </Flex>
  )
}
export default DroppingLogo
