import { createAction } from '@reduxjs/toolkit'
import { ILiveSession } from '../../models/audioManagement/audioManagement'

export const setLiveManagementSession = createAction<ILiveSession>(
  'audioManagement/SET_LIVE_MANAGEMENT_SESSION',
)
export const setLiveSession = createAction<ILiveSession>(
  'audioManagement/SET_SESSION',
)
export const subscribeLiveSessions = createAction(
  'audioManagement/SUBSCRIBE_LIVE_SESSION',
)
export const updateGetter = createAction<boolean>(
  'audioManagement/UPDATE_GETTER',
)
export const setMetadata = createAction<any>('audioManagement/SET_METADATA')
