import React, { useEffect, useState } from 'react'
import {
  IAnnotationsElement,
  IAnnotationTypes,
} from '../../../models/annotations/annotations'
import { Box, Flex, Heading, IconButton, Text, VStack } from '@chakra-ui/react'
import { blueScroll } from '../../../utils/scroll'
import { FiPause, FiPlay } from 'react-icons/fi'
import { getTime } from '../../../utils/audioUtils'

export interface IPlayer {
  isPlaying: boolean
  time: number
  name: string
  id: string
}

export const emptyPlayer = {
  isPlaying: false,
  time: 0,
  name: '',
  id: '',
}

export const SessionAnnotationPanel = ({
  annotationTypes,
  annotations,
  progress,
  visibleAnnotationTypes,
  playAnnotation,
  player,
  isTranscriptionToolActive,
}: {
  annotations: IAnnotationsElement[]
  annotationTypes: IAnnotationTypes[]
  progress: { duration: number; progress: number }
  visibleAnnotationTypes: string[]
  playAnnotation: (annotation: IAnnotationsElement) => void
  player: {
    isPlaying: boolean
    time: number
    name: string
    id: string
  }
  isTranscriptionToolActive: boolean
}) => {
  const [height, setHeight] = useState(
    document.getElementById('tabs_grid_panels'),
  )
  useEffect(() => {
    setHeight(document.getElementById('tabs_grid_panels'))
  }, [isTranscriptionToolActive])
  const coloredAnnotation = annotations
    .map((annotation) => {
      const color = annotationTypes.find(
        ({ annotation_type_id }) =>
          annotation_type_id === annotation.annotation_type.annotation_type_id,
      )
      return {
        ...annotation,
        color: color ? color.color : '',
      }
    })
    .sort((first, second) => first.start - second.start)
  const time = progress.duration * progress.progress
  return (
    <>
      {annotations.length > 0 && height && height.offsetHeight && (
        <Box h={height ? height.offsetHeight - 40 : ''}>
          <Box display="block" h="calc(100% - 50px)" p="0">
            <Heading as="h4" size="xs" mb="10px">
              Annotations
            </Heading>
            <VStack
              h="100%"
              css={blueScroll}
              fontSize="13"
              overflow="auto"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              {coloredAnnotation.map((annotation, index) => {
                if (
                  visibleAnnotationTypes.length &&
                  !visibleAnnotationTypes.find(
                    (type) =>
                      type === annotation.annotation_type.annotation_type_id,
                  )
                ) {
                  return
                }

                const {
                  annotation_id,
                  description,
                  start,
                  end,
                  color,
                  annotation_type,
                } = annotation
                const isCurrent =
                  player.isPlaying && start <= time * 1000 && end > time * 1000

                return (
                  <Flex
                    key={`transcription_scroll_${annotation_id}`}
                    gap="2"
                    justifyContent="flex-start"
                  >
                    <IconButton
                      mt="0"
                      size="xs"
                      color={color}
                      icon={isCurrent ? <FiPause /> : <FiPlay />}
                      onClick={async () => playAnnotation(annotation)}
                      aria-label={`play_sprite_annotation_${description}`}
                    />
                    <Box>
                      <Box>
                        <Text fontWeight="semibold">
                          {annotation_type.annotation_type_name}
                        </Text>
                        {description && (
                          <Text whiteSpace="normal" wordBreak="break-word">
                            {description}
                          </Text>
                        )}
                      </Box>
                      <Flex gap="1">
                        <Text>
                          {getTime({ duration: start })} -{' '}
                          {getTime({ duration: end })}
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                )
              })}
            </VStack>
          </Box>
        </Box>
      )}
    </>
  )
}
