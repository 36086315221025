/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import { combineEpics } from 'redux-observable'
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators'
import { AppEpic } from '../../utils/reduxUtils'
import { saveQualitySurvey } from './actions'
import { concat, EMPTY, of } from 'rxjs'

export const SaveQualitySurvey: AppEpic<
  ReturnType<typeof saveQualitySurvey>
> = (action$, state$, { qualitySurvey }) =>
  action$.pipe(
    filter(saveQualitySurvey.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      return concat(
        qualitySurvey.saveQualitySurvey(action.payload.data).pipe(
          switchMap(() => {
            action.payload.toast({
              title: 'Feedback submitted successfully.',
              position: 'bottom-left',
              status: 'success',
              isClosable: false,
            })
            return EMPTY
          }),
          catchError(() => {
            action.payload.toast({
              title: 'Fail to submit feedback.',
              position: 'bottom-left',
              status: 'error',
              isClosable: false,
            })
            return EMPTY
          }),
        ),
      )
    }),
  )

export const qualitySurveyEpics = combineEpics(SaveQualitySurvey)
