/* eslint-disable guard-for-in */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
import { createSlice } from '@reduxjs/toolkit'
import { IQualitySurvey } from '../../models/qualitySurvey/qualitySurvey'

export const initialQualitySurvey: IQualitySurvey = {}

export const qualitySurveySlice = createSlice({
  name: 'qualitySurvey',
  initialState: initialQualitySurvey,
  reducers: {},
  extraReducers: (builder) => {},
})

export const qualitySurveyReducer = qualitySurveySlice.reducer
