import { Graphics } from '@pixi/graphics'
import { Sound } from '@pixi/sound'

interface RenderOptions {
  /**
   * Width of the render.
   * @default 512
   */
  width: number
  /**
   * Height of the render.
   * @default 128
   */
  height: number
  /**
   * Fill style for waveform.
   * @default 0x000000
   */
  fill: number
  isConversion: boolean
}

export interface IAudioBlock extends Graphics {
  options: RenderOptions
  setColor: (color?: number) => void
}

function normalizeValues(values: number[]): number[] {
  let maxAbsValue = 0
  for (const value of values) {
    const absValue = Math.abs(value)
    if (absValue > maxAbsValue) {
      maxAbsValue = absValue
    }
  }

  if (maxAbsValue === 0) return values
  const scalingFactor = 0.8 / maxAbsValue

  return values.map((value) => value * scalingFactor)
}

/**
 * Render image as Texture. **Only supported with WebAudio**
 * @memberof utils
 * @param sound - Instance of sound to render
 * @param options - Custom rendering options
 * @return Result texture
 */

function renderBlocksFromData(
  sound: Sound,
  options: RenderOptions,
  data: any[],
): Graphics[] {
  const blocks: Graphics[] = []
  const blockSize = 4
  const stepWidth = 80
  const totalTimestamp = data.length * stepWidth

  const step: number = Math.ceil((data.length * blockSize) / options.width)
  const amp: number = options.height / 2

  for (let i = 0; i < data.length / step; i += 1) {
    if (data[step * i] && data[step * i] && data[step * i + (step - 1)]) {
      const x = ((stepWidth * step * i) / totalTimestamp) * options.width
      const block = new Graphics() as IAudioBlock
      let max = 0
      let sum = 0
      for (let j = 0; j < step; j++) {
        sum += data[step * i + j]
      }
      max = (sum / step) * 0.8

      block.options = options
      const xOffset = 1

      block.setColor = (color?: number) => {
        block.clear()
        block.beginFill(color || 0xffffff)
        block.drawRect(
          x + xOffset,
          (1 - max) * amp,
          blockSize - xOffset,
          Math.max(0, max * 2 * amp),
        )
      }
      block.beginFill(0xffffff)
      block.drawRect(
        x + xOffset,
        (1 - max) * amp,
        blockSize - xOffset,
        Math.max(0, max * 2 * amp),
      )
      blocks.push(block)
    }
  }

  return blocks
}

function renderBlocksFromAudio(
  sound: Sound,
  options: RenderOptions,
): Graphics[] {
  const blocks: Graphics[] = []

  if (!sound.media) {
    return blocks
  }

  const media: any = sound.media

  // eslint-disable-next-line no-console
  console.assert(!!media.buffer, 'No buffer found, load first')

  const blockSize = 6
  const data = normalizeValues(media.buffer.getChannelData(0))
  const step: number = Math.ceil((data.length * blockSize) / options.width)
  const amp: number = options.height / 2
  for (let i = 0; i < options.width / blockSize; i += 1) {
    let sum = 0
    let max = 0
    let countedSteps = 0

    for (let j = 0; j < step; j++) {
      let datum: number = Math.min(1, Math.abs(data[i * step + j]))

      // We need to normalise across normal input and high-gain from the model
      if (!options.isConversion) {
        if (datum > 0.1) {
          sum += datum
          countedSteps += 1
        }
      } else {
        sum += datum
        countedSteps += 1
      }
    }

    max = sum / countedSteps

    const block = new Graphics() as IAudioBlock
    const xOffset = 2
    block.options = options
    block.setColor = (color?: number) => {
      block.clear()
      block.beginFill(color || 0xffffff)

      block.drawRect(
        i * blockSize + xOffset,
        (1 - max) * amp,
        blockSize - xOffset,
        Math.max(0, max * 2 * amp),
      )
    }
    block.beginFill(0xffffff)

    block.drawRect(
      blockSize,
      (1 - max) * amp,
      blockSize - xOffset,
      Math.max(0, max * 2 * amp),
    )

    blocks.push(block)
  }

  return blocks
}

function render(
  sound: Sound,
  options: RenderOptions,
  data: number[],
): Graphics[] {
  if (data && data.length > 0) {
    return renderBlocksFromData(sound, options, data)
  } else {
    return renderBlocksFromAudio(sound, options)
  }
}

export type { RenderOptions }
export { render }
