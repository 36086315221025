import React, {
  PropsWithChildren,
  lazy,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Container, Flex, Spinner, useMediaQuery } from '@chakra-ui/react'
import { Route } from 'react-router-dom'
import { Navbar } from './components/Navbar/navbar'
import { Footer } from './components/Footer/footer'
import Live from './pages/Live/live'
import { mobileBreakPoint } from './utils/mobileBreakPoint'
const Eula = lazy(() => import('./pages/Eula/eula'))

export const loader = (
  <Flex justifyContent="center" w="100%" h="100vh" alignItems="center">
    <Spinner size="xl" />
  </Flex>
)

enum ELEMENT_OPTIONS {
  MOBILE,
  DESKTOP,
  LOADING,
}

const ElementWithNavbar = ({
  children,
  isLogged,
}: { isLogged: boolean } & PropsWithChildren) => {
  const [isMobile] = useMediaQuery(mobileBreakPoint)
  const [isLoadingView, setIsLoadingView] = useState(true)

  useEffect(() => {
    setIsLoadingView(false)
  }, [])
  const elementOptions = {
    [ELEMENT_OPTIONS.DESKTOP]: () => (
      <Flex
        direction="column"
        justifyContent="space-between"
        h="100%"
        minH="100vh"
        background="var(--chakra-colors-gray-50)"
        css={{
          '.chakra-offset-slide': {
            height: '100%',
            flex: 1,
            alignSelf: 'stretch',
          },
        }}
      >
        <Navbar />
        <Container
          p={{ base: 1, md: 4 }}
          flex="1"
          maxW="100%"
          display="flex"
          alignItems="center"
          bg="var(--chakra-colors-bg-canvas)"
        >
          {children}
        </Container>

        <Footer isLogged={isLogged} />
      </Flex>
    ),
    [ELEMENT_OPTIONS.MOBILE]: () => (
      <Flex
        direction="column"
        justifyContent="space-between"
        h="100%"
        minH="100vh"
        css={{
          '.chakra-offset-slide': {
            height: '100%',
            flex: 1,
            alignSelf: 'stretch',
          },
        }}
      >
        <Navbar />
        <Container
          p={0}
          flex="1"
          maxW="100%"
          display="flex"
          alignItems="center"
          bg="var(--chakra-colors-bg-canvas)"
        >
          {children}
        </Container>

        <Footer isLogged={isLogged} />
      </Flex>
    ),
    [ELEMENT_OPTIONS.LOADING]: () => (
      <Flex alignItems="center" h="100%">
        {loader}
      </Flex>
    ),
  }

  const elementType = useMemo(() => {
    if (isLoadingView) {
      return ELEMENT_OPTIONS.LOADING
    }
    if (isMobile) {
      return ELEMENT_OPTIONS.MOBILE
    }
    return ELEMENT_OPTIONS.DESKTOP
  }, [isMobile, isLoadingView])

  return elementOptions[elementType]()
}

const PrivateRoute = ({
  isLogged,
  loading,
  path,
  element,
  key,
  isMobile,
  is_eula_accepted,
}: any) => {
  return loading ? (
    <React.Fragment key={`loading_${key}`}>
      <Route path={path} element={loader} />
    </React.Fragment>
  ) : (
    isLogged &&
      (is_eula_accepted ? (
        <React.Fragment key={key}>
          <Route
            path={path}
            element={
              <ElementWithNavbar isLogged={isLogged}>
                {!isMobile ? element : <Live />}
              </ElementWithNavbar>
            }
          />
        </React.Fragment>
      ) : (
        <React.Fragment key={key}>
          <Route
            path="*"
            element={
              <ElementWithNavbar isLogged={isLogged}>
                <Eula />
              </ElementWithNavbar>
            }
          />
        </React.Fragment>
      ))
  )
}

export default PrivateRoute
