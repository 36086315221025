import { Flex, Text } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from '../../utils/reduxUtils'

export const Footer = ({ isLogged, ...props }: any) => {
  const location = useLocation()
  const user = useAppSelector((state) => state.user)

  return (
    <Flex
      w="100%"
      as="footer"
      id="footer"
      color={isLogged && location?.pathname !== '/terms' ? 'subtle' : 'white'}
      {...props}
      py={{ base: '2', md: '3' }}
      flexDirection="column"
    >
      <Text
        display="flex"
        w="100%"
        justifyContent="center"
        fontSize="sm"
        text-align="center"
      >
        &copy; All Rights Reserved Meaning Team Inc | {new Date().getFullYear()}{' '}
        | v{process.env.REACT_APP_VERSION}
      </Text>

      <Text
        display="flex"
        w="100%"
        justifyContent="center"
        fontSize="10px"
        mt="3px"
        text-align="center"
      >
        {user.tabFingerprint}/{user.browserFingerprint}
      </Text>
    </Flex>
  )
}
