import { ajax } from 'rxjs/ajax'
import { apiVersion } from '..'
import { ISessionRequest } from '../../models/sessions/sessions'

export const query = (payload?: {
  url?: string | null
  body?: ISessionRequest
}) => {
  const url =
    payload?.url ||
    `${process.env.REACT_APP_API_URL}/api/${apiVersion}/search/sessions`
  const body = payload?.body || {}

  return ajax({
    url: url,
    method: 'POST',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  })
}
