import React from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tab,
  TabList,
  Text,
  HStack,
} from '@chakra-ui/react'
import { ISessionDetails } from '../../../models/session/session'
import { IAudios } from '../../../models/waveAudio/waveAudio'
import { sound } from '@pixi/sound'
import { IPlayer } from '../sessionAnnotationPanels/sessionAnnotationPanel'
import { Application } from 'pixi.js'
import { play } from '../session.utils'
import { components, Select } from 'chakra-react-select'
import { UpDownIcon } from '@chakra-ui/icons'

const { Option } = components

export const SessionVoicesControl = ({
  data,
  embedded,
  audios,
  player,
  setPlayer,
  setSelectedAudio,
  application,
  progress,
  renderProgress,
  voicesList,
  setVoice,
  flags,
  selectedIndex,
}: {
  data?: ISessionDetails
  embedded: boolean
  audios: IAudios[]
  player: IPlayer
  setPlayer: React.Dispatch<React.SetStateAction<IPlayer>>
  setSelectedAudio: React.Dispatch<React.SetStateAction<IAudios | null>>
  application: Application | null
  progress: { progress: number; duration: number }
  renderProgress: (progress: number, duration: number) => void
  voicesList?: string[]
  setVoice?: (index: number) => void
  flags?: { [id: string]: string }
  selectedIndex?: number
}) => {
  const changeAudio = (audio: IAudios) => {
    setSelectedAudio(audio)
    if (application) {
      application.stage.children.map((el: any) => {
        if (el.options) {
          if (el.isSprite) {
            el.tint = '0xe2e8f0'
          }
          if (el.name === audio.id) {
            el.tint = audio.isConversion ? '0x986cbd' : '0x1b91d6'
          }
        }
      })
    }
    play({
      name: audio.name,
      id: audio.id,
      startAt: progress.progress * progress.duration,
      data,
      renderProgress,
      setPlayer,
      player,
    })
  }

  return (
    <Flex
      display={{ base: 'block', md: 'flex' }}
      alignItems="center"
      ml="24px"
      gap="2"
      flexWrap={{ base: 'wrap', md: 'nowrap' }}
    >
      {voicesList && setVoice && selectedIndex != null && (
        <Flex
          display={{ base: 'block', md: 'flex' }}
          justifyContent="center"
          alignItems="center"
        >
          <HStack>
            <Text fontWeight="500">Select Sample:</Text>
            <Menu>
              <MenuButton
                bg="white"
                h="30px"
                as={Button}
                rightIcon={<UpDownIcon mt="0.5" w="2.5" />}
              >
                <Flex gap="4">
                  {flags ? (
                    <Box
                      as="span"
                      borderRadius="2px"
                      className={`fi fi-${
                        flags[voicesList[selectedIndex || 0]]
                      }`}
                    ></Box>
                  ) : null}
                  <Text color="#242424">{voicesList[selectedIndex || 0]}</Text>
                </Flex>
              </MenuButton>
              <MenuList p="0">
                {voicesList.map((sample, i) => (
                  <MenuItem
                    key={i}
                    onClick={() => setVoice(i)}
                    color="#424242"
                    display="flex"
                  >
                    {flags ? (
                      <Box
                        as="span"
                        borderRadius="2px"
                        mr="5px"
                        className={`fi fi-${flags[sample]}`}
                      ></Box>
                    ) : null}
                    <Text color="#242424">{sample}</Text>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </HStack>
        </Flex>
      )}

      <TabList
        borderRadius="md"
        alignItems="center"
        px={{ base: 0, md: embedded ? '2' : '0' }}
        bg="white"
        gap="2"
        border="none"
        h="30px"
        overflowX="auto"
        maxW={{ base: '350px', md: '100%' }}
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        }}
      >
        {audios.map(({ id, isConversion, name }, index) => {
          const selectedAudio = audios.find(({ id: audioId }) => audioId === id)
          return (
            <React.Fragment key={`voice_tab_${id}_${name}`}>
              {index !== 0 && audios.length > 2 && selectedAudio?.id === id && (
                <Divider mt="2px" h="10px" mx="1" orientation="vertical" />
              )}
              <Tab
                key={`Tab_${id}`}
                h={{ base: '20px', md: '24px' }}
                borderRadius={{ base: 'none', md: 'md' }}
                borderBottom={{ base: '2px solid', md: 'none' }}
                borderColor="transparent"
                _selected={{
                  color: { base: 'initial', md: 'white' },
                  borderColor: isConversion ? '#986cbd' : '#1b91d6',
                  bg: {
                    base: 'transparent',
                    md: isConversion ? '#986cbd' : '#1b91d6',
                  },
                }}
                px={{ base: 0, md: 2 }}
                onClick={() => {
                  if (selectedAudio) {
                    changeAudio(selectedAudio)
                    if (!player.isPlaying) {
                      sound.stopAll()
                    }
                    setPlayer({
                      ...player,
                      name: '',
                      id: '',
                    })
                  }
                }}
              >
                {isConversion ? name : 'Original'}
              </Tab>
            </React.Fragment>
          )
        })}
      </TabList>
    </Flex>
  )
}
