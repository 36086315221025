import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiVersion } from '../../services'
import { ISessionDetails } from '../../models/session/session'

const apiUrl = process.env.REACT_APP_API_URL + `/api/${apiVersion}`
export const sessionApi = createApi({
  reducerPath: 'sessionApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
    prepareHeaders(headers, { getState }) {
      return headers
    },
    credentials: 'include',
  }),
  endpoints: (builder) => ({
    getUserDetails: builder.query<
      ISessionDetails,
      { id: string | undefined; withTranscription: boolean }
    >({
      query: ({
        id,
        withTranscription,
      }: {
        id: string | undefined
        withTranscription: boolean
      }) => ({
        url: `/sessions/${id}/${
          withTranscription ? '/?transcription=true' : ''
        }`,
        method: 'GET',
      }),
    }),

    // Adding a mutation to start transcription
    startTranscription: builder.mutation<
      { detail: string },
      { sessionId: string }
    >({
      query: ({ sessionId }) => ({
        url: `/generate_transcription`,
        method: 'POST',
        body: { session_id: sessionId },
      }),
    }),
  }),
})

export const { useGetUserDetailsQuery, useStartTranscriptionMutation } =
  sessionApi
