import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const apiUrl = process.env.REACT_APP_API_URL
export const iframeLoginApi = createApi({
  reducerPath: 'iframeLoginApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
    prepareHeaders(headers, { getState }) {
      return headers
    },
    credentials: 'include',
  }),
  endpoints: (builder) => ({
    iframeLogin: builder.mutation<
      any,
      {
        tenant_id: string
        agent_id: string
        first_name: string
        last_name: string
      }
    >({
      query: ({
        tenant_id,
        first_name,
        last_name,
        agent_id,
      }: {
        tenant_id: string
        agent_id: string
        first_name: string
        last_name: string
      }) => ({
        url: `/authorization/iframe_login`,
        method: 'POST',
        body: {
          tenant_id,
          first_name,
          last_name,
          agent_id,
        },
      }),
    }),
  }),
})

export const { useIframeLoginMutation } = iframeLoginApi
