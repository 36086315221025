import { extendTheme } from '@chakra-ui/react'
import { theme } from '@chakra-ui/pro-theme'

const brandColor = {
  50: '#d1e9f7',
  100: '#76bde6',
  200: '#5fb2e2',
  300: '#49a7de',
  400: '#329cda',
  500: '#1b91d6',
  600: '#1883c1',
  700: '#1674ab',
  800: '#136696',
  900: '#105780',
}

const brandVioletColor = {
  50: '#F3EDF7',
  100: '#DCCEE9',
  200: '#C6AEDB',
  300: '#B08ECC',
  400: '#9A6FBE',
  500: '#844FB0',
  600: '#693F8D',
  700: '#4F2F6A',
  800: '#352046',
  900: '#1A1023',
}

const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-30px)',
  color: brandColor['500'],
  padding: 0,
}

export const myTheme = extendTheme(
  {
    colors: {
      ...theme.colors,
      brand: brandColor,
      brandViolet: brandVioletColor,
    },
    components: {
      ...theme.components,
      Form: {
        variants: {
          floating: {
            container: {
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              _focusWithin: {
                label: {
                  ...activeLabelStyles,
                },
              },
              'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
                {
                  ...activeLabelStyles,
                },
              label: {
                top: 0,
                left: 0,
                zIndex: 2,
                position: 'absolute',
                backgroundColor: 'white',
                pointerEvents: 'none',
                mx: 3,
                px: 1,
                my: 2,
                transformOrigin: 0,
                color: 'rgb(101,103,105)',
                fontSize: '16px',
                lineHeight: '33px',
                padding: '1px 6px',
                transitionProperty: 'transform, top, padding',
                transition:
                  'transform .15s ease-in-out,top .15s ease-in-out,padding .15s ease-in-out',
              },
              input: {
                height: '52px',
                width: '100%',
                padding: '0 16px',
                _autofill: {
                  backround: 'white !important',
                  _focus: {
                    backround: 'white !important',
                    boxShadow: 'none !important',
                  },
                },
                _focus: {
                  backround: 'white !important',
                  boxShadow: 'none !important',
                },
              },
            },
          },
        },
      },
      Input: {
        ...theme.components.Input,
        baseStyle: {
          ...theme.components.Input.baseStyle,
          field: {
            ...theme.components.Input.baseStyle?.field,
            _placeholder: {
              color: '#a0aec0',
            },
          },
        },
      },
      NumberInput: {
        ...theme.components.Input,
        baseStyle: {
          ...theme.components.Input.baseStyle,
          field: {
            ...theme.components.Input.baseStyle?.field,
            _placeholder: {
              color: '#a0aec0',
            },
          },
        },
      },
      Textarea: {
        ...theme.components.Textarea,
        baseStyle: {
          ...theme.components.Textarea.baseStyle,
          _placeholder: {
            color: '#a0aec0',
          },
        },
      },
      Drawer: {
        variants: {
          alwaysOpen: {
            parts: ['dialog, dialogContainer'],
            dialog: {
              pointerEvents: 'auto',
            },
            dialogContainer: {
              pointerEvents: 'none',
            },
          },
        },
      },
    },
  },
  theme,
)
