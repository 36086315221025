import { ajax } from 'rxjs/ajax'
import { apiVersion } from '../index'

export const getAnnotationTypes = ({ sessionId }: { sessionId: string }) => {
  return ajax({
    url: `${process.env.REACT_APP_API_URL}/api/${apiVersion}/annotations/${sessionId}/types/`,
    method: 'GET',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const saveNewAnnotationType = ({
  sessionId,
  typeName,
  typeDescription,
}: {
  sessionId: string
  typeName: string
  typeDescription: string
}) => {
  return ajax({
    url: `${process.env.REACT_APP_API_URL}/api/${apiVersion}/annotations/${sessionId}/types/`,
    method: 'POST',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
    body: {
      data: {
        annotation_type_name: typeName,
        description: typeDescription,
      },
    },
  })
}
export const saveNewAnnotation = ({
  sessionId,
  start,
  end,
  is_published,
  description,
  annotation_type_id,
}: {
  sessionId: string
  start: number
  end: number
  description: string
  is_published: boolean
  annotation_type_id: string
}) => {
  return ajax({
    url: `${process.env.REACT_APP_API_URL}/api/${apiVersion}/annotations/${sessionId}/`,
    method: 'POST',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
    body: {
      data: {
        start,
        end,
        is_published,
        description,
        annotation_type_id,
      },
    },
  })
}

export const updateAnnotation = ({
  sessionId,
  annotationId,
  description,
  start,
  end,
  selectedTypeId,
}: {
  sessionId: string
  start: number
  end: number
  annotationId: string
  description: string
  selectedTypeId?: string
}) => {
  let data: any = {
    start,
    end,
    description,
  }

  if (selectedTypeId) {
    data['annotation_type_id'] = selectedTypeId
  }

  return ajax({
    url: `${process.env.REACT_APP_API_URL}/api/${apiVersion}/annotations/${sessionId}/annotation/${annotationId}/`,
    method: 'POST',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
    body: {
      data,
    },
  })
}

export const removeAnnotationType = ({
  annotation_type_id,
  sessionId,
}: {
  annotation_type_id: string
  sessionId: string
}) => {
  return ajax({
    url: `${process.env.REACT_APP_API_URL}/api/${apiVersion}/annotations/${sessionId}/types/${annotation_type_id}/`,
    method: 'DELETE',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
export const removeAnnotation = ({
  sessionId,
  annotationId,
}: {
  sessionId: string
  annotationId: string
}) => {
  return ajax({
    url: `${process.env.REACT_APP_API_URL}/api/${apiVersion}/annotations/${sessionId}/annotation/${annotationId}/`,
    method: 'DELETE',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
