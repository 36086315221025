export function lightenColor(
  hexColor: number | string,
  amount: number,
): number {
  // Ensure amount is in [0, 1] range
  amount = Math.max(0, Math.min(1, amount))
  hexColor = +hexColor

  // Convert hex color to RGB format
  let r = (hexColor >> 16) & 0xff
  let g = (hexColor >> 8) & 0xff
  let b = hexColor & 0xff

  // Lighten each color component
  r = Math.floor(r + (255 - r) * amount)
  g = Math.floor(g + (255 - g) * amount)
  b = Math.floor(b + (255 - b) * amount)

  // Convert back to hex format and return
  return (r << 16) + (g << 8) + b
}

export const colors = [
  'rgb(76, 76, 109)',
  'rgb(27, 156, 133)',
  'rgb(85, 85, 255)',
  'rgb(29, 38, 125)',
  'rgb(92, 70, 156)',
  'rgb(255, 85, 85)',
  'rgb(229, 145, 11)',
  'rgb(153, 85, 255)',
  'rgb(221, 85, 255)',
  'rgb(255, 85, 153)',
  'rgb(85, 85, 255)',
  'rgb(27, 145, 214)',
  'rgb(139, 172, 170)',
  'rgb(176, 71, 89)',
  'rgb(255, 51, 77)',
  'rgb(152, 108, 189)',
  'rgb(0, 188, 214)',
  'rgb(0, 144, 214)',
  'rgb(255, 162, 0)',
  'rgb(27, 145, 214)',
  'rgb(76, 76, 109)',
  'rgb(27, 156, 133)',
  'rgb(85, 85, 255)',
  'rgb(29, 38, 125)',
  'rgb(92, 70, 156)',
  'rgb(255, 85, 85)',
  'rgb(229, 145, 11)',
  'rgb(153, 85, 255)',
  'rgb(221, 85, 255)',
  'rgb(255, 85, 153)',
  'rgb(85, 85, 255)',
  'rgb(27, 145, 214)',
  'rgb(139, 172, 170)',
  'rgb(176, 71, 89)',
  'rgb(255, 51, 77)',
  'rgb(152, 108, 189)',
  'rgb(0, 188, 214)',
  'rgb(0, 144, 214)',
  'rgb(255, 162, 0)',
  'rgba(155, 2, 17, 1)',
  'rgba(5, 71, 109, 1)',
  'rgba(2, 62, 2, 1)',
  'rgba(71, 99, 116, 1)',
  'rgba(110, 4, 89, 1)',
]

export const annotationsColors = [
  'rgb(85, 85, 255)',
  'rgb(255, 162, 0)',
  'rgb(27, 156, 133)',
  'rgb(221, 85, 255)',
  'rgb(229, 145, 11)',
  'rgb(139, 172, 170)',
  'rgba(110, 4, 89, 1)',
  'rgb(27, 145, 214)',
]

export const rgbToRgba = (rgb: string, alpha: number) => {
  const matches = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)

  if (!matches) {
    throw new Error('Invalid RGB color format')
  }

  const [, red, green, blue] = matches

  return `rgba(${red}, ${green}, ${blue}, ${alpha})`
}

export const rgbToHex = (rgb: string) => {
  // @ts-ignore
  let [r, g, b] = rgb.match(/\d+/g)

  r = parseInt(r).toString(16).padStart(2, '0')
  g = parseInt(g).toString(16).padStart(2, '0')
  b = parseInt(b).toString(16).padStart(2, '0')

  return `0x${r}${g}${b}`
}
