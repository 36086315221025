import { createAction } from '@reduxjs/toolkit'
import {
  IAvailableDevicesState,
  IMediaState,
  ISelectedDevicesState,
} from '../../models/audio/audio'
import { IVoice } from '../../models/user/user'

export const setAudioPermissions = createAction<boolean>(
  'audio/CHECK_PERMISSIONS',
)

export const setSelectedDevices = createAction<ISelectedDevicesState>(
  'audio/SET_SELECTED_DEVICES',
)
export const setAvailableDevices = createAction<IAvailableDevicesState>(
  'audio/SET_AVAILABLE_DEVICES',
)

export const setSelectedVoice = createAction<{
  voice: IVoice
}>('audio/SET_SELECTED_VOICE')

export const updateMediaState = createAction<Partial<IMediaState>>(
  'audio/UPDATE_MEDIA_STATE',
)

export const updateDeviceTest = createAction<{ isOpen: boolean }>(
  'audio/UPDATE_DEVICE_TEST',
)
export const setDeviceTest = createAction('audio/SET_DEVICE_TEST')
