import React, { useMemo, useState } from 'react'
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  CloseButton,
  ComponentWithAs,
  Flex,
  Icon,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { CheckIcon, DeleteIcon, IconProps } from '@chakra-ui/icons'
import { FiInfo } from 'react-icons/fi'
import { IconType } from 'react-icons'

enum IOptionVarints {
  error = 'error',
  warning = 'warning',
  success = 'success',
}

type IVariants = {
  [key in IOptionVarints]: {
    icon: IconType | ComponentWithAs<'svg', IconProps>
    color: string
  }
}

const variants: IVariants = {
  error: {
    icon: DeleteIcon,
    color: 'red.500',
  },
  warning: {
    icon: FiInfo,
    color: 'brand.500',
  },
  success: {
    icon: CheckIcon,
    color: 'green.500',
  },
}

export interface INotification {
  variant: IOptionVarints
  headerText: string
  subHeader?: string
  onClose?: () => void
  confirmButtonText?: string
  onDiscardButtonClick?: () => void
  discardButtonText?: string
  onConfirmButtonClick?: () => void
}

const Notification = ({
  variant,
  subHeader,
  headerText,
  onClose,
  confirmButtonText,
  onConfirmButtonClick,
  discardButtonText,
  onDiscardButtonClick,
}: INotification) => {
  const selectedVariant = variants[variant]
  return (
    <Box
      as="section"
      pt={{ base: '4', md: '8' }}
      pb={{ base: '12', md: '24' }}
      px={{ base: '4', md: '8' }}
    >
      <Flex direction="row-reverse">
        <Flex
          direction={{ base: 'column', sm: 'row' }}
          width={{ base: 'full', sm: 'md' }}
          boxShadow={useColorModeValue('md', 'md-dark')}
          bg="bg-surface"
          borderRadius="lg"
          overflow="hidden"
        >
          <Center
            display={{ base: 'none', sm: 'flex' }}
            bg={selectedVariant.color}
            px="5"
          >
            <Icon as={selectedVariant.icon} boxSize="10" color="on-accent" />
          </Center>
          <Stack direction="row" p="4" spacing="3" flex="1">
            <Stack spacing="2.5" flex="1">
              <Stack spacing="1">
                <Text fontSize="sm" fontWeight="medium">
                  {headerText}
                </Text>
                <Text fontSize="sm" color="muted">
                  {subHeader}
                </Text>
              </Stack>
              <ButtonGroup variant="link" size="sm" spacing="3">
                {onDiscardButtonClick && discardButtonText && (
                  <Button colorScheme="blue" onClick={onDiscardButtonClick}>
                    {discardButtonText}
                  </Button>
                )}

                {confirmButtonText && onConfirmButtonClick && (
                  <Button onClick={onConfirmButtonClick}>
                    {confirmButtonText}
                  </Button>
                )}
              </ButtonGroup>
            </Stack>
            {onClose && (
              <CloseButton onClick={onClose} transform="translateY(-6px)" />
            )}
          </Stack>
        </Flex>
      </Flex>
    </Box>
  )
}
export default Notification
