import React from 'react'
import { Box, Flex, Grid, Text } from '@chakra-ui/react'
import NavigationTooltip from '../../../components/NavigationTooltip/navigationTooltip'
import { footerHeight, navHeight } from '../../../utils/heightHelper'
import { ISessionDetails } from '../../../models/session/session'
import { sessionMetadata } from '../session.utils'

export const SessionHeader = ({ data }: { data?: ISessionDetails }) => {
  return (
    <>
      <Flex w="100%" gap="5">
        <NavigationTooltip
          refreshCondition={false}
          tooltipInfo="Show All Sessions"
          path="/studio"
          rightText={
            <Flex
              cursor="pointer"
              alignItems="baseline"
              justifyContent="flex-end"
            >
              <Grid gridTemplateColumns="1fr 2fr" w="100%" gap="5">
                <Flex>
                  <Text>{data?.name}</Text>
                </Flex>
                <Flex justifyContent="flex-end">
                  {sessionMetadata(data, sessionMetadata)}
                </Flex>
              </Grid>
            </Flex>
          }
        />
      </Flex>
      <Flex
        flex="1"
        flexDirection="column"
        h="100%"
        maxH={`calc(100vh - ${navHeight(document)} - ${footerHeight(
          document,
        )} - 120px)`}
        overflow="hidden"
        justifyContent="space-between"
        ml="-4px"
      >
        <Box h="100%"></Box>
      </Flex>
    </>
  )
}
