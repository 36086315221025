/* eslint-disable guard-for-in */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
import { createSlice } from '@reduxjs/toolkit'
import { ISession } from '../../models/session/session'
import {
  clearSessionReduxData,
  isRemovingConversions,
  setIsLoadingSession,
  setIsSavingSession,
  setIsUpdatingConversions,
  setIsUpdatingSession,
  setSession,
  setSavingOneConversion,
  setIsRemovingSession,
} from './actions'

export interface ISessionSliceState extends ISession {
  changeTime: number
}

export const initialSession: ISessionSliceState = {
  session: {},
  isUpdatingConversions: [],
  isRemovingConversions: [],
  isLoadingSession: false,
  isUpdatingSession: false,
  isLoadingConversions: false,
  isRemovingSession: false,
  isSavingSession: false,
  isSavingOneConversion: false,
  changeTime: new Date().getTime(),
}

export const sessionSlice = createSlice({
  name: 'session',
  initialState: initialSession,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(setSession.match, (state, { payload }) => {
      state.session = payload.session
    })
    builder.addMatcher(setSavingOneConversion.match, (state, { payload }) => {
      state.isSavingOneConversion = payload
    })
    builder.addMatcher(isRemovingConversions.match, (state, { payload }) => {
      state.isRemovingConversions = payload
    })
    builder.addMatcher(setIsSavingSession.match, (state, { payload }) => {
      state.isSavingSession = payload
    })
    builder.addMatcher(setIsLoadingSession.match, (state, { payload }) => {
      state.isLoadingSession = payload
    })
    builder.addMatcher(setIsRemovingSession.match, (state, { payload }) => {
      state.isRemovingSession = payload
    })
    builder.addMatcher(setIsUpdatingConversions.match, (state, { payload }) => {
      state.isUpdatingConversions = payload
    })
    builder.addMatcher(setIsUpdatingSession.match, (state, { payload }) => {
      state.isUpdatingSession = payload
    })
    builder.addMatcher(clearSessionReduxData.match, (state, { payload }) => {
      for (const [key, value] of Object.entries(initialSession)) {
        const typedKey = key as keyof typeof initialSession
        if (typedKey !== 'changeTime') {
          state[typedKey] = value
        }
      }

      state.changeTime = payload
    })
  },
})

export const sessionReducer = sessionSlice.reducer
