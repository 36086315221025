import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { AnimatedLogo } from '../../storybook/components'
import { Footer } from '../../components/Footer/footer'
import { useAppSelector } from '../../utils/reduxUtils'

export const PageNotFound = () => {
  const { isLogged } = useAppSelector((state) => state.authorization)

  return (
    <Flex
      bg="gray.100"
      flexDirection="column"
      height="100vh"
      alignItems="center"
    >
      <AnimatedLogo maxAnimationHeight="400px" isLoop />
      <Box fontSize="32px">Meaning</Box>
      <Flex textAlign="center" fontSize="42px" color="brand.500">
        [404] - PAGE NOT FOUND
      </Flex>
      <Flex fontSize="20px">
        Click
        <Text
          m="0 5px"
          color="rgb(255, 162, 0)"
          _hover={{ color: 'brand.500' }}
        >
          <Link to="/">here</Link>
        </Text>
        to return to Homepage
      </Flex>
      <Footer isLogged={isLogged} pos="absolute" bottom="0" />
    </Flex>
  )
}
