/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import { combineEpics } from 'redux-observable'
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators'
import { AppEpic } from '../../utils/reduxUtils'
import { concat, EMPTY, of } from 'rxjs'
import {
  acceptEULA,
  onSetTimeUnit,
  setEULAAccept,
  setIsAcceptingEULA,
  setSelectedParameters,
  setUserData,
  setUserVoiceParameter,
} from './actions'

export const SetTimeUnit: AppEpic<ReturnType<typeof onSetTimeUnit>> = (
  action$,
  state$,
  { user },
) =>
  action$.pipe(
    filter(onSetTimeUnit.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      localStorage.setItem('timeUnit', action.payload.timeUnit)
      return concat(
        of(
          setUserData({
            ...state.user,
            time_unit: action.payload.timeUnit,
          }),
        ),
      )
    }),
  )

export const AcceptEula: AppEpic<ReturnType<typeof acceptEULA>> = (
  action$,
  state$,
  { user },
) =>
  action$.pipe(
    filter(acceptEULA.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      return concat(
        of(setIsAcceptingEULA(true)),
        user.acceptEulaService(state.user.meaning_user_id).pipe(
          switchMap(() => {
            return concat(of(setIsAcceptingEULA(true)), of(setEULAAccept(true)))
          }),
          catchError(() => {
            action.payload.toast({
              title: 'Fail to accept EULA.',
              position: 'bottom-left',
              status: 'error',
              isClosable: false,
            })
            return of(setIsAcceptingEULA(true))
          }),
        ),
      )
    }),
  )

export const SetUserVoiceParameter: AppEpic<
  ReturnType<typeof setUserVoiceParameter>
> = (action$, state$, { user }) =>
  action$.pipe(
    filter(setUserVoiceParameter.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const oldParameters = state.user.selected_parameters
      const parameters = state.user.selected_parameters

      const newParameters = parameters
        .filter(
          (element) =>
            element.avatar_identifier !== action.payload.body.avatar_identifier,
        )
        .filter(
          ({ key, model_id, avatar_identifier }) =>
            key && model_id && avatar_identifier,
        )

      const parameter = action.payload.parameters.find(
        ({ key }) => key === action.payload.body.key,
      )
      if (!parameter) {
        action.payload.toast({
          title: 'Error while updating parameter',
          position: 'bottom-left',
          status: 'error',
          isClosable: false,
        })

        return EMPTY
      }

      newParameters.push({ ...action.payload.body, ...parameter })

      const body = newParameters.map(
        ({ model_id, avatar_identifier, key }) => ({
          model_id,
          avatar_identifier,
          key,
        }),
      )

      return concat(
        of(setSelectedParameters(newParameters)),
        user.updateParameter(body).pipe(
          switchMap(() => {
            return EMPTY
          }),
          catchError(() => {
            action.payload.toast({
              title: 'Error while updating parameter',
              position: 'bottom-left',
              status: 'error',
              isClosable: false,
            })
            return concat(of(setSelectedParameters(oldParameters)))
          }),
        ),
      )
    }),
  )

export const userEpics = combineEpics(
  AcceptEula,
  SetUserVoiceParameter,
  SetTimeUnit,
)
