/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import { combineEpics } from 'redux-observable'
import {
  GetSession,
  RemoveSession,
  RemoveSessionRecordingAndConversions,
  SaveNewSession,
  SaveSession,
  SubscribeForSession,
  UpdateGetterForSession,
} from './epics/session'
import { SaveLiveSession, UploadAudioToS3 } from './epics/audio'
import { RemoveOneConversionById, SaveOneConversion } from './epics/conversions'

export const sessionEpics = combineEpics(
  GetSession,
  UploadAudioToS3,
  RemoveSession,
  SaveSession,
  RemoveOneConversionById,
  UpdateGetterForSession,
  SaveOneConversion,
  SaveLiveSession,
  SaveNewSession,
  RemoveSessionRecordingAndConversions,
  SubscribeForSession,
)
