import {
  Box,
  Button,
  Flex,
  Text,
  Textarea,
  FormControl,
  Link,
} from '@chakra-ui/react'
import React, { ReactNode, useEffect, useState } from 'react'
import ReactStars from 'react-stars'
import { Select, SingleValue } from 'chakra-react-select'
import { useAppSelector } from '../../utils/reduxUtils'

const minimumStars = 6
export const Survey = ({
  rating,
  setRating,
  issueDescription,
  setIssueDescription,
  setSelectedIssue,
  selectedIssue,
  onSubmit,
  additionalButton,
  isFirstLogin,
}: {
  rating: number
  setRating: (value: number) => void
  setSelectedIssue: (value: any) => void
  selectedIssue: SingleValue<any>
  issueDescription: string
  setIssueDescription: (value: string) => void
  onSubmit: () => void
  additionalButton?: ReactNode
  isFirstLogin: boolean
}) => {
  const { potential_issues } = useAppSelector((state) => state.user)
  const potentialIssueOptions = potential_issues.map(
    ({ potential_issue_id, description, is_default }) => ({
      value: potential_issue_id,
      label: description,
      is_default: is_default,
    }),
  )

  const defaultIssue = potentialIssueOptions.filter(
    ({ is_default }) => is_default,
  )
  const preselectedIssue =
    potential_issues.length > 0
      ? defaultIssue.length > 0
        ? defaultIssue[0]
        : potentialIssueOptions[0]
      : null

  useEffect(() => {
    setSelectedIssue(preselectedIssue)
  }, [])

  return (
    <Flex w="100%" gap="5" flexDirection="column" alignItems="center">
      <Text textAlign="center">
        {isFirstLogin
          ? "Now that you are all set up, we'd love to know what you thought about the enhanced voice quality"
          : "We'd love to know what you thought about the enhanced voice quality"}
      </Text>
      <ReactStars
        value={rating}
        half={false}
        count={5}
        onChange={setRating}
        size={36}
        color2="#1b91d6"
      />

      <FormControl
        display="flex"
        flexDirection="column"
        gap="5"
        w="100%"
        alignItems="center"
      >
        {rating < minimumStars && (
          <>
            <Box position="relative" w="100%">
              <Text pl="2" fontSize="sm">
                Tell us more about your feedback
              </Text>
              <Textarea
                data-qa__id="survey_textarea"
                errorBorderColor="red.300"
                resize="none"
                p="5px"
                m="2px"
                size="sm"
                // @ts-ignore
                maxLength="1024"
                value={issueDescription}
                onChange={(event) => {
                  setIssueDescription(event.target.value)
                }}
              />
              <Text
                size="xs"
                position="absolute"
                bottom="-15px"
                fontWeight="semibold"
                right="5px"
                fontSize="2xs"
              >
                {issueDescription.length} / 1024
              </Text>
            </Box>
          </>
        )}

        <Text fontSize="xs">
          Issue verifying your configuration? Let us know{' '}
          <Link color="brand.500" href="mailto:support@meaning.team">
            support@meaning.team
          </Link>
        </Text>

        <Flex gap="3" alignSelf="flex-end">
          {additionalButton && additionalButton}
          <Button
            mt="4"
            alignSelf="flex-end"
            colorScheme="brand"
            data-qa__id="submit_survey"
            isDisabled={rating === 0}
            onClick={() => {
              const canSubmit = rating !== 0
              if (canSubmit) {
                onSubmit()
              }
            }}
          >
            Submit
          </Button>
        </Flex>
      </FormControl>
    </Flex>
  )
}
