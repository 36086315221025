import React from 'react'
import { Grid, useMediaQuery } from '@chakra-ui/react'
import { ISessionDetails } from '../../../models/session/session'
import { IAudios } from '../audio.utils'
import { mobileBreakPoint } from '../../../utils/mobileBreakPoint'
import {
  IAnnotationsElement,
  IAnnotationTypes,
} from '../../../models/annotations/annotations'
import {
  IPlayer,
  SessionAnnotationPanel,
} from '../sessionAnnotationPanels/sessionAnnotationPanel'
import { SessionTranscriptionPanel } from '../sessionTranscriptionPanel/sessionTranscriptionPanel'
import { stop } from '../sessionPlayer/player.utils'
import { IPopoverData } from '../../../components/SessionPopoverComment/sessionPopoverComment'
import { Application } from 'pixi.js'
import { updateAlpha } from '../annotations.utils'

export const SessionFooter = ({
  data,
  selectedAudio,
  embedded,
  isTranscriptionToolActive,
  isAnnotationToolActive,
  annotations,
  annotationTypes,
  progress,
  visibleAnnotationTypes,
  player,
  playAnnotation,
  boxRef,
  annotationsChildren,
  setPlayer,
  renderProgress,
  selectedAnnotationTypeId,
  refetchAnnotations,
  setPopoverData,
  application,
}: {
  data?: ISessionDetails
  selectedAudio: IAudios | null
  embedded: boolean
  isTranscriptionToolActive: boolean
  isAnnotationToolActive: boolean
  annotations: IAnnotationsElement[]
  annotationTypes: IAnnotationTypes[]
  progress: { duration: number; progress: number }
  visibleAnnotationTypes: string[]
  player: IPlayer
  playAnnotation: ({
    annotation,
    duration,
    audio,
  }: {
    audio?: IAudios | null
    duration: number
    annotation: IAnnotationsElement
  }) => void
  boxRef: React.MutableRefObject<any>
  annotationsChildren: any
  renderProgress: (progress: number, duration: number) => void
  setPlayer: React.Dispatch<React.SetStateAction<IPlayer>>
  selectedAnnotationTypeId: string | null
  refetchAnnotations: () => void
  setPopoverData: (data: IPopoverData) => void
  application: Application | null
}) => {
  const [isMobile] = useMediaQuery(mobileBreakPoint)
  const onAnnotationPointerEnter = (id: string) => {
    application?.stage?.children.forEach((child: any) => {
      if (
        child &&
        child.id &&
        child.id.includes('annotation') &&
        child.id.includes(id)
      ) {
        updateAlpha({ child, alpha: 0.5 })
      }
    })
  }
  const onAnnotationPointerLeave = () => {
    application?.stage?.children.forEach((child: any) => {
      if (child.id && child.id.includes('annotation')) {
        updateAlpha({ child, alpha: 0.3 })
      }
    })
  }
  return !embedded && !isMobile && isTranscriptionToolActive ? (
    <Grid
      id="tabs_grid_panels"
      mt="15px"
      ml="5px"
      gridGap="15px"
      gridTemplateColumns={
        embedded || isMobile
          ? `1fr 1fr`
          : annotations.filter(({ description }) => !!description).length > 0 &&
            isAnnotationToolActive
          ? `2fr 1fr`
          : '100%'
      }
    >
      {
        <SessionTranscriptionPanel
          selectedAudio={selectedAudio}
          setPopoverData={setPopoverData}
          refetchAnnotations={refetchAnnotations}
          selectedAnnotationTypeId={selectedAnnotationTypeId}
          renderProgress={renderProgress}
          setPlayer={setPlayer}
          onAnnotationPointerEnter={onAnnotationPointerEnter}
          onAnnotationPointerLeave={onAnnotationPointerLeave}
          player={player}
          progress={progress}
          isAnnotationToolActive={isAnnotationToolActive}
          boxRef={boxRef}
          data={data}
          annotationsChildren={annotationsChildren}
          playAnnotation={(id) => {
            const filterAnnotation = annotations.filter(
              ({ annotation_id }) => id === annotation_id,
            )
            if (filterAnnotation && data?.duration && selectedAudio) {
              playAnnotation({
                audio: selectedAudio,
                duration: data?.duration / 1000,
                annotation: filterAnnotation[0],
              })
            }
          }}
        />
      }
      {annotations.filter(({ description }) => !!description).length > 0 &&
        isAnnotationToolActive && (
          <SessionAnnotationPanel
            isTranscriptionToolActive={isTranscriptionToolActive}
            player={player}
            playAnnotation={(annotation) => {
              if (annotation && data?.duration && selectedAudio) {
                if (player.isPlaying) {
                  stop({
                    name: selectedAudio.name,
                    id: selectedAudio.id,
                    player,
                    setPlayer,
                  })
                } else {
                  playAnnotation({
                    audio: selectedAudio,
                    duration: data?.duration / 1000,
                    annotation,
                  })
                }
              }
            }}
            annotations={annotations}
            annotationTypes={annotationTypes}
            progress={progress}
            visibleAnnotationTypes={visibleAnnotationTypes}
          />
        )}
    </Grid>
  ) : null
}
