import { ReactNode, useEffect, useRef, useState } from 'react'
import { Box, Tooltip } from '@chakra-ui/react'

export const OverflownText = ({
  children,
  tooltipChildren,
  ...props
}: {
  children: ReactNode
  tooltipChildren: ReactNode
} & any) => {
  const ref = useRef(null)
  const [isOverflown, setIsOverflown] = useState(false)
  useEffect(() => {
    const element: HTMLElement = ref.current!
    setIsOverflown(element.scrollWidth > element.clientWidth)
  }, [])

  return (
    <Tooltip
      borderRadius="10px"
      hasArrow
      background="#f7fafc"
      label={tooltipChildren}
      isDisabled={!isOverflown}
    >
      <Box
        position="relative"
        textOverflow="ellipsis"
        overflow="hidden"
        ref={ref}
        {...props}
      >
        {children}
      </Box>
    </Tooltip>
  )
}
