import {
  IAnnotationsElement,
  IAnnotationTypes,
} from '../../models/annotations/annotations'
import {
  Application,
  Container,
  DisplayObject,
  Graphics,
  Sprite,
  Texture,
} from 'pixi.js'
import { lightenColor, rgbToRgba } from '../../utils/colors'
import { IAudios } from './audio.utils'
import { sound } from '@pixi/sound'
import { Text as TextPixi } from '@pixi/text'

import { ISessionDetails } from '../../models/session/session'
import React from 'react'
import {
  IAnnotationRef,
  spinnerElement,
  tooltipRenderer,
} from './sessionPlayer/player.utils'
import { IPopoverData } from '../../components/SessionPopoverComment/sessionPopoverComment'
import { updateRef } from './sessionPlayer/annotations.actions'
export const textTooltip = new TextPixi('', {
  fontFamily: 'Arial',
  fontSize: 14,
  fill: 0x171923,
  align: 'center',
})

let isResizing = false
export const tooltipElement = new Graphics()
  .beginFill(0xffffff)
  .lineStyle(2, 0xeaeaea)
  .drawRoundedRect(-10, -6, 140, 28, 5)
  .endFill()
interface RenderOptions {
  /**
   * Width of the render.
   * @default 512
   */
  width: number
  /**
   * Height of the render.
   * @default 128
   */
  height: number
  /**
   * Fill style for waveform.
   * @default 0x000000
   */
  fill: number
  isConversion: boolean
}

export interface IAudioBlock extends Graphics {
  options: RenderOptions
  setColor: (color?: number) => void
}
export const refreshAnnotationTypes = ({
  setSelectedAnnotationType,
  annotation_types,
  setTypeId,
  selectedAnnotationType,
}: {
  setSelectedAnnotationType: (element: {
    id: string | null
    colorHEX: string | null
  }) => void
  annotation_types: IAnnotationTypes[]
  setTypeId: (typeId: string | null) => void
  selectedAnnotationType: { id: string | null; colorHEX: string | null }
}) => {
  if (annotation_types) {
    setTypeId(null)

    const defaultAnnotationType = annotation_types.find(
      ({ is_default }) => is_default,
    )
    const preselectedAnnotationType = annotation_types.find(
      ({ annotation_type_id }) =>
        annotation_type_id === selectedAnnotationType.id,
    )

    if (preselectedAnnotationType) {
      setSelectedAnnotationType({
        id: preselectedAnnotationType.annotation_type_id,
        colorHEX: preselectedAnnotationType.colorHEX,
      })

      setTypeId(preselectedAnnotationType.annotation_type_id)

      return
    }

    if (defaultAnnotationType) {
      setSelectedAnnotationType({
        id: defaultAnnotationType.annotation_type_id,
        colorHEX: defaultAnnotationType.colorHEX,
      })
      setTypeId(defaultAnnotationType.annotation_type_id)
    } else if (annotation_types.length > 0) {
      setSelectedAnnotationType({
        id: annotation_types[0].annotation_type_id,
        colorHEX: annotation_types[0].colorHEX || null,
      })
      setTypeId(annotation_types[0].annotation_type_id)
    }
  }
}

export const showHideAnnotations = ({
  event,
  annotation_type_id,
  visibleAnnotationTypes,
  setVisibleAnnotationTypes,
}: {
  event: any
  annotation_type_id: string
  visibleAnnotationTypes: string[]
  setVisibleAnnotationTypes: (element: string[]) => void
}) => {
  event.preventDefault()
  event.stopPropagation()
  const visibleAnnotationTypeElement = visibleAnnotationTypes.filter(
    (element) => element === annotation_type_id,
  )
  setVisibleAnnotationTypes(
    visibleAnnotationTypeElement.length > 0
      ? visibleAnnotationTypes.filter(
          (element) => element !== annotation_type_id,
        )
      : [...visibleAnnotationTypes, annotation_type_id],
  )
}

export const updateWave = ({
  wavePosition = 0,
  annotationModeAdd,
  application,
  waves,
}: {
  wavePosition: number
  annotationModeAdd: boolean
  application: Application | null
  waves: Container<DisplayObject>[]
}) => {
  if (annotationModeAdd) {
    application?.stage?.children.forEach(
      //@ts-ignore
      (child: { id: string; start: number; end: number }) => {
        if (child.id && child.id.includes('annotation')) {
          if (child.start <= wavePosition && wavePosition <= child.end) {
            updateAlpha({ child, alpha: 0.5 })
          } else {
            updateAlpha({ child, alpha: 0.3 })
          }
        }
      },
    )
  }

  waves.forEach((wave) => {
    wave.children.forEach((block, i) => {
      if (i > 0 && (block as IAudioBlock)?.setColor) {
        const bounds = block.getBounds()
        if (bounds.x < wavePosition) {
          ;(block as IAudioBlock).setColor((block as IAudioBlock).options.fill)
        } else {
          ;(block as IAudioBlock).setColor(
            lightenColor((block as IAudioBlock).options.fill, 0.8),
          )
        }
      }
    })
  })
}

export const updateAlpha = ({
  child,
  alpha,
}: {
  child: any
  alpha: number
}) => {
  child.children.forEach((element: { alpha: number; id: string }) => {
    if (element.id === 'background') {
      element.alpha = alpha
    }
  })
}

export const updateAnnotationOnWave = ({
  isAnnotation,
  annotations,
  audio,
  application,
  setAnnotationsChildren,
  data,
  audios,
  annotation_types,
  visibleAnnotationTypes,
  boxRef,
  height,
  selectedAudio,
  tooltip,
  tooltipText,
  waves,
  onCreateAnnotation,
  onRemoveAnnotation,
  playAnnotation,
  setPopoverData,
  setIsResizing,
  updateAnnotation,
  annotationRef,
}: {
  audio?: IAudios | null
  isAnnotation: boolean
  annotations: IAnnotationsElement[]
  application: Application | null
  setAnnotationsChildren: any
  data?: ISessionDetails
  audios: IAudios[]
  annotation_types?: IAnnotationTypes[]
  visibleAnnotationTypes: string[]
  boxRef: React.MutableRefObject<any>
  height: number
  selectedAudio: IAudios | null
  tooltip: Graphics
  tooltipText: TextPixi
  waves: Container<DisplayObject>[]
  onCreateAnnotation: ({ upPosition }: { upPosition: number }) => void
  onRemoveAnnotation: ({
    sessionId,
    annotationId,
  }: {
    sessionId: string
    annotationId: string
  }) => void
  playAnnotation: ({
    audio,
    annotation,
    duration,
  }: {
    annotation: IAnnotationsElement
    duration: number
    audio: IAudios | null | undefined
  }) => void
  setPopoverData: (data: IPopoverData) => void
  setIsResizing: (drooping: boolean) => void
  updateAnnotation: (data: {
    start: number
    end: number
    annotationId: string
    annotationContainer: any
    additionalAction: () => void
  }) => void
  annotationRef: IAnnotationRef
}) => {
  if (application) {
    const annotationToRemove = application?.stage?.children.filter(
      //@ts-ignore
      (child: { id: string }) => child.id && child.id.includes('annotation'),
    )

    annotationToRemove?.forEach((element) => {
      application.stage.removeChild(element)
    })

    if (isAnnotation) {
      annotations.forEach((annotation, index) => {
        const color = annotation_types?.find(
          ({ annotation_type_id }) =>
            annotation_type_id ===
            annotation.annotation_type.annotation_type_id,
        )
        const isVisible =
          visibleAnnotationTypes.length === 0
            ? true
            : visibleAnnotationTypes.filter(
                (element) =>
                  element === annotation.annotation_type.annotation_type_id,
              ).length > 0

        if (color && isVisible) {
          drawAnnotation({
            annotationRef,
            audio,
            colorHEX: color.colorHEX,
            color: color.color,
            annotation,
            application,
            data,
            audios,
            boxRef,
            height,
            selectedAudio,
            tooltip,
            tooltipText,
            waves,
            onCreateAnnotation,
            onRemoveAnnotation,
            playAnnotation,
            setPopoverData,
            setIsResizing,
            updateAnnotation,
          })
        }
      })
      let duration = 0
      if (audio && audio.id) {
        duration = sound.find(audio?.id).duration
      }
      const children = application?.stage?.children
        .filter(
          //@ts-ignore
          (child: { id: string }) =>
            child.id && child.id.includes('annotation'),
        )
        .map((el) => ({ ...el, duration }))
      setAnnotationsChildren(children)
    }
  }
}
export const crossTexture = Texture.from('/images/close.png')
export const commentTexture = Texture.from('/images/comment.png')

const drawAnnotation = ({
  colorHEX,
  color,
  application,
  annotation,
  audio,
  data,
  audios,
  boxRef,
  height,
  selectedAudio,
  tooltip,
  tooltipText,
  waves,
  onCreateAnnotation,
  onRemoveAnnotation,
  playAnnotation,
  setPopoverData,
  setIsResizing,
  updateAnnotation,
  annotationRef,
}: {
  colorHEX?: any
  color?: any
  application: Application
  annotation: IAnnotationsElement
  audio?: IAudios | null
  data?: ISessionDetails
  audios: IAudios[]
  boxRef: React.MutableRefObject<any>
  height: number
  selectedAudio: IAudios | null
  tooltip: Graphics
  tooltipText: TextPixi
  waves: Container<DisplayObject>[]
  onCreateAnnotation: ({ upPosition }: { upPosition: number }) => void
  onRemoveAnnotation: ({
    sessionId,
    annotationId,
  }: {
    sessionId: string
    annotationId: string
  }) => void
  playAnnotation: ({
    audio,
    annotation,
    duration,
  }: {
    annotation: IAnnotationsElement
    duration: number
    audio: IAudios | null | undefined
  }) => void
  setPopoverData: (data: IPopoverData) => void
  setIsResizing: (dragging: boolean) => void
  updateAnnotation: (data: {
    start: number
    end: number
    annotationId: string
    annotationContainer: any
    additionalAction: () => void
  }) => void
  annotationRef: IAnnotationRef
}) => {
  const selectedElement = audios.findIndex(({ id }) => id === audio?.id)
  const heightIndex =
    selectedElement && selectedElement !== -1 ? selectedElement : 0
  if (data?.duration && application.view) {
    const waveWidth =
      boxRef.current.parentElement.offsetWidth *
      boxRef.current.parentElement.zoom
    const duration = data.duration / 1000
    const background: any = new Graphics()
    const start = (annotation.start / 1000 / duration) * waveWidth
    const end = (annotation.end / 1000 / duration) * waveWidth
    background.beginFill(lightenColor(colorHEX, 0.2))
    background.lineStyle(2, colorHEX)
    background.drawRect(start, heightIndex * height, end - start, height)
    background.alpha = 0.3
    background.id = 'background'
    background.interactive = true
    const commentButton = new Sprite(commentTexture)
    commentButton.width = 17
    commentButton.height = 17
    commentButton.position.set(start + 5, heightIndex * height + height - 21)
    commentButton.interactive = true
    commentButton.on('click', (e) => {
      if (annotationRef.current.isSaving) {
        return
      }
      if (data?.id) {
        setPopoverData({
          x: e.data.originalEvent.clientX,
          annotationId: annotation.annotation_id,
          sessionId: data.id,
          text: annotation.description,
          startText: annotation.description,
          start: annotation.start,
          end: annotation.end,
        })
      }
    })

    const annotationText = new TextPixi(
      annotation.annotation_type.annotation_type_name,
      {
        fill: 'white',
        fontSize: 13,
      },
    )
    const textBox = new Graphics()
    textBox.beginFill(colorHEX) // Red color
    textBox.drawRoundedRect(
      start,
      heightIndex * height + height - 24,
      annotationText.width + 34,
      28,
      4,
    )
    textBox.endFill()

    annotationText.position.set(start + 25, heightIndex * height + height - 19)
    annotationText.interactive = true
    annotationText.on('click', () => {
      if (annotation.annotation_id && !isResizing) {
        playAnnotation({ annotation, duration, audio })
      }
    })
    const button: { id?: string } & Sprite = new Sprite(crossTexture)
    button.width = 10
    button.height = 10
    button.id = 'close'
    button.position.set(end - 15, heightIndex * height + 3)
    button.interactive = true

    if (audio && audio.id) {
      const duration = sound.find(audio?.id).duration
      tooltipRenderer({
        duration,
        app: application,
        element: background,
        tooltip,
        boxRef,
        tooltipText,
      })
    }

    button.on('click', () => {
      if (data.id) {
        if (annotationRef.current.isSaving) {
          return
        }
        application.stage.removeChild(annotationContainer)
        onRemoveAnnotation({
          sessionId: data.id,
          annotationId: annotation.annotation_id,
        })
      }
    })
    const annotationContainer: Container & {
      id?: string
      color?: string
      start?: number
      end?: number
      description?: string
    } = new Container()
    annotationContainer.id = 'annotation' + annotation.annotation_id
    annotationContainer.color = rgbToRgba(color, 0.3)
    annotationContainer.end = end
    annotationContainer.start = start
    annotationContainer.description = annotation.description

    annotationContainer.addChild(
      background,
      textBox,
      annotationText,
      commentButton,
      button,
    )
    backgroundHandler({
      height,
      audios,
      selectedAudio,
      app: application,
      annotationRef,
      start,
      end,
      annotation,
      background,
      annotationContainer,
      setIsResizing,
      saveAnnotationElement: (annotationData) => {
        if (annotationId && application && annotationContainer) {
          const startTime =
            (annotationData.start / waveWidth) * (data ? data.duration : 0)
          const endTime =
            (annotationData.end / waveWidth) *
            (data.duration ? data.duration : 0)
          updateAnnotation({
            ...annotationData,
            start: Math.floor(startTime),
            end: Math.floor(endTime),
          })
        }
      },
    })

    annotationContainer.interactive = true
    background.on('click', (e: any) => {
      if (selectedAudio?.isConversion) {
        waves[0].emit('click', e)
      } else {
        waves[1].emit('click', e)
      }
    })
    annotationContainer.on('pointerup', async (e: any) => {
      onCreateAnnotation({ upPosition: e.data.global.x })
    })

    application.stage.addChild(annotationContainer)
  }
}

const edgeThreshold = 5
let annotationId: string | null = null
let startPosition: string | null = null
let dataForAnnotation = { start: -1, end: -1 }

export const backgroundHandler = ({
  background,
  annotation,
  start,
  end,
  annotationContainer,
  saveAnnotationElement,
  setIsResizing,
  annotationRef,
  app,
  height,
  selectedAudio,
  audios,
}: {
  background: Graphics
  annotation: IAnnotationsElement
  start: number
  end: number
  annotationContainer: any
  setIsResizing: (isDrop: any) => void
  annotationRef: IAnnotationRef
  app: Application

  saveAnnotationElement: ({
    start,
    end,
    annotationId,
    annotationContainer,
    additionalAction,
  }: {
    start: number
    end: number
    annotationId: string
    annotationContainer: any
    additionalAction: () => void
  }) => void
  height: number
  audios: IAudios[]
  selectedAudio: IAudios | null
}) => {
  const saveAnnotation = ({ position }: { position: number }) => {
    if (isResizing) {
      if (
        dataForAnnotation.start > -1 &&
        dataForAnnotation.end > -1 &&
        annotationContainer.id === annotationId
      ) {
        updateRef(annotationRef, {
          ...annotationRef.current,
          isSaving: true,
        })
        const spinner = spinnerElement({
          app: app,
          radius: 6,
          positionX: position - 10,
          positionY: heightIndex * height + 10,
        })
        annotationContainer.addChild(spinner)
        const id = annotationId?.replaceAll('annotation', '')
        saveAnnotationElement({
          additionalAction: () => {
            annotationContainer.removeChild(spinner)
            updateRef(annotationRef, {
              ...annotationRef.current,
              isSaving: false,
            })
          },
          annotationId: id ? id : '',
          annotationContainer,
          ...dataForAnnotation,
        })
        dataForAnnotation = { start: -1, end: -1 }
      }
      isResizing = false
      startPosition = null
    }
  }
  const selectedElement = audios.findIndex(({ id }) => id === selectedAudio?.id)
  const heightIndex =
    selectedElement && selectedElement !== -1 ? selectedElement : 0
  background
    .on('pointerdown', (event: any) => {
      if (annotationRef.current.isSaving) {
        return
      }
      if (
        !isResizing &&
        (event.data.global.x - edgeThreshold <= start ||
          event.data.global.x + edgeThreshold >= end)
      ) {
        isResizing = true
        annotationId = annotationContainer.id
        setIsResizing(false)
        startPosition =
          event.data.global.x - edgeThreshold <= start ? 'start' : 'end'
      }
    })
    .on('pointerup', (e) => {
      if (annotationRef.current.isSaving) {
        return
      }

      saveAnnotation({ position: e.data.global.x })
    })
    .on('pointerupoutside', (e) => {
      if (annotationRef.current.isSaving) {
        return
      }
      saveAnnotation({ position: e.data.global.x })
    })
    .on('pointermove', (event: any) => {
      if (isResizing) {
        const positionX = event.data.global.x
        if (annotationContainer.id === annotationId) {
          const bg = annotationContainer.children.filter(
            (el: any) => el.id === 'background',
          )
          const close = annotationContainer.children.filter(
            (el: any) => el.id === 'close',
          )
          if (close && close.length > 0) {
            annotationContainer.removeChild(close[0])
          }
          if (annotationRef.current.isSaving) {
            background.cursor = 'pointer'
          }
          if (bg && bg.length > 0 && !annotationRef.current.isSaving) {
            const newWidth =
              startPosition === 'start' ? end - positionX : positionX - start
            const colorHEX = bg[0].line.color
            const height = bg[0].height
            bg[0].clear()
            bg[0].beginFill(lightenColor(colorHEX, 0.2))
            bg[0].lineStyle(2, colorHEX)
            const startX = startPosition === 'start' ? positionX : start
            dataForAnnotation = { start: startX, end: startX + newWidth }
            bg[0].drawRect(startX, 0, newWidth, height)
          }
          setIsResizing(annotation.annotation_id)
        }
      } else {
        const positionX = event.data.global.x
        if (
          !annotationRef.current.isSaving &&
          !isResizing &&
          (positionX - edgeThreshold <= start ||
            positionX + edgeThreshold >= end)
        ) {
          background.cursor = 'ew-resize'
        } else {
          background.cursor = 'pointer'
        }
      }
    })
}
