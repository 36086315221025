export const logoAnimation = (speed: number) => ({
  v: '5.9.0',
  fr: 25 * speed,
  ip: 0,
  op: 125,
  w: 1920,
  h: 1080,
  nm: 'Logo animation_ V2',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Layer 6 Outlines',
      parent: 3,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-71.275, 42.14, 0], ix: 2, l: 2 },
        a: { a: 0, k: [15.182, 19.038, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 5,
                    s: [
                      {
                        i: [
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                          [0, 0],
                          [-8.247, 0],
                          [0, -8.247],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [-8.247, 0],
                          [0, 0],
                          [0, -8.247],
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                        ],
                        v: [
                          [0.151, -162.587],
                          [0.151, -162.587],
                          [-14.781, -177.518],
                          [-14.798, -178.356],
                          [0.134, -193.288],
                          [15.066, -178.356],
                          [15.083, -177.518],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 1, y: 0 },
                    t: 15,
                    s: [
                      {
                        i: [
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                          [0, 0],
                          [-8.247, 0],
                          [0, -8.247],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [-8.247, 0],
                          [0, 0],
                          [0, -8.247],
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                        ],
                        v: [
                          [0, 18.788],
                          [0, 18.788],
                          [-14.932, 3.857],
                          [-14.798, -178.356],
                          [0.134, -193.288],
                          [15.066, -178.356],
                          [14.932, 3.857],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 25,
                    s: [
                      {
                        i: [
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                          [0, 0],
                          [-8.247, 0],
                          [0, -8.247],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [-8.247, 0],
                          [0, 0],
                          [0, -8.247],
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                        ],
                        v: [
                          [0, 18.788],
                          [0, 18.788],
                          [-14.932, 3.857],
                          [-14.932, -3.856],
                          [0, -18.788],
                          [14.932, -3.856],
                          [14.932, 3.857],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.592156862745, 0.423529441684, 0.741176470588, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [15.182, 19.038], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 5,
      op: 130,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Layer 5 Outlines',
      parent: 3,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-28.047, 28.962, 0], ix: 2, l: 2 },
        a: { a: 0, k: [15.182, 58.571, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 8,
                    s: [
                      {
                        i: [
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                          [0, 0],
                          [-8.247, 0],
                          [0, -8.247],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [-8.247, 0],
                          [0, 0],
                          [0, -8.247],
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                        ],
                        v: [
                          [-0.163, -149.179],
                          [-0.163, -149.179],
                          [-15.095, -164.111],
                          [-15.07, -164.889],
                          [-0.138, -179.821],
                          [14.794, -164.889],
                          [14.769, -164.111],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 1, y: 0 },
                    t: 18,
                    s: [
                      {
                        i: [
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                          [0, 0],
                          [-8.247, 0],
                          [0, -8.247],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [-8.247, 0],
                          [0, 0],
                          [0, -8.247],
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                        ],
                        v: [
                          [0, 58.321],
                          [0, 58.321],
                          [-14.932, 43.389],
                          [-15.07, -164.889],
                          [-0.138, -179.821],
                          [14.794, -164.889],
                          [14.932, 43.389],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 28,
                    s: [
                      {
                        i: [
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                          [0, 0],
                          [-8.247, 0],
                          [0, -8.247],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [-8.247, 0],
                          [0, 0],
                          [0, -8.247],
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                        ],
                        v: [
                          [0, 58.321],
                          [0, 58.321],
                          [-14.932, 43.389],
                          [-14.932, -43.389],
                          [0, -58.321],
                          [14.932, -43.389],
                          [14.932, 43.389],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0, 0.737254901961, 0.839215746113, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [15.182, 58.571], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 8,
      op: 133,
      st: 8,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Layer 4 Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [959.682, 555.838, 0], ix: 2, l: 2 },
        a: { a: 0, k: [15.182, 64.838, 0], ix: 1, l: 2 },
        s: { a: 0, k: [200, 200, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 11,
                    s: [
                      {
                        i: [
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                          [0, 0],
                          [-8.247, 0],
                          [0, -8.247],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [-8.247, 0],
                          [0, 0],
                          [0, -8.247],
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                        ],
                        v: [
                          [0.262, -185.163],
                          [0.262, -185.163],
                          [-14.67, -200.095],
                          [-14.841, -200.905],
                          [0.091, -215.837],
                          [15.023, -200.905],
                          [15.194, -200.095],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 1, y: 0 },
                    t: 21,
                    s: [
                      {
                        i: [
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                          [0, 0],
                          [-8.247, 0],
                          [0, -8.247],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [-8.247, 0],
                          [0, 0],
                          [0, -8.247],
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                        ],
                        v: [
                          [0, 64.587],
                          [0, 64.587],
                          [-14.932, 49.655],
                          [-14.841, -200.905],
                          [0.091, -215.837],
                          [15.023, -200.905],
                          [14.932, 49.655],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 31,
                    s: [
                      {
                        i: [
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                          [0, 0],
                          [-8.247, 0],
                          [0, -8.247],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [-8.247, 0],
                          [0, 0],
                          [0, -8.247],
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                        ],
                        v: [
                          [0, 64.587],
                          [0, 64.587],
                          [-14.932, 49.656],
                          [-14.932, -49.655],
                          [0, -64.587],
                          [14.932, -49.655],
                          [14.932, 49.656],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 0.196078446332, 0.301960784314, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [15.182, 64.837], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 11,
      op: 136,
      st: 11,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Layer 3 Outlines',
      parent: 3,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [58.411, 33.382, 0], ix: 2, l: 2 },
        a: { a: 0, k: [15.182, 49.651, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 14,
                    s: [
                      {
                        i: [
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                          [0, 0],
                          [-8.247, 0],
                          [0, -8.246],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [-8.247, 0],
                          [0, 0],
                          [0, -8.246],
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                        ],
                        v: [
                          [-0.166, -153.598],
                          [-0.166, -153.598],
                          [-15.098, -168.531],
                          [-15.112, -169.471],
                          [-0.18, -184.402],
                          [14.752, -169.471],
                          [14.766, -168.531],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 1, y: 0 },
                    t: 24,
                    s: [
                      {
                        i: [
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                          [0, 0],
                          [-8.247, 0],
                          [0, -8.246],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [-8.247, 0],
                          [0, 0],
                          [0, -8.246],
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                        ],
                        v: [
                          [0, 49.402],
                          [0, 49.402],
                          [-14.932, 34.469],
                          [-15.112, -169.471],
                          [-0.18, -184.402],
                          [14.752, -169.471],
                          [14.932, 34.469],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 34,
                    s: [
                      {
                        i: [
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                          [0, 0],
                          [-8.247, 0],
                          [0, -8.246],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [-8.247, 0],
                          [0, 0],
                          [0, -8.246],
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                        ],
                        v: [
                          [0, 49.402],
                          [0, 49.402],
                          [-14.932, 34.469],
                          [-14.932, -34.471],
                          [0, -49.402],
                          [14.932, -34.471],
                          [14.932, 34.469],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.635294117647, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [15.182, 49.652], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 14,
      op: 139,
      st: 14,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Layer 2 Outlines',
      parent: 3,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [101.639, 42.14, 0], ix: 2, l: 2 },
        a: { a: 0, k: [15.182, 19.038, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 17,
                    s: [
                      {
                        i: [
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                          [0, 0],
                          [-8.247, 0],
                          [0, -8.247],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [-8.247, 0],
                          [0, 0],
                          [0, -8.247],
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                        ],
                        v: [
                          [0.02, -162.337],
                          [0.02, -162.337],
                          [-14.912, -177.268],
                          [-14.844, -177.981],
                          [0.088, -192.913],
                          [15.02, -177.981],
                          [14.952, -177.268],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 1, y: 0 },
                    t: 27,
                    s: [
                      {
                        i: [
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                          [0, 0],
                          [-8.247, 0],
                          [0, -8.247],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [-8.247, 0],
                          [0, 0],
                          [0, -8.247],
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                        ],
                        v: [
                          [0, 18.788],
                          [0, 18.788],
                          [-14.932, 3.857],
                          [-14.844, -177.981],
                          [0.088, -192.913],
                          [15.02, -177.981],
                          [14.932, 3.857],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 37,
                    s: [
                      {
                        i: [
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                          [0, 0],
                          [-8.247, 0],
                          [0, -8.247],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [-8.247, 0],
                          [0, 0],
                          [0, -8.247],
                          [8.247, 0],
                          [0, 0],
                          [0, 8.247],
                        ],
                        v: [
                          [0, 18.788],
                          [0, 18.788],
                          [-14.932, 3.857],
                          [-14.932, -3.856],
                          [0, -18.788],
                          [14.932, -3.856],
                          [14.932, 3.857],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0, 0.564705882353, 0.839215746113, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [15.182, 19.038], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 17,
      op: 142,
      st: 17,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 1,
      nm: 'White Solid 1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 540, 0], ix: 2, l: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      sw: 1920,
      sh: 1080,
      sc: 'transparent',
      ip: 0,
      op: 125,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
})
