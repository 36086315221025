import { ajax } from 'rxjs/ajax'
import { ISession, ISessionDetails } from '../../models/session/session'

export interface IAudios {
  url: string
  name: string
  id: string
  description: string
  isConversion: boolean
}

export const getAudio = async (src: string) => {
  if (!src) {
    return null
  }
  try {
    const audioEl = await ajax({
      url: src + '?getDownloadUrl=true',
      method: 'GET',
      withCredentials: true,
    }).toPromise()
    return audioEl.response.download_url
  } catch (error) {
    console.error(error)
  }
}
export const uploadAudio = async ({
  isLoadingAudios,
  setIsLoadingAudios,
  setAudios,
  session,
}: {
  isLoadingAudios: boolean
  setIsLoadingAudios: React.Dispatch<React.SetStateAction<boolean>>
  setAudios: React.Dispatch<React.SetStateAction<IAudios[]>>
  session: ISessionDetails
}) => {
  if (!isLoadingAudios && Object.keys(session).length > 0) {
    setIsLoadingAudios(true)

    let conversions = []
    for (const conversion of (session as ISessionDetails).conversions) {
      const url = await getAudio((conversion as any).download_url)
      conversions.push({
        url,
        isConversion: true,
        name: (conversion as any).name,
        description: (conversion as any).description,
        id: (conversion as any).id,
      })
    }
    const url = await getAudio((session as ISessionDetails).download_url)
    setIsLoadingAudios(false)
    setAudios([
      {
        isConversion: false,
        url,
        id: (session as any).id,
        description: (session as any).description,
        name: session.user?.username,
      },
      ...conversions,
    ])
  }
}
