import { ArrowDownIcon, ArrowUpIcon, SmallCloseIcon } from '@chakra-ui/icons'
import * as React from 'react'

export const generateSortingIndicator = (
  column: any,
  onClearSort: () => void,
) => {
  return (
    column.canSort &&
    (column.isSorted ? (
      column.isSortedDesc ? (
        <>
          <ArrowDownIcon
            cursor="pointer"
            color="brand.500"
            onClick={() => column.toggleSortBy(false)}
            data-qa__id="sort_arrow_down"
          />
          <SmallCloseIcon
            _hover={{ color: 'red' }}
            cursor="pointer"
            ml="3px"
            onClick={() => {
              column.clearSortBy()
              onClearSort()
            }}
            data-qa__id="sort_close"
          />
        </>
      ) : (
        <>
          <ArrowUpIcon
            cursor="pointer"
            color="brand.500"
            onClick={() => column.toggleSortBy(true)}
            data-qa__id="sort_arrow_up"
          />
          <SmallCloseIcon
            cursor="pointer"
            _hover={{ color: 'red' }}
            ml="3px"
            onClick={() => {
              column.clearSortBy()
              onClearSort()
            }}
            data-qa__id="sort_close"
          />
        </>
      )
    ) : (
      <>
        <ArrowUpIcon
          cursor="pointer"
          _hover={{ color: 'brand.500' }}
          onClick={() => column.toggleSortBy(false)}
          data-qa__id="sort_arrow_up"
        />
        <ArrowDownIcon
          cursor="pointer"
          _hover={{ color: 'brand.500' }}
          onClick={() => column.toggleSortBy(true)}
          data-qa__id="sort_arrow_down"
        />
      </>
    ))
  )
}
