import { ajax } from 'rxjs/ajax'

export const login = ({
  tokenOrEmailOrDomain,
  isSSO,
}: {
  tokenOrEmailOrDomain: string | undefined
  isSSO: boolean
}) => {
  return ajax({
    url: isSSO
      ? `${process.env.REACT_APP_API_URL}/authorization/check_domain/`
      : `${process.env.REACT_APP_API_URL}/user/details/`,
    method: isSSO ? 'POST' : 'GET',
    withCredentials: true,
    body: isSSO ? { domain: tokenOrEmailOrDomain } : {},
    headers:
      tokenOrEmailOrDomain && !isSSO
        ? {
            Authorization: `Token ${tokenOrEmailOrDomain}`,
          }
        : {},
  })
}

export const iframe_login = ({ token }: { token: string | undefined }) => {
  return ajax({
    url: `${process.env.REACT_APP_API_URL}/user/details/`,
    method: 'GET',
    withCredentials: true,
    headers: {
      Authorization: `Token ${token}`,
    },
  })
}

export const logoutSSO = (url: string) => {
  new Image().src = url

  return ajax({
    url: url,
    method: 'GET',
    headers: {},
    crossDomain: true,
  })
}

export const logout = () => {
  return ajax({
    url: `${process.env.REACT_APP_API_URL}/user/logout`,
    method: 'GET',
    withCredentials: true,
    headers: {},
  })
}
