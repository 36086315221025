import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Text,
  Stack,
  useBreakpointValue,
  useColorModeValue,
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  Spinner,
  ButtonGroup,
  Button,
  HStack,
  Flex,
  IconButton,
  Tabs,
  Tab,
  TabList,
} from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import {
  Cell,
  Column,
  Row,
  useAsyncDebounce,
  useFilters,
  useFlexLayout,
  useMountedLayoutEffect,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import { CellComponent } from './TableElements/cell'
import { RangeDatesFilter } from './TableElements/rangeDates'
import { Filters } from './TableElements/filters'
import { AlertElement } from '../Alert'
import { FiEdit2, FiTrash2 } from 'react-icons/fi'
import { ClearIcon } from './ClearIcon'
import { TableElement } from './TableElements/table'
import { equals } from 'ramda'
import { startOfDay } from 'date-fns'

export interface ITable {
  table_info?: string | undefined
  tableHeader: string
  usedDurations: {
    min?: number
    max?: number
  }
  usedLatency: {
    min?: number
    max?: number
  }

  maxWidthOfTable?: string

  // search
  isSearch: boolean
  onSearchChange?: (value: string) => void
  isSearching?: boolean
  searchPlaceholder?: string

  isDatepicker?: boolean
  isFilters?: boolean

  // table
  isLoading: boolean
  defaultPageIndex?: number
  onChangeFilters: (sort: Array<{ id: string; value: string }>) => void
  onClearFiltersButtonClick: () => void
  onChangeSort: (sort: Array<{ id: string; desc: boolean }>) => void
  fetchData: ({
    pageIndex,
    pageSize,
  }: {
    pageIndex: number
    pageSize: number
  }) => void
  onClearSort: () => void
  height: string
  pageSizeDefault: number
  pageCounter: number
  rows: Array<Row>
  columns: Array<Column>
  onRemoveClick: (id: string) => void
  onEditClick: (id: string) => void
  isEditButton: boolean
  headerAlertText: string
  subHeaderAlertText: string
  isHeaderAlertName?: boolean
  cancelAlertText: string
  yesAlertText: string
  usedFilters?: { key: string; value: string }[]
  usedSort?: { id: string; desc: boolean }[]
  usedSearch?: string
  isRightButtonHeader?: boolean
  rightButtonHeaderIcon?: any
  rightButtonHeaderText?: string
  isIconButton?: boolean
  onClickRightButtonHeader?: () => void
  additionalActionButtons: {
    icon: any
    action: (id: any) => void
    aria: string
  }[]
  additionalTabs?: {
    name: string
    component: (elements: any) => void
  }[]
  defaultTab?: number
  isClearing?: boolean
  onStart: () => void
  usedDates?: any
}

export const Table = ({
  isEditButton,
  usedDurations,
  usedLatency,
  isClearing = false,
  onStart,
  maxWidthOfTable,
  tableHeader,
  isLoading,
  isSearching,
  onChangeSort,
  pageSizeDefault,
  isSearch,
  onSearchChange,
  onEditClick,
  fetchData,
  onRemoveClick,
  onClearSort,
  onChangeFilters,
  onClearFiltersButtonClick,
  rows,
  pageCounter,
  columns,
  height,
  searchPlaceholder,
  isFilters = true,
  isDatepicker = true,
  usedFilters = [],
  usedSearch = '',
  usedDates,
  usedSort = [],
  defaultPageIndex = 0,
  headerAlertText,
  isHeaderAlertName,
  subHeaderAlertText,
  cancelAlertText,
  yesAlertText,
  isRightButtonHeader,
  rightButtonHeaderIcon,
  rightButtonHeaderText,
  isIconButton,
  onClickRightButtonHeader,
  additionalActionButtons,
  additionalTabs,
  defaultTab,
  table_info,
}: ITable) => {
  const paddingTop = '30px'
  const headerHeight = '102px'
  const bottomHeight = '60px'
  const paginationHeight = '21px'
  const chakraTabs = document.getElementById('chakra-tabs')
  const tabsHeight = chakraTabs ? chakraTabs.offsetHeight + 20 : 0
  const calc = `${paddingTop} - ${headerHeight} - ${bottomHeight} - ${tabsHeight}px`
  const calcMetrics = `${paddingTop} - ${headerHeight} - ${tabsHeight}px + ${paginationHeight}`

  const [searchText, setSearchText] = useState('')
  const [selectedTab, setSelectedTab] = useState(defaultTab)
  const [pageCounterState, setPageCounterState] = useState(-1)
  const onInputSearchChange = useAsyncDebounce((value) => {
    if (onSearchChange) {
      onSearchChange(value)
    }
  }, 500)

  const data = useMemo(() => rows, [rows])
  const col: any = useMemo(
    () =>
      columns.map((el) => ({
        ...el,
        Cell: (el as any)?.isAction
          ? ({
              cell: {
                row: {
                  /* @ts-expect-error */
                  original: { id, name },
                },
              },
            }) => {
              return (
                <HStack spacing="1">
                  {additionalActionButtons.map(({ icon, aria, action }) => (
                    <IconButton
                      key={`aria_${aria}`}
                      size="sm"
                      onClick={() => action(id)}
                      icon={icon}
                      variant="ghost"
                      aria-label={aria}
                    />
                  ))}
                  {isEditButton && (
                    <IconButton
                      size="sm"
                      onClick={() => onEditClick(id)}
                      icon={<FiEdit2 fontSize="1rem" />}
                      variant="ghost"
                      aria-label="Edit member"
                    />
                  )}

                  <AlertElement
                    buttonElement={
                      <IconButton
                        size="sm"
                        icon={<FiTrash2 fontSize="1rem" />}
                        variant="ghost"
                        aria-label="Delete Member"
                        data-qa__id="table_delete_row_button"
                      />
                    }
                    headerText={`${headerAlertText} ${
                      isHeaderAlertName && name.val ? name.val : ''
                    }`}
                    subHeaderText={subHeaderAlertText}
                    cancelText={cancelAlertText}
                    yesText={yesAlertText}
                    onClickYes={() => onRemoveClick(id)}
                  />
                </HStack>
              )
            }
          : (props: Cell) => CellComponent({ ...props }),
      })),
    [columns],
  )

  const tableInitialState: any = {
    pageIndex: defaultPageIndex,
    filters: usedFilters,
    sortBy: usedSort,
    hiddenColumns: columns
      .filter((col: any) => col.show === false)
      .map((col) => col.accessor),
  }

  const tableInstance: any = useTable(
    {
      columns: col,
      data,
      initialState: tableInitialState,
      manualSortBy: true,
      manualFilters: true,
      autoResetSortBy: false,
      manualPagination: true,
      autoResetPage: true,
      autoResetFilters: false,
      pageCount: Math.ceil(pageCounter / pageSizeDefault),
      pageSize: pageSizeDefault,
    } as any,
    useFlexLayout,
    useFilters,
    useSortBy,
    usePagination,
  )

  const {
    pageCount,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setAllFilters,
    setSortBy,
    state: { pageSize, pageIndex, filters },
  } = tableInstance

  const [disablePageChange, setDisablePageChange] = useState(false)
  const onPageChange = useAsyncDebounce(
    (page: { pageIndex: number; pageSize: number }) => {
      if (defaultPageIndex !== page.pageIndex && !disablePageChange) {
        fetchData(page)
      }
    },
    100,
  )

  const [isFirstIteration, setIsFirstIteration] = useState(true)
  useEffect(() => {
    onStart()
    setIsFirstIteration(false)
  }, [])

  const [isFirstSort, setIsFirstSort] = useState(true)
  useEffect(() => {
    if (!isFirstIteration && isFirstSort) {
      setIsFirstSort(false)
      setSortBy(usedSort)
    }
  }, [usedSort])

  const [isFirstSearch, setIsFirstSearch] = useState(true)
  useEffect(() => {
    setIsFirstSearch(false)
    setSearchText(usedSearch)
  }, [usedSearch])

  useEffect(() => {
    if (isClearing && isLoading) {
      setIsClearingFilters(true)
      // search
      setSearchText('')
      setSortBy([])
      setAllFilters([])
      const timer = setTimeout(() => {
        gotoPage(0)
        clearTimeout(timer)
      }, 50)
    }
  }, [isClearing, isLoading])

  const onClickFiltersButton = useAsyncDebounce((filters) => {
    // onFilterButtonClick(filters)
  }, 0)

  useEffect(() => {
    if (pageCounterState !== pageCounter) {
      setPageCounterState(pageCounter)
    }
  }, [gotoPage, pageCounter])

  React.useEffect(() => {
    !isClearing &&
      searchText === usedSearch &&
      !isFirstIteration &&
      onPageChange &&
      onPageChange({ pageIndex, pageSize })
  }, [onPageChange, pageIndex, pageSize])

  const [isClearingFilters, setIsClearingFilters] = useState(false)
  const onChangeFiltersProp = (elements: any) => {
    if (!isClearingFilters) {
      setDisablePageChange(true)
      gotoPage(0)
      const timer = setTimeout(() => {
        clearTimeout(timer)
        setDisablePageChange(false)
      }, 100)
      onChangeFilters(elements)
    }
    setIsClearingFilters(false)
  }

  useMountedLayoutEffect(() => {
    setDisablePageChange(true)
    const time = filters.find((filter: { id: string }) => filter.id === 'time')
    const from = time
      ? equals(
          startOfDay(new Date(usedDates.from_)),
          startOfDay(new Date(time.value.start)),
        )
      : false
    const to = time
      ? equals(
          startOfDay(new Date(usedDates.to_)),
          startOfDay(new Date(time.value.end)),
        )
      : false
    const usedElements = filters.filter((el: any) => {
      return el.id !== 'time'
    })
    if (
      !(from && to) ||
      (!equals(usedElements, usedFilters) && filters.length > 1)
    ) {
      onChangeFiltersProp(filters)
    }

    const timer = setTimeout(() => {
      clearTimeout(timer)
      setDisablePageChange(false)
    }, 100)
  }, [filters])

  useEffect(() => {
    setSelectedTab(defaultTab)
  }, [defaultTab])

  const onChangeSortProp = (sortProps: any) => {
    if (usedSort !== sortProps && !isClearing) {
      setDisablePageChange(true)
      onChangeSort(sortProps)
      gotoPage(0)
      const timer = setTimeout(() => {
        clearTimeout(timer)
        setDisablePageChange(false)
      }, 100)
    }
  }

  const isClearIcon =
    ((usedFilters as any)?.length > 0 ||
      (usedDurations.min && usedDurations.min > 0) ||
      (usedDurations.max && usedDurations.max > 0) ||
      (usedLatency.min && usedLatency.min > 0) ||
      (usedLatency.max && usedLatency.max > 0)) &&
    isFilters

  return (
    <Box
      w="100%"
      bg="bg-surface"
      boxShadow={{ base: 'none', md: useColorModeValue('sm', 'sm-dark') }}
      borderRadius={useBreakpointValue({ base: 'none', md: 'lg' })}
    >
      <Stack spacing="5">
        <Box
          id="table_header_element"
          px={{ base: '4', md: '6' }}
          pt={paddingTop}
        >
          <Box>
            <Text pb="3" fontSize="xl" fontWeight="medium">
              {tableHeader}
            </Text>
            <Flex justifyContent="space-between" gap="5" flexWrap="wrap">
              <Flex gap="5" flexWrap="wrap">
                {isSearch && (
                  <InputGroup w="xs">
                    <InputLeftElement pointerEvents="auto">
                      <SearchIcon cursor="auto" color="#a0aec0" boxSize="5" />
                    </InputLeftElement>
                    <Input
                      value={searchText}
                      onChange={(event) => {
                        setSearchText(event.target.value)
                        onInputSearchChange(event.target.value)
                        gotoPage(0)
                      }}
                      bg="white"
                      fontSize="sm"
                      placeholder={
                        searchPlaceholder ? searchPlaceholder : 'Search'
                      }
                      data-qa__id="table_search_input"
                    />
                    {isSearching && (
                      <InputRightElement pointerEvents="none">
                        <Spinner />
                      </InputRightElement>
                    )}
                  </InputGroup>
                )}
                {isDatepicker && (
                  <RangeDatesFilter
                    isClearing={isClearing}
                    isLoading={isLoading}
                    usedDates={usedDates}
                    column={tableInstance}
                  />
                )}
                {isFilters && (
                  <Filters
                    usedFilters={usedFilters}
                    column={tableInstance}
                    usedDurations={usedDurations}
                    usedLatency={usedLatency}
                  />
                )}
                {!!isClearIcon && (
                  <IconButton
                    variant="outline"
                    px="7"
                    onClick={() => {
                      const timer = setTimeout(() => {
                        clearTimeout(timer)
                        gotoPage(0)
                        setDisablePageChange(false)
                      }, 100)
                      setIsClearingFilters(true)
                      setSearchText('')
                      onClearFiltersButtonClick()
                      const timeFilter = filters.filter(
                        (el: any) => el.id === 'time',
                      )
                      setAllFilters(timeFilter)
                    }}
                    aria-label={'Filters'}
                    icon={<ClearIcon />}
                    data-qa__id="table_clear_filters_button"
                  />
                )}
              </Flex>
              {/* TODO: re-enable once offline inference is fixed*/}
              <Flex>
                {isRightButtonHeader &&
                  onClickRightButtonHeader &&
                  rightButtonHeaderText &&
                  (isIconButton ? (
                    <IconButton
                      aria-label={rightButtonHeaderText}
                      onClick={onClickRightButtonHeader}
                      variant="primary"
                      icon={rightButtonHeaderIcon}
                    />
                  ) : (
                    <Button
                      onClick={onClickRightButtonHeader}
                      variant="primary"
                      rightIcon={
                        rightButtonHeaderIcon
                          ? rightButtonHeaderIcon
                          : undefined
                      }
                    >
                      {rightButtonHeaderText}
                    </Button>
                  ))}
              </Flex>
            </Flex>
          </Box>
        </Box>
        {/*{additionalTabs && additionalTabs.length > 0 && (*/}
        {/*  <Tabs*/}
        {/*    onChange={(value) => {*/}
        {/*      setSelectedTab(value)*/}
        {/*      if (onTabChange) {*/}
        {/*        onTabChange(value)*/}
        {/*      }*/}
        {/*    }}*/}
        {/*    px="6"*/}
        {/*    mt="0"*/}
        {/*    size="sm"*/}
        {/*    id="chakra-tabs"*/}
        {/*    defaultIndex={defaultTab}*/}
        {/*    index={defaultTab}*/}
        {/*  >*/}
        {/*    <TabList>*/}
        {/*      <Tab>Table</Tab>*/}
        {/*      {additionalTabs.map(({ name }) => (*/}
        {/*        <Tab key={name}>{name}</Tab>*/}
        {/*      ))}*/}
        {/*    </TabList>*/}
        {/*  </Tabs>*/}
        {/*)}*/}
        height={`${height} - ${calcMetrics}`}
        maxWidthOfTable={maxWidthOfTable}
        {additionalTabs &&
          selectedTab &&
          additionalTabs[selectedTab - 1].component && (
            <>
              {additionalTabs[selectedTab - 1].component({
                height: `${height} - ${calcMetrics}`,
                maxWidthOfTable,
              })}
            </>
          )}
        {(!additionalTabs || (additionalTabs && selectedTab === 0)) && (
          <>
            <Box overflowX="auto">
              <TableElement
                table_info={table_info}
                maxWidthOfTable={maxWidthOfTable}
                tableInstance={tableInstance}
                height={`${height} - ${calc}`}
                onClearSort={onClearSort}
                isLoading={isLoading}
                isSearching={isSearching ? isSearching : false}
                onChangeSort={onChangeSortProp}
                onRemoveClick={onRemoveClick}
                onEditClick={onEditClick}
              />
            </Box>

            <Box h={bottomHeight} px={{ base: '4', md: '6' }} pb="5">
              <HStack spacing="3" justify="space-between">
                <Text
                  color="muted"
                  fontSize="sm"
                  data-qa__id="table_pagination_info"
                >
                  {pageCount && pageSize
                    ? `Showing page ${pageIndex + 1} of ${pageCount}`
                    : ' '}
                </Text>
                {(canPreviousPage || canNextPage) && (
                  <ButtonGroup
                    spacing="3"
                    justifyContent="space-between"
                    width={{ base: 'full', md: 'auto' }}
                    variant="secondary"
                  >
                    {canPreviousPage && (
                      <Button
                        onClick={previousPage}
                        data-qa__id="table_pagination_previous"
                      >
                        Previous
                      </Button>
                    )}
                    {canNextPage && (
                      <Button
                        onClick={nextPage}
                        data-qa__id="table_pagination_next"
                      >
                        Next
                      </Button>
                    )}
                  </ButtonGroup>
                )}
              </HStack>
            </Box>
          </>
        )}
      </Stack>
    </Box>
  )
}
export default Table
