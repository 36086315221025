/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import { combineEpics } from 'redux-observable'
import {
  catchError,
  delay,
  filter,
  repeatWhen,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators'
import { AppEpic } from '../../utils/reduxUtils'
import {
  setLiveManagementSession,
  setLiveSession,
  setMetadata,
  subscribeLiveSessions,
  updateGetter,
} from './actions'
import { concat, EMPTY, of, Subject, timer } from 'rxjs'
import { newVoiceElements } from '../../pages/Live/live'
import { setSelectedVoice, updateMediaState } from '../audio/actions'
import { checkIsStatus401 } from '../authorization/actions'

let repeat = 0
export const SetLiveSession: AppEpic<
  ReturnType<typeof setLiveManagementSession>
> = (action$, state$, { audioManagement }) =>
  action$.pipe(
    filter(setLiveManagementSession.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const session = {
        session: action.payload,
        selected_action: '',
        selected_voice: '',
      }
      return audioManagement
        .setLiveSession({
          body: session,
          user: state.user.meaning_user_external_id,
        })
        .pipe(
          switchMap((AjaxResponse) => {
            repeat = 0

            const metadata = AjaxResponse?.response?.session?.metadata ?? {}

            console.log('SET LIVE SESSION OK')
            console.log(AjaxResponse)
            return concat(
              of(setMetadata(metadata)),
              of(setLiveSession(action.payload)),
            )
          }),
          catchError((err) => {
            console.log('SET LIVE SESSION ERROR')
            console.log(err)
            console.log(err?.status)
            let actions: any = [of(checkIsStatus401(err.status))]
            if (repeat < 10) {
              repeat += 1
              actions.push(
                timer(1000).pipe(
                  switchMap(() => of(setLiveManagementSession(action.payload))),
                ),
              )
            }
            return concat(...actions)
          }),
        )
    }),
  )
let pause$ = new Subject()
let resume$ = new Subject()
export const UpdateGetter: AppEpic<ReturnType<typeof updateGetter>> = (
  action$,
  state$,
  { audioManagement },
) =>
  action$.pipe(
    filter(updateGetter.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const isControlledByApi =
        state.user.features.filter(
          (feature) => feature === 'is_controlled_by_live_session_api',
        ).length > 0
      if (!isControlledByApi) {
        return EMPTY
      }
      if (!action.payload) {
        pause$.next()
        return EMPTY
      } else {
        resume$.next()
        return of(subscribeLiveSessions())
      }
    }),
  )

export const SubscribeLiveSessions: AppEpic<
  ReturnType<typeof subscribeLiveSessions>
> = (action$, state$, { audioManagement }) =>
  action$.pipe(
    filter(subscribeLiveSessions.match),
    withLatestFrom(state$),
    delay(2500),
    takeUntil(pause$),
    repeatWhen((completed) => resume$),
    switchMap(([action, state]) => {
      return audioManagement
        .getLiveSession(
          state.user.meaning_user_external_id,
          state.user.tabFingerprint,
          state.user.browserFingerprint,
        )
        .pipe(
          switchMap((AjaxResponse) => {
            const {
              response: {
                selected_action,
                session: { session_id },
                metadata,
                selected_voice,
                selected_is_recording,
                avatars,
              },
            } = AjaxResponse
            if (!state.audioManagement.pauseGetter) {
              return of(setMetadata({}))
            }
            const actions: any = [
              of(subscribeLiveSessions()),
              of(setMetadata(metadata)),
              of(
                setLiveSession({
                  ...state.audioManagement.liveSession,
                  session_id,
                }),
              ),
            ]
            if (selected_action !== '') {
              if (selected_action === 'start_session') {
                actions.push(
                  of(
                    updateMediaState({
                      canBeRecorded: true,
                      shouldBeRecorded: selected_is_recording,
                    }),
                  ),
                )
                if (avatars.length > 0) {
                  let newVoice: any = {}
                  const allVoices = []
                  for (const voiceElement of state.user.s3_models) {
                    for (const oneVoice of voiceElement.voices) {
                      allVoices.push(oneVoice)
                    }
                  }
                  const selectedVoice = allVoices.filter(
                    ({ voice_id }) => voice_id === selected_voice,
                  )
                  if (selectedVoice.length > 0) {
                    newVoice = { ...selectedVoice[0] }
                  } else {
                    const defaultVoice = allVoices.filter(
                      ({ is_default }) => is_default,
                    )
                    if (defaultVoice.length > 0) {
                      newVoice = { ...defaultVoice[0] }
                    } else {
                      newVoice = { ...allVoices[0] }
                    }
                  }
                  if (
                    newVoice.voice_id ===
                      state.audio.selectedVoice.voice?.voice_id &&
                    newVoice.model_id ===
                      state.audio.selectedVoice.voice?.model_id
                  ) {
                    newVoice = newVoiceElements()
                  }
                  actions.push(
                    of(
                      setSelectedVoice({
                        voice: newVoice,
                      }),
                    ),
                  )
                }
              }
              if (
                selected_action === 'stop_session' &&
                state.audio.mediaState.isConnected
              ) {
                console.log('STOP')
                const newVoice = {
                  voice_id: '',
                  model_id: '',
                  description: '',
                  display_name: '',
                  id: '',
                  key: '',
                  data_center_id: '',
                  latency: 0,
                }
                actions.push(
                  of(
                    setSelectedVoice({
                      voice: newVoice,
                    }),
                  ),
                )
                return concat(...actions)
              }
            } else {
              return concat(...actions)
            }
            return concat(...actions)
          }),
          catchError((err) => {
            return concat(
              of(checkIsStatus401(err.status)),
              of(subscribeLiveSessions()),
            )
          }),
        )
    }),
  )

export const audioManagementEpics = combineEpics(
  SetLiveSession,
  SubscribeLiveSessions,
  UpdateGetter,
)
