import { ajax } from 'rxjs/ajax'
import { IBodyNewSave, IBodyUpdateSave } from '../../models/session/session'
import { apiVersion } from '../index'

export const getSessionService = (
  id: string | number,
  withTranscription?: boolean,
) => {
  return ajax({
    url: `${process.env.REACT_APP_API_URL}/api/${apiVersion}/sessions/${id}${
      withTranscription ? '/?transcription=true' : ''
    }`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  })
}
export const removeSession = (id: string | number) => {
  return ajax({
    url: `${process.env.REACT_APP_API_URL}/api/${apiVersion}/sessions/${id}`,
    method: 'DELETE',
    body: {},
    withCredentials: true,
  })
}
export const removeSessionRecordingAndConv = (id: string | number) => {
  return ajax({
    url: `${process.env.REACT_APP_API_URL}/api/${apiVersion}/sessions/${id}`,
    method: 'PATCH',
    body: {},
    withCredentials: true,
  })
}

export const saveSession = ({
  body,
  isNew,
}: {
  body: IBodyUpdateSave | IBodyNewSave | {}
  isNew: boolean
}) => {
  return ajax({
    url: `${process.env.REACT_APP_API_URL}/api/${apiVersion}/sessions`,
    method: isNew ? 'POST' : 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
    withCredentials: true,
  })
}
export const saveConversion = ({
  body,
  sessionId,
  isNew,
}: {
  sessionId: string | number
  body: any
  isNew: boolean
}) => {
  return ajax({
    url: `${process.env.REACT_APP_API_URL}/api/${apiVersion}/sessions/${sessionId}/conversions`,
    method: isNew ? 'POST' : 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
    withCredentials: true,
  })
}
export const removeConversion = ({
  session_id,
  conversion_id,
}: {
  session_id: string | number
  conversion_id: string | number
}) => {
  return ajax({
    url: `${process.env.REACT_APP_API_URL}/api/${apiVersion}/sessions/${session_id}/conversions/${conversion_id}`,
    method: 'DELETE',
    withCredentials: true,
  })
}
